import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../_services';

/** 
 * Clase que intercepta los errores en las comunicaciones http
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                //auto logout if 401 response returned from api
                if(!err.url.includes("register") || !err.url.includes("login")) {
                    this.authenticationService.logout();
                    location.reload(true);
                }
            } else if (!err.status) {
                this.authenticationService.logout();
                location.reload(true);

            }

            if(err.error && err.error.message && err.error.message == "Expired JWT Token") {
                this.authenticationService.logout();
                location.reload(true);
            }

            console.error(err);

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}