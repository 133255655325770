import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, UserService, NotificacionService, CertificadoService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import { interval, Subscription, Observable } from 'rxjs';
import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';

/**
 * Componente que muestra el contador con el proceso de licitación
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-proceso',
  templateUrl: './proceso.component.html',
  styleUrls: ['./proceso.component.scss']
})
export class ProcesoComponent implements OnInit {

  /** Variables globales */
  countdown = "XX:XX";
  licitCode = null;
  currentUser = null;
  licitacion = null;
  subscription: Subscription;
  loadingInit = true;

  contacto = {sms: false, email: false};

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private notificacionService: NotificacionService,
    private userService: UserService,
    private stateService: StateService,
    private ngZone: NgZone,
		private translate: TranslateService,
    private countryService: CountryService,
    private certificadoService: CertificadoService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) {
  }

  /**
   * Método que se lanza en el inicio del componente y carga la información del usuario.
   * Si no hay una sesión y código de licitación va a start.
   */
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
			this.licitCode = params.get("licit");

			if (this.authenticationService.currentUserValue && this.licitCode) { 
				this.currentUser = this.authenticationService.currentUserValue;
        this.loadLicitacion();
			} else {
        //console.error(this.authenticationService.currentUserValue)
        this.ngZone.run(() => this.router.navigate(['/start'])).then();
			}
		})
  }

  /**
   * Carga la información de la licitación a mostrar.
   */
  loadLicitacion() {
    var loadedLicitacion = function(resData){
      console.warn(resData);

      if(!resData[0]) return;

      this.licitacion = resData[0];

      if(!this.licitacion.abierta || (this.currentUser.id != this.licitacion.creador.id) || this.licitacion.tipo != "WFP") {
        console.error(this.licitacion.abierta);
        console.error(this.currentUser.id);
        console.error(this.licitacion.creador.id);

        this.ngZone.run(() => this.router.navigate(['/start'])).then();
        return;
      }


      this.cdr.detectChanges();


      const source = interval(1000);
      this.subscription = source.subscribe(val => this.procesoCountdown());
    }
    self.io.socket.get('/licitacion', {referencia: this.licitCode}, loadedLicitacion.bind(this));
  }

  /**
   * Proceso iterativo que muestra el tiempo restante en pantalla.
   * Al finalizar va a la vista de contratar.
   */
  procesoCountdown(){
    var ms = moment(this.licitacion.endDate).diff();
  
    if(ms < 0){
      this.countdown = "00:00";
      //Procesando... Ir a pag resultados.
      this.ngZone.run(() => this.router.navigate(['/contratar/' + this.licitCode])).then();
    } else {
      var d = moment.duration(ms);
      var hours = Math.floor(d.asHours());
      var hoursStr = "";
      if (hours < 10) hoursStr = "0" + hours
      else hoursStr = hours + "";
      var s = moment.utc(ms).format("mm") + ":" + moment.utc(ms).format("ss");
      this.countdown = s;
    }

    if(this.loadingInit) {
      this.loadingInit = false;
    }
    this.cdr.detectChanges();
  }

  /**
   * Método que se lanza al finalizar el componente. Cierra la conexión con el socket.
   */
  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe();
    if(self.io.socket) {
      self.io.socket.off('conexion');
    }
  }

}
