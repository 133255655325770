// Spain
export const locale = {
	lang: 'de',
	data: {
		START: {
			TITULO: 'Finden Sie die Trauerfeier, die ',
			TITULO2: 'decken alle Ihre Bedürfnisse ab, ',
			SUBTITULO: 'Wir zeigen Ihnen den Bestattung, der den Service bieten, den Sie mit höchster Qualität und zum besten Preis benötigen.',
			PASO1: 'SCHRITT 1',
			PASO2: 'SCHRITT 2',
			PASO3: 'SCHRITT 3',
			PASO1_NOMBRE: 'Lage und Zeremonie ',
			PASO2_NOMBRE: 'Service',
			PASO3_NOMBRE: 'Gib deine Daten ein ',
			SIGUIENTE: 'Folgt ',
			FINALIZAR: 'Beenden',
			VOLVER: 'Zurückkehren',
			STEP1: {
				UBIC_FALLEC: 'Ort verstorbene Person ',
				UBIC_TANAT: 'Ort der Tanatorio ',
				POBLACION: 'Bevölkerung',
				PROVINCIA: 'Autonome Gemeinschaft/Staat ',
				PAIS: 'Land',
				TIPOLOGIA: 'Dienstleistung ',
				TIPOLOGIA_SELECT: 'Wählen Sie den Dienst ',
				CREMACION: 'Einäscherung',
				INHUMACION: 'Inhumation ',
				CEREMONIA: 'Zeremonie',
				CIVIL: 'Bürgerlich',
				RELIGIOSA: 'Religiös ',
				MULTIPLE: 'Multiple Tod ',
				MULTIPLE_SELECT: 'Wählen Sie Nr. von Leuten'
							},
			STEP2: {
				SUBTITULO: 'Wählen Sie eines unserer Servicepakete. ',
				SERV1: 'RUHE',
				SERV2: 'RUHIG',
				SERV3: 'Premium ',
				SERV1_ITEM1: 'Runde Trauer Arche ',
				SERV1_ITEM2: 'Präsentation und Konditionierung ',
				SERV1_ITEM3: 'Uniformiertes Bestattungsunternehmen ',
				SERV1_ITEM4: 'Ärztliche Bescheinigung',
				SERV1_ITEM5: 'Luxus -Bestattungsunternehmen mit notwendiger Verschiebung inklusive ',
				SERV1_ITEM6: 'Mittlere Krone ',
				SERV1_ITEM7: 'Einäscherung und Urne ',
				SERV2_ITEM1: 'Runde Beerdigung Arche, Semededonda oder ägyptische ökologische ',
				SERV2_ITEM2: 'Präsentation und Konditionierung ',
				SERV2_ITEM3: 'Uniformiertes Bestattungsunternehmen ',
				SERV2_ITEM4: 'Ärztliche Bescheinigung',
				SERV2_ITEM5: 'Luxus -Bestattungsunternehmen mit notwendiger Verschiebung inklusive ',
				SERV2_ITEM6: 'Große Krone ',
				SERV2_ITEM7: 'Einäscherung und Urna Premium ',
				SERV2_ITEM8: 'Übertragung nach Tanatorio ',
				SERV2_ITEM9: 'Bräunungsraum ',
				SERV3_ITEM1: 'Runde Beerdigung Arche, Semededonda oder ägyptische ökologische ',
				SERV3_ITEM2: 'Präsentation und Konditionierung ',
				SERV3_ITEM3: 'Uniformiertes Bestattungsunternehmen ',
				SERV3_ITEM4: 'Ärztliche Bescheinigung',
				SERV3_ITEM5: 'Luxus -Bestattungsunternehmen mit notwendiger Verschiebung inklusive ',
				SERV3_ITEM6: 'Große Krone mit Kopfteil ',
				SERV3_ITEM7: 'Einäscherung und Luxusurne ',
				SERV3_ITEM8: 'Übertragung nach Tanatorio ',
				SERV3_ITEM9: 'VIP -Tankraum ',
				SERV3_ITEM10: 'Luxus Catering ',
				SERV3_ITEM11: 'Musikalische Begleitung oder ähnliches ',
				SELECCIONAR: 'WÄHLEN',
				SELECCIONADO: 'AUSGEWÄHLT',
				ADICIONALES: 'Wenn Sie möchten, können Sie dem ausgewählten Paket zusätzliche Dienste hinzufügen.',
				CATERING: 'Catering ',
				CATERING_SELECT: 'Wählen Sie Nr. von Menschen zum Catering ',
				CATERING_NO: 'Ohne Catering ',
				CORONAS: 'Kronen ',
				CORONAS_SELECT: 'Num. Der Kronen ',
				CORONAS_NO: 'Ohne Kronen ',
				OTRAS: 'Andere Spezifikationen oder Informationen für Bestattungsunternehmen ',
				VER_ADICIONALES: 'Zusätzliche Dienste zeigen '
			},
			STEP3: {
				SUBTITULO: 'Geben Sie Ihre Daten ein, damit wir Ihnen den Bestattung zeigen können, den der Service anbieten kann. ',
				NOMBRE: 'Name',
				PREFIJO: 'Ländervorwahl) ',
				TELEFONO: 'Mobiltelefon)',
				EMAIL: 'Email',
				POLITICA: 'Ich habe das gelesen und akzeptiert ',
				POLITICA2: 'Datenschutzrichtlinie.',
				errorSwal: 'Falsche E-mail',
				errorSwal_TEXT: 'Die eingeführte E -Mail ist bereits im System registriert. ',
			},
			SWAL: {
				errorSMSSwal: 'Falscher SMS -Code ',
				errorSMSSwal_TEXT: 'Der eingeführte Telefonvalidierungscode ist falsch. Überprüfen Sie es und stellen Sie es erneut vor. '
			}
		},
		VALIDAR: {
			TITULO: 'Anwendung korrekt gesendet ',
			GRACIAS: 'Vielen Dank, dass Sie der World Funeral People Service vertrauen. ',
			CODIGO: 'Um fortzufahren, geben Sie den an Ihr Telefon gesendeten Verifizierungscode ein. ',
			VALIDAR: 'Code validieren ',
			RECIBIDO: 'Haben Sie den Code nicht erhalten? ',
			CODIGO_ERROR: 'Der eingeführte Code ist nicht gültig ',
			REENVIAR: 'Vorwärtscode ',
			REENVIADO: 'Weitergeleitete Nachricht'
		},
		PROCESO: {
			TITULO: 'In weniger als 5 Minuten bieten wir Ihnen diese Bestattungen an, die Ihren Anforderungen an Qualität und Preis am besten entsprechen. ',
			AVISO: 'Wir werden Sie per SMS und E -Mail benachrichtigen, wenn alle Informationen verfügbar sind. ',
			DOCUMENTACION: 'Wenn Sie möchten, können Sie in diesen wenigen Minuten die Dokumentation lesen und/oder vorbereiten, die für die Verarbeitung des Bestattungsdienstes benötigt wird. ',
			DOC1: 'Fotokopie der verstorbenen Person. ',
			DOC2: 'Fotokopie des Anmelders (der für die Beerdigung verantwortlich ist). ',
			DOC3: 'Todesurkunde (falls bereits ausgestellt). ',
			DOC4: 'Verhältnis der Sachen des Verstorbenen (Schmuck, Kleidung oder andere persönliche Effekte, die aufgezeichnet werden wollen). ',
			DOC5: 'Bezahlverfahren: Karten- oder Bankkontonummer.',
			DOC6: 'Wir informieren Sie auch darüber, dass Sie die Genehmigung und Art des Dienstes bei der Ankunft des ausgewählten Bestattungsunternehmens unterschreiben müssen. '
		},
		CONTRATAR: {
			TITULO: 'Hier bieten wir Ihnen die <b class = "g-font-weight-500"> <i> Begräbnisoptionen </i> </b>, die wir in Betracht ziehen, um Ihre Bedürfnisse besser zu besuchen. ',
			SUBTITULO: 'Klicken Sie jetzt auf Mietverleih und wir werden uns mit dem Bestattungsunternehmen wenden, damit ich den Service so schnell wie möglich anbieten kann.',
			FUNERARIA: 'Bestattungsdaten ',
			CERTIFICADOS: 'Qualitäts- und Exzellenzzertifikate ',
			CERT_NO: 'Es gibt keine Zertifikate ',
			CONTRATAR: 'Mieten Sie jetzt ',
			NOT_FOUND: 'Wir sind der Meinung, dass wir im Moment keinen Service für Ihre Suche gefunden haben. ',
			SOLICITAR: 'Erneut anfordern ',
			LOADING: 'Ladeinformationen ... ',
			PROCESANDO: 'Bearbeitung von Einstellungen ... ',
			SWAL: {
				contratarSwal: 'Serviceeinstellung ',
				contratarSwal_TEXT: 'Bestätigen Sie die Einstellung mit {{importe}} über den Dienst von {{funeraria}}? ',
				contratarSwal_BTN: 'Mieten',
				contratarSwal_CANCELAR: 'Stornieren',
			},
			SUCCESS: {
				TITULO: 'Danke, dass du den Service eingestellt hast ',
				CONTACTO: 'Bald wird das Bestattungsunternehmen Sie kontaktieren, um mit den Verfahren fortzufahren. ',
				CUENTA: 'Benepy von World Funeral People und einen Bericht erstellen. ',
				SERVICIOS: 'Du kannst haben:',
				SERV1: 'Psychologische Aufmerksamkeit für freies Duell. Sie können zwischen verschiedenen Spezialitäten wählen. ',
				SERV2: 'Digitales Erbe. Wir kümmern uns um die Verwaltung der digitalen Informationen der verstorbenen Person. ',
				SERV3: 'Rechtshilfe und Verwaltungsmanagement. Wir wissen, dass diese Situationen viel Bürokratie erfordern, manchmal nichts Einfaches. Wir beraten und helfen Ihnen bei allem, was Sie brauchen. ',
				SERV4: 'Schaffung eines digitalen Schemas. Sie können eine Seite für die verstorbene Person erstellen, die Daten aus der Zeremonie, dem Dank, den Fotos und allen Informationen enthält, die Sie benötigen. ',
				SERV5: 'Finanzierungsdienst. Wir haben Ihnen den Familienfinanzierungsdienst zur Verfügung gestellt. ',
				SERV6: 'Pflegedienste. Wenn Sie Minderjährige oder ältere Menschen haben, die im Moment Aufmerksamkeit und Fürsorge benötigen, helfen wir Ihnen, die richtige Person zu finden. ',
				BOTON: 'EIN KONTO ERSTELLEN'
			}
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Anmeldung',
				BUTTON: 'Anmeldung',
				BIENVENIDA1: 'Willkommen zum Einstellungsprozess Ihres WFN -Ratens {{{tarifa}} ',
				BIENVENIDA1_SUB: 'Melden Sie sich an, um Ihr Konto zu diesem Satz zu verbinden. ',
				BIENVENIDA2: 'Willkommen im WFN -Einstellungsprozess ',
				BIENVENIDA2_SUB: 'Melden Sie sich an, um fortzufahren. ',
				FORGOT_BUTTON: 'Haben Sie Ihr Passwort vergessen?',
				REGISTER: 'Sie haben kein Konto? ',
				REGISTER_BUTTON: 'Melden Sie sich an',
				VOLVER: 'Kehren Sie zum Login zurück.',
			},
			INPUT: {
				EMAIL: 'Email',
				PASSWORD: 'Passwort',
				CONFIRM_PASSWORD: 'Bestätige das Passwort'
			},
			FORGOT: {
				TITLE: 'Passwort wiederherstellen',
				DESC: 'Geben Sie dann den Code der empfangenen E -Mail und das neue Passwort ein.',
				SUCCESS: 'Ihr Passwort wurde korrekt wiederhergestellt. Melden Sie sich mit Ihren neuen Anmeldeinformationen an, um fortzufahren. ',
				INCORRECTO: 'Der eingeführte Wiederherstellungscode ist nicht korrekt. ',
				INVALID: 'Dieser Wiederherstellungsverbindung ist nicht gültig oder wurde bereits verwendet. ',
				LOGIN: 'ANMELDUNG',
				CODIGO: 'Wiederherstellungscode (4 Abbildungen) ',
				BUTTON: 'PASSWORT WIEDERHERSTELLEN',
				EMAIL: 'Eine Wiederherstellungs -Mail wurde an die E -Mail gesendet, die eingeführt wird, wenn sie im System registriert ist. Befolgen Sie seine Anweisungen, um fortzufahren. '
			},
			REGISTER: {
				TITLE: 'Melden Sie sich an',
				BIENVENIDA1: 'Willkommen zum Einstellungsprozess Ihres WFN -Ratens {{{tarifa}} ',
				BIENVENIDA1_SUB: 'Zunächst erstellen wir ein Konto, das mit Ihrem Preis verbunden ist. ',
				BIENVENIDA2: 'Willkommen im WFN -Einstellungsprozess ',
				BIENVENIDA2_SUB: 'Zunächst werden wir ein Konto erstellen. ',
				EMAIL_CORP: 'Nur Firmen-E-Mails (z. B. @your-company.com) werden akzeptiert',
				PAIS: 'Land',
				PAIS_SELECT: 'Land auswählen ... ',
				PERTENEZCO: 'Ich gehöre zu',
				PERTENEZCO_SELECT: 'Sektor auswählen ... ',
				SECTOR1: 'Beerdigungen ',
				SECTOR2: 'Versicherungsgesellschaften',
				SECTOR3: 'Rentenfonds',
				SECTOR4: 'Sicheres Bankgeschäft ',
				SECTOR5: 'Vorhersage- und Vorschriftenunternehmen ',
				SECTOR6: 'Botschaften ',
				SECTOR7: 'Gruppen mit Bestattungsabdeckung ',
				SECTOR8: 'Andere',
				BUTTON: 'Registrieren ',
				LOGIN: 'Haben Sie bereits ein Konto?',
				LOGIN_BUTTON: 'Anmeldung',
				SUCCESS: 'Korrekter Datensatz ',
				SUCCESS_SUB: 'Wir begrüßen Sie im World Funeral Net! ',
				SUCCESS_MAIL: 'Wir haben eine Bestätigungs -E -Mail an die Registrierungsadresse gesendet. Bitte geben Sie den Code ein oder klicken Sie auf den in der E -Mail enthaltenen Link, um auf alle Plattformfunktionen zuzugreifen. ',
				SUCCESS_MAIL2: 'Wir haben eine Bestätigungs -E -Mail an die Registrierungsadresse gesendet. Bitte geben Sie den Code ein oder klicken Sie auf den in der E -Mail enthaltenen Link, um Ihren Preis zu mieten. ',
				ERROR_CODE: 'Der eingeführte Code ist nicht korrekt. ',
				VALIDAR_BUTTON: 'Konto validieren ',
				GRACIAS: 'Vielen Dank für Ihr Vertrauen. '
			},
			VALIDAR: { //NUEVO <--------------
				LOADING: 'Validierung Ihres Kontos, einen Moment bitte ... ',
				SUCCESS: 'Validiertes Konto ',
				SUCCESS_DESC: 'Ihr Konto wurde korrekt validiert. Wir werden den Rekord in ein paar Sekunden fortsetzen ... ',
				ERROR: 'Validierungsfehler ',
				ERROR_DESC: 'Ihr Validierungslink ist nicht korrekt. Überprüfen Sie Ihre E -Mails und verwenden Sie den letzten generierten Link. '
			},
			VALIDATION: {
				INVALID: '{{name}} ist nicht gültig ',
				REQUIRED: '{{name}} muss eingeführt werden ',
				MIN_LENGTH: '{{name}} Mindestlänge ist {{min}} ',
				AGREEMENT_REQUIRED: 'Es ist notwendig, die Allgemeinen Geschäftsbedingungen zu akzeptieren ',
				NOT_FOUND: 'Das geknackte {{name}} ist nicht gefunden ',
				INVALID_LOGIN: 'Die eingegebenen Daten sind nicht korrekt. Versuchen Sie es erneut.',
				INACTIVE: 'Ihr Konto ist inaktiv. Überprüfen Sie Ihre E -Mails und klicken Sie auf den Validierungslink. ',
				REQUIRED_FIELD: 'Sie müssen dieses Feld ausfüllen ',
				MIN_LENGTH_FIELD: 'Die Mindestgröße dieses Feldes ist:',
				MAX_LENGTH_FIELD: 'Die maximale Größe dieses Feldes ist:',
				INVALID_FIELD: 'Dieses Feld ist nicht gültig ',
				MATCH: 'Das Passwort und die Bestätigung stimmen nicht überein ',
				INVALID_REGISTER: 'Die eingeführte E -Mail ist bereits registriert. Versuchen Sie es erneut oder melden Sie sich an. '
			}
		},
		HOME: {
			BIENVENIDA: 'Hallo',
			SALIR: 'Abmelden',
			SERVICIOS: {
				TITULO: 'Angeheuerte Dienste ',
				DETALLES: 'Servicedetails ',
				CATERING: 'Catering ',
				CORONAS: 'Kronen ',
				OTRAS: 'Andere Spezifikationen ',
				FUNERARIA: 'Leichenhalle',
				WEB: 'Website'
			},
			PAGINA: {
				TITULO: 'Seite des Verstorbenen erstellen ',
				TITULO_EDIT: 'Seite des Verstorbenen bearbeiten ',
				DETALLE: 'Hier können Sie die Seite der verstorbenen Person erstellen, um die Informationen, die Ihre Familie und Freunde sehen möchten, wie Zeitpläne oder Details des Dienstes und der Zeremonie, Fotos, Zeitpläne usw. zu platzieren. ',
				SIGUIENTE: 'Folgt ',
				VOLVER: 'Zurückkehren',
				PASO1: 'Daten aus dem Verstorbenen ',
				PASO2: 'Zeremoniedaten ',
				PASO3: 'Erinnere dich an deinen geliebten Menschen ',
				PASO4: 'Aktie ',
				NOMBRE: 'Name',
				APELLIDO1: 'Nachname',
				APELLIDO2: 'Zweiter Nachname ',
				FECHA_NAC: 'Geburtsdatum',
				FECHA_FAC: 'Sterbedatum',
				PASO2_DETALLE: 'Vervollständigen Sie die Zeremoniedaten, um Familie und Freunde zu benachrichtigen.',
				FECHA: 'Datum',
				HORA: 'Stunde',
				NOMBRE_FUN: 'Begräbnisname, Tanatorio oder Feierort ',
				DIRECCION: 'Adresse, Straße und Nummer ',
				CP: 'CP ',
				LOCALIDAD: 'Standort',
				PROVINCIA: 'Autonome Gemeinschaft/Staat ',
				PAIS: 'Land',
				DESCRIPCION: 'Kurze Beschreibung der Zeremonie (Messe, Antwort, Abschied oder ein anderer) ... ',
				PASO3_DETALLE: 'Fügen Sie alles hinzu, an das Sie sich von Ihren geliebten Menschen erinnern möchten, Details, Anekdoten, Geschichten, zusätzlich zu Bildern und Videos. ',
				RECUERDO: 'Schreiben Sie hier über Ihren geliebten Menschen.',
				IMAGEN_TITULO: 'Laden Sie Bilder der verstorbenen Person hoch, ',
				IMAGEN_PORTADA: 'Wählen Sie das Titelfoto der Seite ',
				IMAGEN_SLIDER: 'Wählen Sie andere Fotos für die Seite (6 Bilder max.). ',
				ARRASTRA: 'Ziehen oder klicken Sie hier, um das Bild hinzuzufügen ',
				IMAGEN_SUBIR: 'Zunahme',
				IMAGEN_BORRAR: 'Löschen',
				SUBIR_ERR: 'Falsches Format ',
				SUBIR_ERR_TEXT: 'Die Datei muss ein Bildformat haben ',
				COMENTARIOS: 'Kommentare von Freunden und Familie. ',
				COMENTARIOS_CHECK: 'Möchten Sie die Option aktivieren, dass Ihre Freunde und Familie Kommentare auf der Seite hinterlassen können? ',
				PASO4_DETALLE: 'Teilen Sie die Seite mit Ihrer Familie und Ihren Freunden. ',
				OPCION_TITULO: 'Möchten Sie die Option ermöglichen, dass Ihre Freunde und Familie auch die Seite teilen können? ',
				OPCION_CHECK: 'Aktivieren Sie die Option zum Teilen ',
				OPCIONES: 'Wählen Sie die Optionen aus, die Sie teilen möchten. ',
				PREVISUALIZAR: 'Vorschau ',
				LOADING: 'Vorbereitung der Planung der Seite ... ',
				// - Pagina ya creada
				TITULO_CREADA: 'Seite des Verstorbenen ',
				BORRAR: 'Löschen',
				borrarSwal: 'Kommentar löschen ',
				borrarSwal_TEXT: 'Sind Sie sicher, diesen Kommentar zu beseitigen? Sie werden seinen Inhalt nicht wiederherstellen können. ',
				borrarSwal_BTN1: 'Ja, löschen ',
				borrarSwal_BTN2: 'Stornieren',
			},
			TRAZABILIDAD: {
				TITULO: 'Service -Rückverfolgbarkeit ',
				NO: 'Es sind keine Spuren verfügbar ',
				ACTUALIZAR: 'Aktualisieren'
			},
			PSICOLOGO: {
				TITULO: 'Freier Psychologe zu Duell ',
				DETALLE: 'Wir haben Ihnen das kostenlose Telefon für die psychologische Pflege zur Verfügung gestellt. ',
				CODIGO: 'Identifizieren Sie sich als WFP -Kunde mit dem folgenden Code:',
				CAMPOS: 'Um Ihnen die Aufmerksamkeit entsprechend Ihren Bedürfnissen zu erteilen, füllen Sie die folgenden Felder aus. ',
				PERSONA: 'Welcher geliebte Mensch ist verstorben? ',
				PERSONA_SEL: 'Wählen Sie die Person ',
				PADRES: 'Vater Mutter',
				HIJOS: 'Sohn/A ',
				HERMANO: 'Bruder/a ',
				OTRO: 'Andere',
				MOTIVO: 'Was war der Grund für den Tod? ',
				MOTIVO_SEL: 'Wählen Sie den Grund aus, ',
				LOADING: 'Serviceinformationen senden ... ',
				ENVIAR: 'Sende Informationen',
				VER_CODIGO: 'Siehe Code ',
				MOTIVO1: 'Tod von Nicht -Natos oder Kindern ',
				MOTIVO2: 'Unerwarteter Tod durch Zufall ',
				MOTIVO3: 'Tod durch lange Krankheit ',
				MOTIVO4: 'Multiple Tod (Ehe, zwei Mitglieder oder mehr als eine Familie), ',
				MOTIVO5: 'Gerichtlicher Tod (Selbstmord, gewalttätiger Tod, andere), ',
				MOTIVO6: 'Andere Motive '
			},
			LEGAL: {
				TITULO: 'Rechtsberatung',
				DETALLE: 'Ein Team von Fachleuten hilft Ihnen und berät Ihnen zu jedem Verfahren oder Verfahren. '
			},
			JURIDICA: {
				TITULO: 'Rechtsberatung',
				DETALLE: 'Wir helfen Ihnen bei der Lösung aller notwendigen Verwaltungsverfahren wie Beruhigungen, Renten, niedrigen Verträgen usw. '
			},
			DIGITAL: {
				TITULO: 'Digitales Lebensmanagement ',
				DETALLE: 'Wir verwalten und machen den Übergang, um alle Konten, Abonnements, sozialen Netzwerke und digitalen Profile der Person zu kündigen. '
			},
			FINANCIA: {
				TITULO: 'Finanzierungsdienst ',
				DETALLE: 'Wir haben Ihnen den Familienfinanzierungsdienst zur Verfügung gestellt. Die Wirtschaft sollte momentan kein Problem für Sie sein. '
			},
			DOMESTIC: {
				TITULO: 'Inländischen Dienstleistungen',
				DETALLE: 'Wir ermöglichen die dringende Hilfe für die Betreuung von Erwachsenen, Kindern oder anderen Bedürfnissen jetzt oder in Zukunft. '
			},
		},
		WFP: {
			CEREMONIA: 'Zeremonie',
			MEMORIA: 'In Erinnerung an',
			FALLECIDO: 'Tot das {{fecha}} in {{lugar}}, zum {{edad}} Jahre alt. ',
			FECHA_UBIC: 'Datum, Uhrzeit und Ort ',
			COMPARTE_WEB: 'Teilen Sie die Zeremonie von ',
			ENVIAR_FLORES: 'Senden Sie Blumen an ',
			PROVEEDORES: 'Wir bieten Ihnen Lieferanten von hervorragendem Preis -Leistungs -Verhältnis.',
			COMPRAR: 'Kaufen',
			MENSAJE: 'Zeremonie in Erinnerung an ',
			SMS: 'Geben Sie bis zu 6 Mobiltelefone ein, um die Seite pro SMS zu teilen. ',
			SMS_NAME: 'Telefon',
			EMAIL: 'Geben Sie bis zu 6 E -Mail -Adressen ein, um die Seite per E -Mail zu teilen. ',
			EMAIL_NAME: 'Email',
			ENVIAR: 'Seite teilen ',
			VOLVER: 'Kehre zu meinem Profil zurück ',
			NO_DISPONIBLE: 'Die Seite ist derzeit nicht verfügbar. ',
			COMENTARIOS: 'Kommentare von Familie und Freunden ',
			COMENTARIOS_TITULO: 'Veröffentlicht einen neuen Kommentar ',
			COMENTARIOS_NOMBRE: 'Name',
			COMENTARIOS_INPUT: 'Kommentar',
			COMENTARIOS_BOTON: 'Kommentar senden ',
			COMENTARIOS_ENVIADO: 'Kommentarmacher richtig '
		},
		PREVIEW: {
			PREVISUALIZACION: 'Planungsseitenzeremonie des Verstorbenen ',
			VER: 'Siehe in Version ',
			EDITAR: 'Seite bearbeiten ',
			FINALIZAR: 'Fertigstellen und teilen ',
			COMPARTE: 'Teilen Sie die Seite von ',
			COMPARTE2: 'Mit Ihren Kontakten ',
			COMPARTIR: 'Teilen durch ',
			ACCEDE: 'Greifen Sie auf die Seite zu oder kopieren Sie Ihren Link ',
			CONFIRMACION: 'Seite richtig gespeichert ',
			NO_PUBLICADA: 'Unveröffentlichte Seite ',
			PREVISUALIZAR: 'Vorschau und Veröffentlichung ',
		},
























		




		MENU: {
			SEGUIMIENTO: 'seguimiento<br>de mis pujas',
			SEGUIMIENTO2: 'seguimiento de mis pujas',
			EJECUCION: 'en<br>ejecución',
			EJECUCION2: 'en ejecucion',
			TODAS: 'ver todas<br>las pujas',
			TODAS2: 'ver todas las pujas',
			CREAR: 'crear<br>licitación',
			CREAR2: 'crear licitación',
			EXPLORAR: 'explorar mis<br>licitaciones',
			EXPLORAR2: 'explorar mis licitaciones',
			PENDIENTES: 'pendientes<br>de adjudicar',
			PENDIENTES2: 'pendientes de adjudicar',
			TODAS_LIC: 'ver todas<br>las licitaciones',
			TODAS_LIC2: 'ver todas las licitaciones',
			CONTRATAR: 'CONTRATAR',
			PERFIL: 'Mi Perfil',
			LOGOUT: 'Cerrar Sesión'
		},
		FOOTER: {
			CONTACTA: 'Contacta con nosotros',
			SEDES: 'Sedes en: Madrid, Mexico, Lisboa y Colonia.',
			AVISO_LEGAL: 'Aviso legal',
			POLITICA: 'Política de privacidad'
		},
		DEMO: {
			LOADING: 'Preparando la plataforma, un momento por favor...',
			BIENVENIDA: 'Le damos la bienvenida a WFN',
			SECTOR: 'Seleccione el sector al que pertenece para acceder a la plataforma de demostración.',
			FUNERARIA: 'SOY OPERADOR FUNERARIO',
			ASEGURADORA: 'SOY ASEGURADORA, EMPRESA O COLECTIVO',
			HOME: { //NUEVO3 <--------------
				STEP1: '¡Bienvenid@ a WFN! Esta es la plataforma de demostración. Vamos a descubrir las funcionalidades de la plataforma y después podrás interactuar con ella y hacer las pruebas que necesites.',
				STEP2: 'Este es el menú principal. Desde aquí podrás acceder a las diferentes páginas disponibles.',
				STEP3: 'Aquí podrás hacer el seguimiento de las licitaciones en las que has participado.',
				STEP4: 'Aquí se muestran las licitaciones que te han sido asignadas. En ellas podrás consultar la información del adjudicatario y rellenar la trazabilidad.',
				STEP5: 'Finalmente, aquí se muestra el listado completo de las licitaciones disponibles. Vamos a ir a esta página para verlas más en detalle.',
				STEP6: 'Podrás ofertar tus licitaciones en este menu. Insertarás sus detalles y serán publicadas en el listado. Podrás probarlo al finalizar esta demostración.',
				STEP7: 'Una vez creadas, encontrarás en esta página todas tus licitaciones.',
				STEP8: 'Cuando una licitación haya finalizado, podrás encontrar todas las que estén pendientes de asignar a una funeraria en esta pantalla.',
				STEP9: 'Aquí se muestran las licitaciones que ya has asignado a una funeraria y se encuentran en ejecución. En ellas podrás consultar la información de la funeraria y ver su trazabilidad.',
				STEP10: 'Finalmente, aquí se muestra el listado completo de las licitaciones disponibles. Vamos a ir a esta página para verlas más en detalle.',
			},
			LICIT: {
				STEP1: '¡Bienvenid@ a WFN! Esta es la plataforma de demostración. Vamos a descubrir las funcionalidades de la plataforma y después podrás interactuar con ella y hacer las pruebas que necesites.',
				STEP2: 'Cada “card” que ves, es una licitación puesta por una aseguradora o colectivo. En ella encontrarás toda información y detalles de los servicios, tales como localización, el tiempo restante, el importe actual, estado del servicio etc.',
				STEP3: 'En la zona superior encontrarás la referencia de la licitación, junto a la fecha de creación. Así mismo, podrás eliminarlas en cualquier momento (se avisará a los participantes) y editarlas cuando aún no hayan participado en ella. Si la licitación se queda desierta podrás editarla también una vez finalizada.',
				STEP4: 'En la zona superior encontrarás la referencia que el sistema ha asignado a la licitación, junto a la fecha de creación.',
				STEP5: "Estos son los servicios que hay que prestar en una licitación. Al pulsar sobre ellos podrás ver su detalle general. Los servicios con el icono <b style='color: red'>+</b> indican que el licitador ha incluido un detalle específico",
				STEP6: 'A continuación se indica la localización en la que se realizará el servicio o el origen y destino en los traslados',
				STEP7: 'Este es el tiempo restante para que finalice la licitación y por tanto el tiempo para pujar.',
				STEP8: 'En este espacio observarás el estado actual de la licitación. Esta puede estar abierta, pendiente de adjudicación, pendiente de ejecución, completada, cerrada o desierta.',
				STEP9: 'Finalmente encontrarás el importe actual de la licitación y podrás acceder al detalle de sus pujas o inscritos.',
				STEP10: 'Una vez se haya asignado una licitación podrás ver el progreso de la trazabilidad y los detalles de la funeraria adjudicada.',
				STEP11: 'Además, en el siguiente menu podrás ver el detalle de la trazabilidad que rellenará la funeraria.',
				STEP12: 'En este espacio observarás el estado actual de la licitación. Esta puede estar abierta (ganando/perdiendo/inscrito), en trámite, en ejecución, completada o desierta.',
				STEP13: 'Finalmente encontrarás el importe actual de la licitación y podrás pujar o inscribirte en la misma.',
				STEP14: 'Una vez te hayan asignado una licitación podrás ver los detalles de la empresa adjudicadora.',
				STEP15: 'Si la licitación se encuentra en ejecución podrás rellenar la información de trazabilidad del servicio.',
				STEP16: 'Podrás personalizar la vista de cards en todo momento con los diferentes filtros disponibles.',
				STEP17: 'Esto es todo. Recuerda que estás en la plataforma de demostración. Podrás interactuar con las licitaciones disponibles, crear nuevas y hacer todas las pruebas que necesites.',
				STEP18: 'Esto es todo. Recuerda que estás en la plataforma de demostración. Podrás interactuar con las licitaciones disponibles, pujar o inscribirte en ellas y hacer todas las pruebas que necesites.',
			}
		},
		CONFIRMACION: {
			LOADING: 'Procesando la contratación...',
			SUCCESS: 'Contratación completada',
			SUCCESS_SUB: 'Serás redirigido a la plataforma en unos segundos...',
			PENDING: 'Confirmación de cuenta pendiente',
			PENDING_SUB: 'Antes de continuar, su cuenta debe ser confirmada por la organización. La organización se reserva el derecho de admisión, valoraremos su ingreso en 24 – 48 H y podrá finalizar el proceso de contratación.',
			PAGO: 'Accediendo a la confirmación de pago...'
		},
		PAGO: {
			LOADING: 'Accediendo a plataforma segura de pagos...',
			RESULT_LOADING: 'Cargando datos de suscripción...',
			METODO: 'Selección de método de pago',
			METODO_SUB: 'Seleccione su método de pago para finalizar la activación de su cuenta.',
			METODO_TARJETA: 'PAGO CON TARJETA DE CRÉDITO',
			METODO_SEPA: 'PAGO CON DOMICILIACIÓN BANCARIA (SEPA)',
			SEPA_TITLE: 'Pago con domiciliación bancaria - SEPA',
			SEPA_NOMBRE: 'Nombre Titular',
			SEPA_IBAN: 'Número de cuenta (IBAN)',
			SEPA_CODIGO: 'Código de promoción',
			SEPA_ERRORCOD: 'El código introducido no es correcto',
			SEPA_BUTTON: 'Suscribirme a WFuneralNet',
			SEPA_LOADING: 'Procesando datos...',
			SEPA_LEGAL: 'Al proporcionar su información de pago y confirmar este pago, usted autoriza a WFuneralNet y a Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para que cargue el importe en su cuenta y a su banco para que cargue el importe en su cuenta de acuerdo con dichas instrucciones. Como parte de sus derechos, tiene derecho a un reembolso por parte de su banco según los términos y condiciones de su acuerdo con su banco. Sus derechos se explican en una declaración que puede obtener de su banco. Usted acepta recibir notificaciones de futuros cargos hasta 2 días antes de que se produzcan.',
			SUCCESS: 'Su suscripción ha sido activada correctamente.',
			SUCCESS_SUB: 'Puedes comenzar a usar la plataforma con toda su funcionalidad. Pulsa en el siguiente botón para acceder al inicio.',
			SUCCESS_BUTTON: 'COMENZAR A UTILIZAR WORLD FUNERAL NET',
			CANCEL: 'Has cancelado el proceso de suscripción.',
			CANCEL_SUB: 'Si ha ocurrido un error o quieres volver a intentarlo, pulsa en el siguiente botón para acceder a la plataforma segura de pagos.',
			CANCEL_BUTTON: 'CONTINUAR A PLATAFORMA SEGURA DE PAGO'
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra a tu medida',
				INVITADO: 'Los filtros se encuentran desactivados en la cuenta de invitado.',
				CONTRATAR: 'Contratar',
				REFERENCIA: 'Referencia',
				SERVICIO: 'Servicio',
				LOC_ORIGEN: 'Localización origen',
				LOC_DESTINO: 'Localización destino',
				TODOS: 'Todos',
				PAIS: 'País',
				COMUNIDAD: 'Comunidad autónoma',
				ESTADO: 'Estado',
				FECHA_DESDE: 'Fecha desde',
				FECHA_HASTA: 'Fecha hasta',
				PRECIO_MIN: 'Precio min.',
				PRECIO_MAX: 'Precio max.',
				ESTADO_LIC: 'Estado licitación',
				ESTADOS: {
					PENDIENTE_INI: 'Pendiente Inicio',
					ABIERTA: 'Abierta',
					PENDIENTE_ADJ: 'Pendiente adjudicación',
					ADJ_TRAM: 'Adjud. en trámite',
					ADJ_PEN: 'Adjud. y pendiente',
					EJEC: 'En ejecución',
					COMPLETADA: 'Completada',
					CERRADA: 'Cerrada',
					DESIERTA: 'Desierta'
				},
				REESTABLECER: 'Reestablecer filtros'
			},
			ORDEN: {
				ESTADO: 'Ordenar por estado de licitación',
				ESTADO_ABIERTAS: 'Mostrar primero abiertas',
				ESTADO_PENDIENTE: 'Mostrar primero pendientes',
				ESTADO_ADJUD: 'Mostrar primero adjudicadas',
				FECHA: 'Ordenar por fecha de inicio',
				FECHA_ASC: 'Ascendente',
				FECHA_DESC: 'Descendente',
				TIEMPO: 'Ordenar por tiempo hasta finalización',
				TIEMPO_ASC: 'Ascendente',
				TIEMPO_DESC: 'Descendente'
			},
			LOADING: 'Cargando licitaciones...',
			NOHAY: 'No hay licitaciones',
			//NUEVO2 --------------
			CARD: {
				ORIGEN: 'Origen',
				DESTINO: 'Destino',
				TRASLADO: 'Traslado',
				LOCALIZ: 'Localización',
				PUJA: 'puja',
				OFERTA: 'oferta',
				PUJAR: 'pujar',
				OFERTAR: 'ofertar',
				LA: 'La',
				TIME: 'Tiempo restante para',
				COMIENZA: 'comienza en',
				VER_ASEG: 'ver aseguradora',
				VER_FUN: 'ver funeraria',
				VER_DETALLES: 'ver detalles',
				ESTADOS: {
					PENDIENTE_INI: 'Pendiente de inicio',
					ABIERTA: 'Abierta',
					INSCRITO: 'Inscrito',
					GANANDO: 'Ganando',
					PERDIENDO: 'Perdiendo',
					CONTU: 'con tu',
					PUJA: 'puja',
					OFERTA: 'oferta',
					DE: 'de',
					PENDIENTE_ADJ: 'Pendiente',
					PENDIENTE_ADJ2: 'adjudicación',
					CERRADA: 'Cerrada',
					ADJ_TRAM: 'Adjudicación en trámite',
					ADJ_PEN: 'Adjudicada y',
					ADJ_PEN2: 'pendiente de ejecución',
					EJEC: 'En ejecución',
					COMPLETADA: 'Completada',
					NO_ADJ: 'No adjudicado',
					DESIERTA: 'Desierta'
				},
				PUJA_SALIDA: 'Puja salida',
				OFERTA_SALIDA: 'Oferta salida',
				PUJA_ACTUAL: 'Puja actual',
				OFERTA_ACTUAL: 'Oferta actual',
				PUJA_GANADORA: 'Puja ganadora',
				OFERTA_GANADORA: 'Oferta ganadora',
				PUJA_DESIERTA: 'Puja desierta',
				OFERTA_DESIERTA: 'Oferta desierta',
				PUJA_ADJUDIC: 'Puja adjudic.',
				OFERTA_ADJUDIC: 'Oferta adjudic.',
				PRECIO_FIJO: 'Precio fijo',
				VER_PUJAS: 'VER PUJAS',
				VER_OFERTAS: 'VER OFERTAS',
				INSCRITOS: 'INSCRITOS',
				ADJUDICAR: 'ADJUDICAR',
				TRAZABILIDAD: 'TRAZABILIDAD',
				INSCRÍBETE: 'INSCRÍBETE',
				PUJAR2: 'PUJAR',
				OFERTAR2: 'OFERTAR',
				VOLVER_PUJAR: 'VOLVER A PUJAR',
				VOLVER_OFERTAR: 'VOLVER A OFERTAR',
				IVA_INC: 'IVA no incluido',
				HIST: {
					HISTORICO_PUJAS: 'Histórico de pujas',
					HISTORICO_OFERTAS: 'Histórico de ofertas',
					HISTORICO_INSC: 'Histórico de inscritos',
					IMPORTE_SALIDA: 'Importe salida',
					ACTUALIZAR: 'actualizar',
					TOTAL: 'Total',
					FECHA: 'Fecha',
					USUARIO: 'Usuario',
					IMPORTE: 'Importe',
					DESIERTA: 'Licitación desierta',
					CREADOR: 'CREADOR',
				},
				CERRAR: 'cerrar',
				ASIG: {
					NOMBRE: 'Nombre',
					LICENCIA: 'Licencia',
					DOMICILIO: 'Domicilio',
					TELEFONO: 'Teléfono',
					EMAIL: 'Email',
					WEB: 'Página Web',
					CERTIFICACIONES: 'Certificaciones',
					PUNTUACIONES: 'Puntuaciones',
					IMPORTE: 'Importe licitación',
					ADJUDICAR: 'ADJUDICAR',
					EXCLUIR: 'EXCLUIR',
					EXCLUIR_TODAS: 'EXCLUIR TODAS',
					BLOQUEAR: 'BLOQUEAR PROV.',
					VER_DETALLES: 'ver detalles',
					CERRAR_DETALLES: 'cerrar detalles',
					DETALLE_ASIG1: 'La Aseguradora se reserva el derecho de adjudicación valorando la calidad de los servicios ofrecidos por la Funeraria.',
					DETALLE_ASIG2: 'Es importante que completes tu perfil con puntuaciones y certificados de calidad.',
					DETALLE_ASIG_BTN: 'Completar perfil',
					REFERENCIA: 'Referencia licit. WFN',
					REFERENCIA2: 'Referencia licit. creador',
					SIGUIENTES_PUJAS: 'Siguientes pujas',
					SIGUIENTES_OFERTAS: 'Siguientes ofertas'
				},
				PUJAS: {
					PUJA_RAPIDA: 'Puja rápida',
					OFERTA_RAPIDA: 'Oferta rápida',
					PUJA_MANUAL: 'o introduce manualmente tu puja',
					OFERTA_MANUAL: 'o introduce manualmente tu oferta',
					PUJAR: 'PUJAR',
					OFERTAR: 'OFERTAR',
					AVISO: 'Aviso',
					PRORROGA3_PUJA: 'La licitación se extenderá en 3 minutos al pujar durante el minuto final',
					PRORROGA3_OFERTA: 'La licitación se extenderá en 3 minutos al ofertar durante el minuto final',
					PRORROGA1_PUJA: 'La licitación se extenderá en 1 minuto al pujar durante el minuto final',
					PRORROGA1_OFERTA: 'La licitación se extenderá en 1 minuto al ofertar durante el minuto final',
					PROCESO_PUJA: 'Su puja se está procesando. Un momento por favor...',
					PROCESO_OFERTA: 'Su oferta se está procesando. Un momento por favor...',
					HISTORICO_PUJAS: 'Ver histórico de pujas',
					HISTORICO_OFERTAS: 'Ver histórico de ofertas'
				},
				TRAZA: {
					INCIDENCIA: 'Incidencia en el servicio',
					VALIDAR: 'VALIDAR',
					CANCELAR: 'CANCELAR'
				},
				SERVICIO: {
					TIPO_TRASLADO: 'Tipo traslado',
					CARRETERA: 'Carretera punto a punto',
					AEROPUERTO: 'Aeropuerto',
					INCLUIDOS: 'Servicios Incluídos',
					TIPO_COMPLETO: 'Tipo Serv. Completo',
					CREMACION: 'Cremación + Urna',
					DESTFIN: 'Destino Final Temp.',
					DETALLE_ESP: 'Detalle específico del servicio',
					ESPEC_GEN: 'Especificaciones generales del servicio',
					TRASLADO1: 'El fallecimiento y el destino final (inhumación o incineración) se produce en localidades distintas.',
					TRASLADO2: 'Elementos y servicios habituales del traslado:',
					TRASLADO3: 'Arca de traslado : En aquellos casos en los que el Reglamento de Policía Sanitaria Mortuoria (RPSM) lo exija deberá estar provisto adicionalmente de un dispositivo de filtrado de aire u otro para equilibrar la presión interior y exterior.',
					TRASLADO4: 'Flete aéreo en su caso, Kms. realizados por carretera y gestiones y trámites específicos.'					
				}
			},
			SWAL: { 
				CANCELAR: 'Cancelar',
				registerSwal: 'Contratación Necesaria',
				registerSwal_TEXT: 'Este contenido no se encuentra disponible con una cuenta de invitado. Contrata una tarifa para continuar.',
				registerSwal_BTN1: 'Contratar',
				suscriptionSwal: 'Licitación no disponible',
				suscriptionSwal_TEXT: 'Este contenido no se encuentra disponible con tu suscripción. Revisa tu tarifa para acceder.',
				suscriptionSwal_BTN1: 'Mi Perfil',
				confirmationSwal: 'Licitación Correcta',
				confirmationSwal_TEXT: 'Tu importe se ha licitado correctamente',
				errorSwal: 'Importe Incorrecto',
				errorSwal_TEXT: 'El importe debe ser inferior al actual de la licitación.',
				errorNegativeSwal_TEXT: 'El importe de la licitación no puede ser negativo.',
				error2Swal_TEXT: 'El importe de la licitación ha cambiado y ahora es inferior a tu propuesta. Inténtelo de nuevo con otro importe.',
				confirmaSwal: 'Confirmación',
				confirmaSwal_TEXT1: '¿Confirmas la licitación por {{importeLicitar}} {{divisa}} ?',
				confirmaSwal_TEXT2: '¿Confirmas la inscripción a la licitación? Recibirás una notificación si eres el elegido al finalizar.',
				confirmaSwal_BTN1: 'Si, confirmo',
				confirmaElegidoSuccessSwal: 'Usuario elegido correctamente.',
				confirmaElegidoSuccessSwal_TEXT: 'El adjudicatario se ha asignado correctamente. A continuación puedes ver sus detalles.',
				confirmaElegidoSwal_TEXT1: '¿Confirmas a {{empresa}} como adjudicatario de la licitación?',
				confirmaSaltarSwal_TEXT1: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más exlusiones, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones',
				confirmaSaltarSwal_TEXT2: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones. Exclusión {{pasadas}}/2',
				confirmaSaltarSwal_TEXT3: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación? No hay más exlusiones, la licitación se quedará desierta.',
				confirmaSaltarSwal_TEXT4: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación? No podrás volver a este usuario. Exclusión {{pasadas}}/2',
				confirmaSaltarSwal_TEXT5: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más participantes, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones',
				confirmaSaltarSwal_TEXT6: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones.',
				confirmaSaltarSwal_TEXT7: '¿Confirmas excluir a todos los participantes? La licitación se quedará desierta.',
				confirmaSaltarSuccessSwal1: 'Participante excluido correctamente.',
				confirmaSaltarSuccessSwal2: 'Participante bloqueado correctamente.',
				confirmaSaltarSuccessSwal_TEXT1: 'Se ha excluido al participante. Recuerda que solo puedes excluir 2 veces por licitación.',
				confirmaSaltarSuccessSwal_TEXT2: 'Se ha excluido al participante. La licitación ha quedado desierta.',
				confirmaSaltarSuccessSwal_TEXT3: 'Se ha excluido al participante de la licitación.',
				confirmaSaltarSuccessSwal_TEXT4: 'Se ha excluído al participante y no volverá a ver tus licitaciones. La licitación ha quedado desierta.',
				confirmaSaltarSuccessSwal_TEXT5: 'Se ha excluído al participante y no volverá a ver tus licitaciones.',
				confirmaTrazaSwal: 'Confirmación de traza',
				confirmaTrazaSwal_TEXT: '¿Deseas confirmar esta traza? Se informará al licitador.',
				confirmaTrazaSwal_BTN1: 'Si, confirmo',
				errorTrazaSwal: 'Error en la traza',
				errorTrazaSwal_TEXT: 'Debes introducir un comentario del servicio al haber una incidencia en el mismo.',
				confirmaTrazaSuccessSwal: 'Traza confirmada correctamente.',
				confirmaTrazaSuccessSwal_TEXT: 'La traza seleccionada se ha confirmado correctamente.',
				cancelaTrazaSwal: 'Cancelación de traza',
				cancelaTrazaSwal_TEXT: '¿Deseas cancelar esta traza? Se borrará toda la información de la misma.',
				cancelaTrazaSwal_BTN1: 'Si, confirmo',
				cancelaTrazaSuccessSwal: 'Traza cancelada correctamente.',
				cancelaTrazaSuccessSwal_TEXT: 'La traza seleccionada se ha cancelado correctamente.',
				borrarSwal: 'Eliminar Licitación',
				borrarSwal_TEXT: '¿Estás seguro de eliminar esta licitación? No podrás recuperar su contenido.',
				borrarSwal_BTN1: 'Si, eliminar',
				borrarConfirmSwal: 'Licitación eliminada correctamente',
				borrarConfirmSwal_TEXT: 'Se ha eliminado correctamente la licitación.',
				conexionSwal: 'Límite de conexiones superado',
				conexionSwal_TEXT: 'Se ha superado el número de conexiones simultáneas disponible. Cierra alguna de las sesiones activas para continuar.'
			},
			TUTORIAL: 'VER TUTORIAL DE NUEVO'
		},
		GLOBAL: {
			PAGO: 'Pago de suscripción pendiente.',
			PAGO_SUB: 'Aún no nos has proporcionado los datos de pago de tu suscripción. Pulsa el botón para acceder a la plataforma segura de pagos.',
			PAGO_BUTTON: 'PLATAFORMA SEGURA DE PAGOS'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} debe ser introducido',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Es necesario aceptar los términos y condiciones',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		},
		CREAR: {
			TITULO: 'Crear licitación',
			TITULO_EDIT: 'Editar licitación',
			REF: 'Referencia del cliente',
			REF2: '(no será mostrado públicamente)',
			REF_ERROR: 'La referencia ya registrada en el sistema.',
			TIPO: 'Tipo de licitación',
			ESTANDAR: 'Licitación estándar.',
			ESTANDAR_DESC: 'Introduce una cifra de salida. Las funerarias pujarán a la baja para adjudicarse el servicio o traslado.',
			FIJO: 'Licitación a precio fijo.',
			FIJO_DESC: 'El servicio se adjudicará al precio establecido entre todas las funerarias que lo hayan solicitado.',
			FECHA_INI: 'Fecha de inicio/hora',
			FECHA_FIN: 'Fecha de finalización/hora',
			FECHA_ERROR: 'La fecha de finalización no puede ser anterior a la fecha inicio.',
			OPT_TRASLADO: 'Licitar traslado',
			OPT_SERVICIO: 'Licitar servicio',
			IMPORTE: 'Importe de salida',
			IMPORTE_ERROR1: 'El importe del traslado no puede ser negativo',
			IMPORTE_ERROR2: 'El importe del traslado debe ser inferior a 100.000',
			DETALLE: 'Detalle específico del servicio',
			ORIGEN: 'ORIGEN',
			PAIS: 'País',
			COMUNIDAD: 'Comunidad autónoma',
			ESTADO: 'Estado',
			POBLACION: 'Población',
			DESTINO: 'DESTINO',
			KMS: 'KMs a destino',
			OPCIONAL: '(opcional)',
			LOCALIZACION: 'LOCALIZACIÓN',
			DESTINO_TRASLADO: '(Destino del traslado)',
			PENDIENTE_DESTINO: 'Pendiente seleccionar destino del traslado',
			SERVICIO_ERROR: 'Debes seleccionar al menos un servicio a licitar',
			IMPORTE_ERROR3: 'El importe del traslado no puede ser negativo',
			IMPORTE_ERROR4: 'El importe del traslado debe ser inferior a 100.000',
			INCLUIDO_EN: 'Incluído en',
			INCLUIDO_EN2: 'Servicio Completo',
			SELECT_OPCION: 'Seleccione una opción',
			OPCION_ERROR: 'Debes seleccionar una opción',
			OTROS_OPCION: 'Seleccione otros servicios a incluir',
			CREACION: 'Formato de creación',
			UNIDO: 'Misma licitación.',
			UNIDO_DESC: 'El traslado y los servicios se ofertarán en una misma licitación. La funeraria adjudicada será la misma para ambos.',
			SEPARADO: 'Licitaciones separadas.',
			SEPARADO_DESC: 'El traslado y los servicios se ofertarán en licitaciones separadas. La funeraria adjudicada puede ser distinta en ambas.',
			BOTON: 'AÑADIR LICITACIÓN',
			BOTON_EDIT: 'EDITAR LICITACIÓN',
			SWAL: {
				confirmationAddSwal: 'Licitación Añadida',
				confirmationAddSwal_TEXT: 'Los servicios seleccionados se han añadido correctamente al listado de licitaciones',
				confirmationEditSwal: 'Licitación Editada',
				confirmationEditSwal_TEXT: 'La licitación se ha editado correctamente',
				errorLicitacionSwal: 'Licitación con pujas',
				errorLicitacionSwal_TEXT: 'Esta licitación ya ha recibido pujas. No es posible hacer modificaciones, puedes borrarla y crearla de nuevo.'
			}
		},
		START_old: {
			PASOS: {
				DATOS: 'Datos de contratante',
				VALIDACION: 'Validación de datos',
				ADICIONAL: 'Información adicional',
				PAGO: 'Datos de pago',
				CONFIRMACION: 'Confirmación',
				TARIFA: 'Selección de tarifa',
				TARIFA2: 'Información de tarifa'
			},
			TARIFA_LOC: 'Indique la localización de aplicación de tarifa',			
			CANALES: 'Quiero ser notificado a través de los siguientes canales: <i>(al menos uno)</i>',
			VOLVER: 'VOLVER',
			CONTINUAR: 'CONTINUAR',
			VALIDACION: 'Validación de datos',
			SMS: {
				TITULO: 'Validación de teléfono móvil',
				MENSAJE: 'Le hemos enviado un SMS al teléfono {{telefono}} con un código de confirmación. Introdúzcalo a continuación.',
				CODIGO: 'Código de confirmación',
				CODIGO_ERROR: 'El código introducido no es válido',
				REENVIAR: 'Reenviar código',
				REENVIADO: 'Mensaje reenviado'
			},
			CERTIFICADOS: 'Información adicional - Certificados y punt. calidad',
			PAGO: 'Introduce los datos bancarios',
			CONFIRMACION: 'Confirmación',
			PERSONAL: 'Información Personal',
			TARIFA: 'Tarifa',
			ALTA: 'Alta como',
			OFERTANTE: 'Ofertante',
			PROVEEDOR: 'Proveedor',
			SERVICIOS: 'de traslados y servicios funerarios',
			DATOS_PAGO: 'Datos de pago',
			CONFIRMAR: 'CONFIRMAR',
			CONFIRMAR_PAGAR: 'CONFIRMAR Y PAGAR',

			SWAL: {
				errorSMSSwal: 'Código SMS incorrecto',
				errorSMSSwal_TEXT: 'El código de validación de teléfono enviado por SMS es incorrecto. Revíselo e introdúzcalo de nuevo.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.'
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: 'Suscripción hasta',
			GUARDAR: 'GUARDAR INFORMACIÓN',
			DATOS: {
				TITULO: 'Mi perfil',
				TITULO2: 'Información del usuario',
				EMAIL: 'Email',
				EMPRESA: 'Empresa/Colectivo',
				EMPRESA2: 'Nombre de la empresa o colectivo',
				ACTIVIDAD: 'Actividad',
				LICENCIA: 'Nº Licencia',
				PAIS: 'País',
				COMUNIDAD: 'Comunidad autónoma/Estado',
				DIRECCION: 'Dirección',
				POBLACION: 'Población',
				CP: 'CP',
				MOVIL: 'Teléfono Movil',
				NOTIFICACIONES: 'Notificaciones',
				TELEFONO: 'Teléfono',
				WEB: 'Página Web',
			},
			SUSCRIPCION: {
				TITULO: 'Suscripción',
				NO_HAY: 'No hay ninguna suscripción activa',
				CAMBIO: 'Procesando cambio de método de pago...',
				TARIFA: 'Tarifa',
				FECHA_INICIO: 'Fecha Inicio',
				FECHA_FIN: 'Fecha Fin',
				PROX_RENOV: 'Próx.Renovación',
				FACTURACION: 'Facturación',
				PRECIO: 'Precio',
				LOCALIZACION: 'Localización de la tarifa',
				ESTADO: 'Estado tarifa',
				RENOV_AUTO: 'Renovación automática',
				RENOV_CANCEL: 'Renovación cancelada',
				PROX_CAMBIO: 'Próximo cambio en la tarifa',
				FECHA_EFECT: 'Fecha Efectiva',
				LOCALIZACION2: 'Localización',
				CANCELAR_CAMBIO: 'Cancelar cambio',
				CAMBIO_PROCESO: 'Procesando cancelación...',
				METODO_PAGO: 'Método de pago',
				EXPIRACION: 'Expiración',
				PAGO_CAMBIO: 'Cambiar método de pago',
				PAGO_LOADING: 'Cargando plataforma de pago...',
				BTN_LOCALIZACION: 'CAMBIAR LOCALIZACIÓN',
				BTN_TARIFA: 'CAMBIAR TARIFA',
				BTN_SUSC: 'CANCELAR SUSCRIPCIÓN',
				BTN_CANCEL: 'PROCESANDO CANCELACIÓN...'
			},
			METODO_PAGO: {
				TITULO: 'Método de pago',
				TITULAR: 'Titular',
				FACTURACION: 'Facturación',
				MENSUAL: 'Mensual',
				LICITA: 'Por licitación',
				CAMBIAR: 'Cambiar datos de pago',
				NOMBRE: 'Nombre Titular',
				IBAN: 'Número de cuenta (IBAN)'
			},
			PAGOS: {
				TITULO: 'Historial de pagos',
				TITULO2: 'Historial de pagos - facturas',
				IMPORTE: 'Importe',
				ESTADO: 'Estado',
				VER: 'Ver',
				DESCARGA: 'Descarga'
			},
			BLOQUEO: {
				TITULO: 'Lista de bloqueo',
				FECHA: 'Fecha Bloqueo'
			},
			CERTIFICACIONES: {
				TITULO: 'Mis certificaciones y puntuaciones',
				TITULO2: 'Certificaciones/Puntuaciones de calidad de la empresa',
				TITULO3: 'Añade una nueva certificación',
				TITULO5: 'Actualizar certificación',
				TITULO4: 'Certificaciones',
				NPS: 'Introduce las puntuaciones NPS',
				OTRO: 'Otro índice de medición de calidad (identificación)',
				OTRO1: 'Introduce la puntuación % de',
				OTRO2: 'Introduce la puntuación % de otro índice',
                NOMBRE: 'Nombre',
                ESTADO: 'Estado',
                SUBIDA: 'Fecha Subida',
                VALIDEZ: 'Fecha Validez',
				BORRAR: 'Borrar',
                BOTON: 'Añadir certificación',
				BOTON2: 'Actualizar certificación',
				CANCELAR: 'Cancelar',
                GUARDAR: 'Guardar certificación',
                PROCESO: 'Procesando certificación',
                ESTADO_CORRECTO: 'Correcto',
                ESTADO_REVISION: 'En revisión',
                ESTADO_ERROR: 'No válido',
                ESTADO_CADUCADO: 'Caducado',
				ACTUALIZAR: 'Actualizar',
				SUBIR_ERR: 'Formato Incorrecto',
				SUBIR_ERR_TEXT: 'La certificación debe subirse en formato PDF',
				SUBIR_ERR2: 'Archivo no seleccionado',
				SUBIR_ERR2_TEXT: 'Debes seleccionar o arrastrar un archivo en el cuadro de la zona superior',
				ARRASTRA: 'Arrastra o pulsa aquí para subir tu certificación (PDF)',
				SUBIR_OK: 'Certificación guardada',
				SUBIR_OK_TEXT: 'La certificación se ha guardado con éxito',
				BORRAR_PREG: 'Borrar certificación',
				BORRAR_PREG_TEXT: '¿Estás seguro de borrar esta certificación? No se podrá recuperar.',
				BORRAR_BOTON: 'Si, borrar',
				BORRAR_BOTON2: 'Cancelar',
				BORRAR_OK: 'Certificación borrada',
				BORRAR_OK_TEXT: 'La certificación se ha borrado con éxito.'
			},
			ALERTAS: {
				TITULO: 'Mi sistema de alertas',
				FRECUENCIA: 'Selecciona la frecuencia de recepción de notificaciones de nuevas licitaciones.',
				INMEDIATO: 'Inmediato',
				MINUTOS: 'Cada 15 minutos',
				NUNCA: 'Nunca',
				MODO: 'Ajusta el modo en el que serás notificado de los mensajes de la plataforma.',
				MODO_ERROR: 'Debes seleccionar al menos una opción',
				TELEGRAM_TITULO: 'Activación de notificaciones de Telegram',
				TELEGRAM1: 'Siga las siguientes instrucciones para activar las notificaciones en la aplicación Telegram:',
				TELEGRAM2: '1. Abre la aplicación <b>Telegram</b> en tu dispositivo movil o instálala desde la tienda de aplicaciónes (App Store - Apple o Google Play - Android)',
				TELEGRAM3: '2. Busca el usuario <b>WFuneralNetBot</b> en la barra de búsqueda de la zona superior. Alternativa: Si tienes Telegram instalado en tu dispositivo actual puedes acceder a este enlace <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" target="_blank">https://t.me/WFuneralNetBot</a> y abrir la aplicación.',
				TELEGRAM4: '3. Pulse sobre el botón <b>Iniciar</b> que le aparecerá al encontrar el usuario en la aplicación.',
				TELEGRAM5: '4. Introduzca a continuación el código de validación que le aparecerá en la conversación.',
				COD_DISPOSITIVO: 'Código dispositivo',
				COD_ERROR: 'El código introducido no es válido',
				NOTIFICACIONES: 'Personaliza las notificaciones que recibirás de la actividad de la plataforma'
			},
			CAMBIO: {
				AVISO: 'AVISO: El cambio de localización será efectivo en las próximas 48 horas.',
				BTN_CANCELAR: 'CANCELAR CAMBIO',
				BTN_LOC: 'CAMBIAR LOCALIZACIÓN',
				BTN_TARIFA: 'CAMBIAR TARIFA',
				BTN_PROCESO: 'PROCESANDO CAMBIO...',
				SELECCIONE_TARIFA: 'Seleccione la tarifa',
				PAGAR: 'Cantidad a pagar',
				AVISO2: 'AVISO: La nueva tarifa se aplicará inmediatamente.'
			},
			TODO_MUNDO: 'Todo el mundo',
			DISPOSITIVOS: {
				TITULO: 'Dispositivos registrados',
				NOMBRE: 'Nombre dispositivo',
				FECHA: 'Fecha registro'
			},
			SWAL: {
				confirmationSwal: 'Datos Guardados',
				confirmationSwal_TEXT: 'Los datos se han guardado correctamente',
				borrarSwal: 'Borrar Bloqueo',
				borrarSwal_TEXT: '¿Deseas borrar este bloqueo?',
				borrarSwal_BTN1: 'Si, borrar',
				borrarSwal_BTN2: 'Cancelar',
				borrarConfirmSwal: 'Bloqueo borrado correctamente',
				borrarConfirmSwal_TEXT: 'Se ha borrado el bloqueo correctamente.',
				cancelarSuscripSwal: 'Cancelar Suscripción',
				cancelarSuscripSwal_TEXT: '¿Estás seguro de cancelar tu suscripción? Podrás seguir utilizando los servicios de WFN hasta la fecha de finalización actual de tu tarifa.',
				cancelarSuscripSwal_BTN1: 'Si, cancelar',
				cancelarSuscripSwal_BTN2: 'Cancelar',
				cancelarSuscripOkSwal: 'Suscripción cancelada correctamente',
				cancelarSuscripOkSwal_TEXT: 'Se ha cancelado correctamente la suscripción. Podrás volver a activar la tarifa desde tu perfil.',
				tarifaCambiadaSwal: 'Cambio correcto de tarifa',
				tarifaCambiadaSwal_TEXT: 'La tarifa se ha cambiado correctamente',
				tarifaCambiadaSwal_TEXT2: 'Su nueva tarifa se aplicará inmediatamente.',
				errorSwal: 'Email incorrecto',
				errorSwal_TEXT: 'El email introducido ya se encuentra registrado en el sistema.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.',
				cambioLocSwal: 'Localización cambiada correctamente',
				cambioLocSwal_TEXT: 'Su nueva localización de tarifa será efectiva en 48 horas.',
				borrarCambioSwal: 'Cambio borrado correctamente',
				borrarCambioSwal_TEXT: 'Se ha cancelado el cambio seleccionado correctamente.',
				borrarDispSwal: 'Borrar Dispositivo',
				borrarDispSwal_TEXT: '¿Deseas borrar este dispositivo?',
				borrarDispSwal_BTN1: 'Si, borrar',
				borrarDispSwal_BTN2: 'Cancelar',
				borrarDispConfirmSwal: 'Dispositivo borrado correctamente',
				borrarDispConfirmSwal_TEXT: 'Se ha borrado el dispositivo correctamente.',
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Planes y precios para',
			TITULO_FUN2: 'operadores funerarios',
			SELECCIONA: 'Selecciona el plan que mejor se adapta a tu negocio de operador funerario. Siempre tendrás la flexibilidad para subir o bajar tu plan.',
			PAGO_ANUAL: 'PAGO ANUAL',
			AHORRO: 'AHORRO 10%',
			PAGO_MENSUAL: 'PAGO MENSUAL',
			POPULAR: 'MÁS POPULAR',
			PUJA_EN: 'Puja en',
			OFERTA_PARA: 'Oferta para',
			TARIFA1: 'todo el mundo',
			TARIFA2: 'todo el país',
			TARIFA3: '5 comunidades',
			TARIFA4: 'una comunidad',
			TARIFA1_MX: 'Mexico',
			TARIFA2_MX: 'Mexico, EEUU y Canadá',
			TARIFA3_MX: 'todo el mundo',
			MES: 'mes',
			AÑO: 'año',
			IVA: 'IVA',
			TAX: 'TAX',
			OFERTA: 'Oferta de lanzamiento',
			CONTRATAR: 'CONTRATAR',
			TEXTO1: 'Acceso a la información de las licitaciones que se producen en cualquier lugar del mundo.',
			TEXTO2: 'Acceso Premium a la revista funeraria mensual internacional Wfuneralnews.',
			TEXTO3: 'Soporte personalizado de nuestro equipo.',
			TEXTO4: 'Sistema de alertas en tu móvil o email, de las licitaciones, pujas u otra información relevante que consideres.',

			TITULO_ASEG1: 'Precios para',
			TITULO_ASEG2: 'aseguradoras, empresas y colectivos',
			TITULO_ASEG3: 'WFN Aseguradoras y colectivos',
			ASEG1: 'Licita en nuestra plataforma tus servicios o traslados funerarios.',
			ASEG2: 'Obtén el mejor precio para el servicio.',
			ASEG3: 'Acceso a nuestra base de datos de 60.000 funerarias en 194 países.',
			ASEG4: 'Segmentación por criterios de calidad y excelencia (ISO, UNE, NPS).',
			ASEG5: 'Ten información sobre la trazabilidad del servicio contratado.',
			ASEG6: 'Acceso a la información de las licitaciones que se producen en cualquier lugar del mundo.',
			ASEG7: 'Acceso Premium a la revista funeraria mensual internacional Wfuneralnews.',
			ASEG8: 'Soporte personalizado de nuestro equipo.',
			PAGANOS: 'Páganos a nosotros solo por lo contratado.',
			PRECIO: 'del precio al que se haya adjudicado la licitación final.',
			FUNERARIA: 'Pagas directamente a la funeraria el servicio contratado.'
		}
	}
};
