// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
// NGX DATATABLE
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AngularIbanModule } from 'angular-iban';
import '@stripe/stripe-js';
// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
	MatProgressSpinnerModule
} from '@angular/material';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { StartComponent } from './start/start.component';

import {RouterModule} from '@angular/router';

import { TooltipModule } from 'ng2-tooltip-directive';
import { SuscripcionComponent } from './suscripcion/suscripcion.component';
import { ValidarComponent } from './validar/validar.component';
import { ProcesoComponent } from './proceso/proceso.component';
import { ContratarComponent } from './contratar/contratar.component';
import { WfpComponent } from './wfp/wfp.component';
import { PreviewComponent } from './preview/preview.component';

@NgModule({
	declarations: [StartComponent, SuscripcionComponent, ValidarComponent, ProcesoComponent, ContratarComponent, WfpComponent, PreviewComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		PartialsModule,
		MatInputModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
		MatSelectModule,
		MatMenuModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatTabsModule,
		MatNativeDateModule,
		MatCardModule,
		MatRadioModule,
		MatIconModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		NgxDatatableModule,
		NgbModule,
		TranslateModule.forChild(),
		SweetAlert2Module,
		OwlDateTimeModule, 
		OwlNativeDateTimeModule,
		TooltipModule,
		RouterModule,
		NgxDropzoneModule,
		AngularIbanModule
	],
	providers: []
})
export class PagesModule {
}
