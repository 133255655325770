import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de notificaciones.
 */
@Injectable()
export class NotificacionService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `POST` para enviar una petición de ayuda
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    sendAyuda(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/ayuda`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar la creación de una licitación
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    nueva_licitacion(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/nueva_licitacion`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar la validación de una licitación
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    licitacion_validada(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/notificacion/licitacion_validada`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para notificar la no-adjudicación de una licitación
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
     no_adjudicados(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/notificacion/no_adjudicados`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para compartir la página del fallecido por SMS
     * @param info Contenido del mensaje
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    enviarSMS(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/sms/enviarSMS`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para compartir la página del fallecido por MAIL
     * @param info Contenido del mail
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    compartirPagina(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/mailcompartir`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para enviar el código de psicologo por MAIL
     * @param info Contenido del mail
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
     enviarPsicologo(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/mailpsicologo`, info, httpOptions);
    }
}