// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	//apiUrl: 'https://api.wfuneralnet.com',
	apiUrl: 'https://api-pruebas.wfuneralnet.com',
	//apiUrl: 'http://localhost:8081',
	//baseUrl: 'http://localhost:4200',
	baseUrl: 'https://clientes-demo.wfuneralpeople.com',
	stripe: "pk_test_51HjQ89HnipIYubl1q5pHND8qDEYXhknXyfAQAa0l2HRzgfFNxjuH5I48hB2n6wqekw57AgEsTYC1e7RpeMX8CvtE00kG3AFxqS"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
