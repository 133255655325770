// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
//import { AuthGuard } from './core/auth';
import { AuthGuard, StartGuard } from './_helpers';
//WFN Imports
import { StartComponent } from './views/pages/start/start.component';
import { SuscripcionComponent } from './views/pages/suscripcion/suscripcion.component';
import { ValidarComponent } from './views/pages/validar/validar.component';
import { ProcesoComponent } from './views/pages/proceso/proceso.component';
import { ContratarComponent } from './views/pages/contratar/contratar.component';
import { WfpComponent } from './views/pages/wfp/wfp.component';
import { PreviewComponent } from './views/pages/preview/preview.component';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [],
		children: [
			{ 
				path: 'start', 
				component: StartComponent
			},
			{ 
				path: 'validar', 
				component: ValidarComponent
			},
			{ 
				path: 'proceso/:licit', 
				component: ProcesoComponent
			},
			{ 
				path: 'contratar/:licit', 
				component: ContratarComponent
			},
			{ 
				path: 'home', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'home/:tab', 
				component: SuscripcionComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'preview/:code', 
				component: PreviewComponent,
				canActivate: [AuthGuard, StartGuard]
			},
			{ 
				path: 'wfp/:code', 
				component: WfpComponent
			},
			{ 
				path: 'wfp/:code/preview', 
				component: WfpComponent
			},
			{
				path: 'builder',
				loadChildren: () => import('./views/theme/content/builder/builder.module').then(m => m.BuilderModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'home', pathMatch: 'full'},
			{path: '**', redirectTo: 'home', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
