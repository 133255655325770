import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de estados/comunidades.
 */
@Injectable()
export class StateService {
    constructor(private http: HttpClient) { }
 
    /**
     * Realiza una petición `GET` para obtener el listado completo de estados/comunidades
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/states?populate=false&sort=name ASC`);
    }
 
    /**
     * Realiza una petición `GET` para obtener los estados de un país
     * @param grupo Identificador del país
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAllByCountry(country: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/states?countryId=${country}&populate=false&sort=name ASC`);
    }
 
    /**
     * Realiza una petición `GET` para obtener el estado por su ID
     * @param id Identificador del estado a obtener
     * @returns An `Observable` of the response body as a JSON object.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/states/${id}`);
    }
}