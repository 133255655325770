import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, LicitaService, UserService, NotificacionService, CertificadoService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';

declare function initDropdown(): any;
/**
 * Componente que contiene el proceso de registro de licitación particular.
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  /** Variables globales */
  @ViewChild('wizard', {static: true}) el: ElementRef;
  @ViewChild('errorSwal', {static: false}) private errorSwal: SwalComponent

  model: any = {};
  currentUser: any;

  tarifaPar = null;

  paises: any;
  paisesHash = {};
  paisesHashCode = {};
  estados: any;
  estadosHash = {};
  estadosAll: any;
  tarifas = [];
  tarifasHash = {};
  provinciasSel = [];
  provinciasSel_tanat = [];
  mostrarAdicionales = false;

  licitacion = null;
  
  loading = false;
  step = 1;

  wiz1Form: FormGroup;


	submittedWiz1 = false;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private notificacionService: NotificacionService,
    private ngZone: NgZone,
    private userService: UserService,
    private stateService: StateService,
		private translate: TranslateService,
    private licitaService: LicitaService,
    private countryService: CountryService,
    private certificadoService: CertificadoService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
	}

  /**
   * Método que se lanza en el inicio del componente y carga los formularios y los maestros necesarios.
   * Comprueba si hay login y si hay licitación abierta.
   */
	ngOnInit() {
    if(this.authenticationService.currentUserValue) {
      this.currentUser = this.authenticationService.currentUserValue;
      console.warn("CURRENT USER!!!", this.currentUser);

      //Comprobamos si tiene alguna licitación creada
      if(this.currentUser.status == "ACTIVO") {
        this.router.navigate(['/home']);
        return;
      }

      this.licitaService.getLicitacionesByUser(this.currentUser.id)
	    .subscribe(licitaciones => {
        console.warn(licitaciones);
        var encAbierta = null;
        var encPendiente = null;
        for(let licita of licitaciones) {
          if(licita.abierta) {
            encAbierta = licita;
            break;
          } else if(!licita.validada) {
            encPendiente = licita;
            break;
          } else {
            this.licitacion = licita;
          }
        }
        if(encAbierta) {  //Tiene una licitación abierta
          this.ngZone.run(() => this.router.navigate(['/proceso/' + encAbierta.referencia])).then();
        } else if(encPendiente) { //Tiene una licitación pendiente de validar
          this.ngZone.run(() => this.router.navigate(['/contratar/' + encPendiente.referencia])).then();
        } else { 
          //No se ha encontrado nada pendiente... Cargamos su información

          this.initWiz1FormEdit();
          this.loadProvinciasAll();

          this.paso3 = {nombre: this.currentUser.empresa, prefijo: this.currentUser.prefijo, telefono: this.currentUser.telefono, email: this.currentUser.email, politica: false};
          
        }
      });
    } else {
      this.initWiz1Form();
      this.loadProvinciasAll();
    }


  }

  /**
   * Carga del listado completo de comunidades
   */
  loadProvinciasAll(){
    console.log("loadProvinciasAll()");
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }

        this.loadPaises();
      });
  }

  /**
   * Carga del listado completo de países.
   * Localiza la IP del usuario para seleccionar su pais por defecto.
   */
  loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	  .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
        this.paisesHashCode[paises[i].iso2+""] = paises[i];
      }

      if(!this.licitacion) {
        /*this.http.get<any>(`https://freegeoip.app/json/`)
        .subscribe(data => {
          console.warn(data);
          if(this.paisesHashCode[data.country_code]) {
            this.wiz1Form.get('pais').setValue(this.paisesHashCode[data.country_code].id);
            this.wiz1Form.get('pais_tanat').setValue(this.paisesHashCode[data.country_code].id);
            this.paso3.prefijo = "+" + this.paisesHashCode[data.country_code].phonecode;
            this.onChangePais(null); this.onChangePais_tanat(null);
          }
        });*/
      } else {
        this.onChangePais(null); this.onChangePais_tanat(null);
      }

      
		});
	}

  /**
   * Evento de cambio de país para cargar sus comunidades
   * @param event Información del evento
   */
  onChangePais(event){
    this.provinciasSel = [];
    if(!this.licitacion) this.wiz1Form.get('provincia').setValue('');
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.controls["pais"].value) {
        this.provinciasSel.push(estado);
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Evento de cambio de país (tanatorio) para cargar sus comunidades
   * @param event Información del evento
   */
  onChangePais_tanat(event){
    this.provinciasSel_tanat = [];
    if(!this.licitacion) this.wiz1Form.get('provincia_tanat').setValue('');
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.wiz1Form.controls["pais_tanat"].value) {
        this.provinciasSel_tanat.push(estado);
      }
    }
    this.cdr.detectChanges();
  }
  
  /**
   * Inicializa el formulario inicial (nuevo usuario).
   */
  initWiz1Form() {
		this.wiz1Form = this.fb.group({
      pais: ['', Validators.compose([Validators.required])],
      provincia: ['', Validators.compose([Validators.required])],
      poblacion: ['', Validators.compose([Validators.required])],
      pais_tanat: ['', Validators.compose([Validators.required])],
      provincia_tanat: ['', Validators.compose([Validators.required])],
      poblacion_tanat: ['', Validators.compose([Validators.required])],
      tipo: ['', Validators.compose([Validators.required])],
      ceremonia: ['', Validators.compose([Validators.required])],
      multiple: [false],
      multipleNum: ['']
		});
  }

  /**
   * Inicializa el formulario inicial (usuario existente).
   */
   initWiz1FormEdit() {
		this.wiz1Form = this.fb.group({
      pais: [this.licitacion.paisOrigen.id, Validators.compose([Validators.required])],
      provincia: [this.licitacion.provinciaOrigen.id, Validators.compose([Validators.required])],
      poblacion: [this.licitacion.poblacionOrigen, Validators.compose([Validators.required])],
      pais_tanat: [this.licitacion.paisDestino.id, Validators.compose([Validators.required])],
      provincia_tanat: [this.licitacion.provinciaDestino.id, Validators.compose([Validators.required])],
      poblacion_tanat: [this.licitacion.poblacionDestino, Validators.compose([Validators.required])],
      tipo: [this.licitacion.servicios[0].tipo, Validators.compose([Validators.required])],
      ceremonia: [this.licitacion.servicios[0].cerem, Validators.compose([Validators.required])],
      multiple: [false],
      multipleNum: ['']
		});
  }

  /**
   * Hace scroll al elemento indicado
   * @param id Identificador html del elemento al que hacer scroll.
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }

	/**
	 * Checking control validation
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasErrorWiz1(controlName: string, validationType: string): boolean {
		const control = this.wiz1Form.controls[controlName];
		if (!control || !this.submittedWiz1) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
  }

  errorMultiple = false

  /**
   * Método para el cambio de página del wizard.
   * Comprueba en cada paso si todos los requisitos se cumplen.
   */
  siguiente() {
    if(this.step == 1) {
      this.submittedWiz1 = true;

      if (this.wiz1Form.invalid || (this.wiz1Form.value.multiple && !this.wiz1Form.value.multipleNum)) {
        console.log(this.wiz1Form);
        if(this.wiz1Form.value.multiple && !this.wiz1Form.value.multipleNum) this.errorMultiple = true
        else this.errorMultiple = false;
        const controls = this.wiz1Form.controls;
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.cdr.detectChanges();
        return;
      }
    } else if(this.step == 2) {
      if(!this.paso2.tarifa) return;
    } else if(this.step == 3) {
      var err = false;
      if(!this.paso3.nombre) {
        this.paso3_error.nombre = true;
        err = true;
      } else {
        this.paso3_error.nombre = false;
      }
      if(!this.paso3.prefijo) {
        this.paso3_error.prefijo = true;
        err = true;
      } else {
        this.paso3_error.prefijo = false;
      }
      if(!this.paso3.telefono) {
        this.paso3_error.telefono = true;
        err = true;
      } else {
        this.paso3_error.telefono = false;
      }
      if(!this.paso3.email) {
        this.paso3_error.email = true;
        err = true;
      } else {
        this.paso3_error.email = false;
      }
      if(!this.paso3.politica) {
        this.paso3_error.politica = true;
        err = true;
      } else {
        this.paso3_error.politica = false;
      }

      if(err) return;

      //Comprobamos si el email introducido es válido
      this.userService.checkWFN(this.paso3.email)
      .pipe(first())
      .subscribe(
        data => {
          this.guardarDatos();
        },
        error => {
          console.log(error);
          this.errorSwal.fire();
          this.step--;
          this.cdr.detectChanges();
      });


    }
    this.step++;
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 100);
    
  }

  /**
   * Vuelve al paso anterior del wizard
   */
  volver() {
    this.step--;
    this.cdr.detectChanges();
  }

  /**
   * Devuelve el background de la página en base al paso actual
   * @returns String css del fondo correspondiente.
   */
  getBackground() {
    if(this.step == 1) return 'url("/assets/media/bg/start.png")'
    else if(this.step == 2) return 'url("/assets/media/bg/start2.png")'
    else if(this.step == 3) return 'url("/assets/media/bg/start3.png")'
  }

  /* --------------
      PAGINA 2
  -------------- */

  /** Información correspondiente al paso 2 */
  paso2 = {tarifa: null, catering: '', coronas: '', otras: ''};

  /**
   * Selecciona la tarifa
   * @param tarifa Identificador de la tarifa escogida
   */
  seleccionarTarifa(tarifa: any) {
    this.paso2.tarifa = tarifa;
    this.cdr.detectChanges();
    this.scroll('adicionales');
  }

  /**
   * Visualiza las opciones adicionales de contratación
   */
  verAdicionales() {
    this.mostrarAdicionales = true;
    this.cdr.detectChanges();
  }

  /* --------------
      PAGINA 3
  -------------- */

  /** Información correspondiente al paso 3 */
  paso3 = {nombre: null, prefijo: '', telefono: null, email: null, politica: false};
  /** Información de errores del paso 3 */
  paso3_error = {nombre: false, prefijo: false, telefono: false, email: false, politica: false};

  /**
   * Guarda la información de la licitación antes de ir al componente de validar.
   * Valida el correo antes de continuar.
   */
  guardarDatos() {
    var form = this.wiz1Form.value;
    GlobalVariables.registro = {
      pais: form.pais,
      provincia: form.provincia,
      poblacion: form.poblacion,
      pais_tanat: form.pais_tanat,
      provincia_tanat: form.provincia_tanat,
      poblacion_tanat: form.poblacion_tanat,
      tipo: form.tipo,
      ceremonia: form.ceremonia,
      multiple: form.multipleNum,

      tarifa: this.paso2.tarifa,
      catering: this.paso2.catering,
      coronas: this.paso2.coronas,
      otras: this.paso2.otras,

      nombre: this.paso3.nombre,
      prefijo: this.paso3.prefijo,
      telefono: this.paso3.telefono,
      email: this.paso3.email,
      divisa: this.paisesHash[form.pais].currency
    }

    console.warn(GlobalVariables.registro);

    //Vamos al componente de validación (SMS)
    this.router.navigate(['/validar']);

    

  }

}
