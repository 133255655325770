// Italian
export const locale = {
	lang: 'it',
	data: {
		START: {	
			TITULO: 'Trova il servizio funebre che',
			TITULO2: 'soddisfa tutte le tue necessità',
			SUBTITULO: 'Ti mostreremo le agenzie funebri che offrono il servizio di cui hai bisogno con la massima qualità e al miglior prezzo',
			PASO1: 'PASSO 1',
			PASO2: 'PASSO 2',
			PASO3: 'PASSO 3',
			PASO1_NOMBRE: 'Ubicazione e cerimonia',
			PASO2_NOMBRE: 'Servizio',
			PASO3_NOMBRE: 'Inserisci i tuoi dati',
			SIGUIENTE: 'Successivo',
			FINALIZAR: 'Concludere',
			VOLVER: 'Indietro',
			STEP1: {
				UBIC_FALLEC: 'Ubicazione persona deceduta',
				UBIC_TANAT: 'Ubicazione del tanatorio',
				POBLACION: 'Popolazione',
				PROVINCIA: 'Comunità autonoma/Stato',
				PAIS: 'Paese',
				TIPOLOGIA: 'Tipologia di servizio',
				TIPOLOGIA_SELECT: 'Seleziona il servizio',
				CREMACION: 'Cremazione',
				INHUMACION: 'Inumazione',
				CEREMONIA: 'Cerimonia',
				CIVIL: 'Civile',
				RELIGIOSA: 'Religiosa',
				MULTIPLE: 'Decesso multiplo',
				MULTIPLE_SELECT: 'Seleziona il num. di persone'
			},
			STEP2: {
				SUBTITULO: 'Scegli uno dei nostri pacchetti di servizi.',
				SERV1: 'TRANQUILLITÀ',
				SERV2: 'SERENITÀ',
				SERV3: 'PREMIUM',
				SERV1_ITEM1: 'Cassa funebre rotonda',
				SERV1_ITEM2: 'Presentazione e condizionamento',
				SERV1_ITEM3: 'Personale funebre in uniforme',
				SERV1_ITEM4: 'Certificato medico',
				SERV1_ITEM5: 'Carro funebre di lusso con spostamento incluso',
				SERV1_ITEM6: 'Corona media',
				SERV1_ITEM7: 'Cremazione e urna',
				SERV2_ITEM1: 'Cassa funebre rotonda, semirotonda o ecologica egizia',
				SERV2_ITEM2: 'Presentazione e condizionamento',
				SERV2_ITEM3: 'Personale funebre in uniforme',
				SERV2_ITEM4: 'Certificato medico',
				SERV2_ITEM5: 'Carro funebre di lusso con spostamento incluso',
				SERV2_ITEM6: 'Corona grande',
				SERV2_ITEM7: 'Cremazione e urna premium',
				SERV2_ITEM8: 'Trasporto al tanatorio',
				SERV2_ITEM9: 'Sala tanatorio',
				SERV3_ITEM1: 'Cassa funebre rotonda, semirotonda o ecologica egizia',
				SERV3_ITEM2: 'Presentazione e condizionamento',
				SERV3_ITEM3: 'Personale funebre in uniforme',
				SERV3_ITEM4: 'Certificato medico',
				SERV3_ITEM5: 'Carro funebre di lusso con spostamento incluso',
				SERV3_ITEM6: 'Corona grande con testata',
				SERV3_ITEM7: 'Cremazione e urna di lusso',
				SERV3_ITEM8: 'Trasporto al tanatorio',
				SERV3_ITEM9: 'Sala tanatorio VIP',
				SERV3_ITEM10: 'Catering di lusso',
				SERV3_ITEM11: 'Accompagnamento musicale o simile',
				SELECCIONAR: 'SELEZIONARE',
				SELECCIONADO: 'SELEZIONATO',
				ADICIONALES: 'Se lo desideri, puoi aggiungere servizi aggiuntivi al pacchetto selezionato',
				CATERING: 'Catering',
				CATERING_SELECT: 'Seleziona il num. di persone per il catering',
				CATERING_NO: 'Senza catering',
				CORONAS: 'Corone',
				CORONAS_SELECT: 'Num. di corone',
				CORONAS_NO: 'Senza corone',
				OTRAS: 'Altre specifiche o informazioni per l\'agenzia funebre',
				VER_ADICIONALES: 'Mostra servizi aggiuntivi'
			},
			STEP3: {
				SUBTITULO: 'Inserisci i tuoi dati per mostrarti le agenzie funebri che possono fornire il servizio.',
				NOMBRE: 'Nome',
				PREFIJO: 'Paese (Prefisso)',
				TELEFONO: 'Telefono (cellulare)',
				EMAIL: 'Email',
				POLITICA: 'Ho letto e accetto la',
				POLITICA2: 'politica sulla privacy.',
				errorSwal: 'Email non corretta',
				errorSwal_TEXT: "L'email inserita è già registrata nel sistema.",
			},
			SWAL: {
				errorSMSSwal: 'Codice SMS non corretto',
				errorSMSSwal_TEXT: 'Il codice di convalida del telefono inserito è errato. Controllalo e inseriscilo nuovamente.',
			}
		},
		VALIDAR: {
			TITULO: 'Richiesta inviata correttamente',
			GRACIAS: 'Grazie per aver scelto il servizio di World Funeral People.',
			CODIGO: 'Per continuare, inserisci il codice di verifica inviato al tuo telefono',
			VALIDAR: 'Convalida codice',
			RECIBIDO: 'Non hai ricevuto il codice?',
			CODIGO_ERROR: 'Il codice inserito non è valido',
			REENVIAR: 'Reinvia codice',
			REENVIADO: 'Messaggio reinvato'
		},
		PROCESO: {
			TITULO: 'In meno di 5 minuti ti presenteremo quelle agenzie funebri che meglio rispondono alle tue esigenze in termini di qualità e prezzo.',
			AVISO: 'Ti avviseremo tramite SMS ed Email quando tutte le informazioni saranno disponibili.',
			DOCUMENTACION: 'Se lo desideri, in questi pochi minuti, puoi iniziare a leggere e/o preparare la documentazione che ti sarà necessaria per la gestione del servizio funebre.',
			DOC1: 'Fotocopia del documento d\'identità della persona deceduta.',
			DOC2: 'Fotocopia della persona che dichiara (chi si occupa del funerale).',
			DOC3: 'Certificato medico di decesso (se già emesso).',
			DOC4: 'Elenco dei beni del defunto (gioielli, abbigliamento o altri effetti personali che si desidera far constare).',
			DOC5: 'Metodo di pagamento: carta o numero di conto bancario.',
			DOC6: 'Ti informiamo anche che dovrai firmare l\'autorizzazione e il tipo di servizio all\'arrivo dell\'agenzia funebre scelta.'
		},
		CONTRATAR: {
			TITULO: 'Qui ti offriamo le <b class="g-font-weight-500"><i>opzioni funebri</i></b> che riteniamo meglio soddisfino le tue esigenze.',
			SUBTITULO: 'Clicca su assumi ora e ti metteremo in contatto con l\'agenzia funebre scelta affinché possa fornire il servizio il prima possibile',
			FUNERARIA: 'Dati dell\'agenzia funebre',
			CERTIFICADOS: 'Certificati di qualità ed eccellenza',
			CERT_NO: 'Nessun certificato',
			CONTRATAR: 'ASSUMERE ORA',
			NOT_FOUND: 'Ci dispiace, in questo momento non abbiamo trovato nessun servizio per la tua ricerca.',
			SOLICITAR: 'Richiedi di nuovo',
			LOADING: 'Caricamento informazioni...',
			PROCESANDO: 'Elaborazione dell\'assunzione...',
			SWAL: {
				contratarSwal: 'Assunzione di servizio',
				contratarSwal_TEXT: 'Confermi l\'assunzione per {{importe}} del servizio di {{funeraria}}?',
				contratarSwal_BTN: 'Assumere',
				contratarSwal_CANCELAR: 'Annulla',
			},
			SUCCESS: {
				TITULO: 'Grazie per aver assunto il servizio',
				CONTACTO: 'A breve l\'agenzia funebre ti contatterà per procedere con le pratiche.',
				CUENTA: 'Approfitta di World Funeral People e crea un account.',
				SERVICIOS: 'Potrai disporre di:',
				SERV1: 'Supporto psicologico al lutto gratuito. Potrai scegliere tra diverse specializzazioni.',
				SERV2: 'Legato digitale. Ci occupiamo di gestire le informazioni digitali della persona deceduta.',
				SERV3: 'Assistenza legale e gestione amministrativa. Sappiamo che queste situazioni richiedono molta burocrazia, a volte non semplice. Ti consigliamo e aiutiamo in tutto ciò di cui hai bisogno.',
				SERV4: 'Creazione di un necrologio digitale. Potrai creare una pagina per la persona deceduta, incorporando dati della cerimonia, ringraziamenti, foto e tutte le informazioni che necessiti.',
				SERV5: 'Servizio di finanziamento. Mettiamo a tua disposizione il servizio di finanziamento familiare.',
				SERV6: 'Servizi di cura. Se hai minori o anziani che necessitano di attenzione e cure in questi momenti, ti aiutiamo a trovare la persona giusta.',
				BOTON: 'CREA UN ACCOUNT'
			}
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Accedi',
				BUTTON: 'Accedi',
				BIENVENIDA1: 'Benvenuto nel processo di assunzione della tua tariffa WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Accedi per associare il tuo account a questa tariffa.',
				BIENVENIDA2: 'Benvenuto nel processo di assunzione di WFN',
				BIENVENIDA2_SUB: 'Accedi per continuare.',
				FORGOT_BUTTON: 'Hai dimenticato la password?',
				REGISTER: 'Non hai un account?',
				REGISTER_BUTTON: 'Registrati',
				VOLVER: 'Torna all\'accesso'
			},
			INPUT: {
				EMAIL: 'Email',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Conferma Password'
			},
			FORGOT: {
				TITLE: 'Recupera password',
				DESC: 'Inserisci di seguito il codice dell\'email ricevuta e la nuova password',
				SUCCESS: 'La tua password è stata recuperata correttamente. Accedi con le tue nuove credenziali per continuare.',
				INCORRECTO: 'Il codice di recupero inserito non è corretto.',
				INVALID: 'Questo link di recupero non è valido o è già stato utilizzato.',
				LOGIN: 'ACCEDI',
				CODIGO: 'Codice di recupero (4 cifre)',
				BUTTON: 'RECUPERA PASSWORD',
				EMAIL: 'Se l\'email inserita è registrata nel sistema, è stata inviata una mail di recupero. Segui le istruzioni per continuare.'
			},
			REGISTER: {
				TITLE: 'Registrati',
				BIENVENIDA1: 'Benvenuto nel processo di assunzione della tua tariffa WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Prima di tutto creeremo un account associato alla tua tariffa.',
				BIENVENIDA2: 'Benvenuto nel processo di assunzione di WFN',
				BIENVENIDA2_SUB: 'Prima di tutto creeremo un account.',
				EMAIL_CORP: 'Solo email aziendali (es. @la-tua-azienda.com) sono accettate',
				PAIS: 'Paese',
				PAIS_SELECT: 'Seleziona paese...',
				PERTENEZCO: 'Appartengo a',
				PERTENEZCO_SELECT: 'Seleziona settore...',
				SECTOR1: 'Imprese funebri',
				SECTOR2: 'Compagnie assicurative',
				SECTOR3: 'Fondi pensione',
				SECTOR4: 'Banche assicurative',
				SECTOR5: 'Compagnie di previdenza e prenecessità',
				SECTOR6: 'Ambasciate',
				SECTOR7: 'Collettivi con copertura funebre',
				SECTOR8: 'Altri',
				BUTTON: 'REGISTRAMI',
				LOGIN: 'Hai già un account?',
				LOGIN_BUTTON: 'Accedi',
				SUCCESS: 'Registrazione completata',
				SUCCESS_SUB: 'Ti diamo il benvenuto in World Funeral Net!',
				SUCCESS_MAIL: "Abbiamo inviato un'email di conferma all'indirizzo di registrazione. Per favore, inserisci il codice qui sotto o clicca sul link incluso nell'email per accedere a tutte le funzionalità della piattaforma.",
				SUCCESS_MAIL2: "Abbiamo inviato un'email di conferma all'indirizzo di registrazione. Per favore, inserisci il codice qui sotto o clicca sul link incluso nell'email per procedere con l'assunzione della tua tariffa.",
				ERROR_CODE: 'Il codice inserito non è corretto.',
				VALIDAR_BUTTON: 'VALIDA ACCOUNT',
				GRACIAS: 'Grazie mille per la tua fiducia.'
			},
			VALIDAR: { //NUOVO <--------------
				LOADING: 'Validando il tuo account, un momento per favore...',
				SUCCESS: 'Account validato',
				SUCCESS_DESC: 'Il tuo account è stato validato correttamente. Continueremo la registrazione in pochi secondi...',
				ERROR: 'Errore nella validazione',
				ERROR_DESC: 'Il tuo link di validazione non è corretto, controlla la tua email e usa l\'ultimo link generato.'
			},
			VALIDATION: {
				INVALID: '{{name}} non è valido',
				REQUIRED: '{{name}} deve essere inserito',
				MIN_LENGTH: 'La lunghezza minima di {{name}} è {{min}}',
				AGREEMENT_REQUIRED: 'È necessario accettare i termini e le condizioni',
				NOT_FOUND: 'Il {{name}} richiesto non è stato trovato',
				INVALID_LOGIN: 'I dati inseriti non sono corretti. Riprova.',
				INACTIVE: 'Il tuo account è inattivo. Controlla la tua email e clicca sul link di validazione.',
				REQUIRED_FIELD: 'Devi compilare questo campo',
				MIN_LENGTH_FIELD: 'La lunghezza minima di questo campo è:',
				MAX_LENGTH_FIELD: 'La lunghezza massima di questo campo è:',
				INVALID_FIELD: 'Questo campo non è valido',
				MATCH: 'La password e la sua conferma non corrispondono',
				INVALID_REGISTER: "L'email inserita è già registrata. Riprova o accedi."
			}
		},
		HOME: {
			BIENVENIDA: 'Ciao',
			SALIR: 'Logout',
			SERVICIOS: {
				TITULO: 'Servizi assunti',
				DETALLES: 'Dettagli del servizio',
				CATERING: 'Catering',
				CORONAS: 'Corone',
				OTRAS: 'Altre specifiche',
				FUNERARIA: 'Agenzia funebre',
				WEB: 'Sito web'
			},
			PAGINA: {
				TITULO: 'Crea pagina del defunto',
				TITULO_EDIT: 'Modifica pagina del defunto',
				DETALLE: 'Qui puoi creare la pagina della persona defunta per inserire le informazioni che desideri vengano viste da parenti e amici, come orari o dettagli del servizio e della cerimonia, foto, necrologi, ecc.',
				SIGUIENTE: 'Successivo',
				VOLVER: 'Indietro',
				PASO1: 'Dati del defunto',
				PASO2: 'Dati della cerimonia',
				PASO3: 'Ricorda il tuo caro',
				PASO4: 'Condividi',
				NOMBRE: 'Nome',
				APELLIDO1: 'Primo cognome',
				APELLIDO2: 'Secondo cognome',
				FECHA_NAC: 'Data di nascita',
				FECHA_FAC: 'Data di decesso',
				PASO2_DETALLE: 'Completa i dati della cerimonia per notificare parenti e amici',
				FECHA: 'Data',
				HORA: 'Ora',
				NOMBRE_FUN: 'Nome agenzia funebre, tanatorio o luogo di celebrazione',
				DIRECCION: 'Indirizzo, via e numero',
				CP: 'CAP',
				LOCALIDAD: 'Località',
				PROVINCIA: 'Comunità autonoma/Stato',
				PAIS: 'Paese',
				DESCRIPCION: 'Breve descrizione della cerimonia (messa, responso, commiato o altro)...',
				PASO3_DETALLE: 'Aggiungi tutto ciò che vorresti ricordare del tuo caro, dettagli, aneddoti, storie, oltre a immagini e video.',
				RECUERDO: 'Scrivi qui del tuo caro',
				IMAGEN_TITULO: 'Carica immagini della persona defunta',
				IMAGEN_PORTADA: 'Seleziona la foto di copertina della pagina',
				IMAGEN_SLIDER: 'Seleziona altre foto per la pagina (max 6 immagini).',
				ARRASTRA: 'Trascina o clicca qui per aggiungere un\'immagine',
				IMAGEN_SUBIR: 'Carica',
				IMAGEN_BORRAR: 'Elimina',
				SUBIR_ERR: 'Formato non corretto',
				SUBIR_ERR_TEXT: 'Il file deve essere in formato immagine',
				COMENTARIOS: 'Commenti di amici e parenti.',
				COMENTARIOS_CHECK: 'Vuoi abilitare l\'opzione per consentire ai tuoi amici e parenti di lasciare commenti sulla pagina?',
				PASO4_DETALLE: 'Condividi la pagina con i tuoi parenti e amici.',
				OPCION_TITULO: 'Vuoi abilitare l\'opzione che i tuoi amici e familiari possano condividere anche la pagina?',
				OPCION_CHECK: 'Abilita l\'opzione di condivisione',
				OPCIONES: 'Seleziona le opzioni con cui vuoi condividere la pagina',
				PREVISUALIZAR: 'Anteprima',
				LOADING: 'Preparando l\'anteprima della pagina...',

				TITULO_CREADA: 'Pagina del defunto',
				BORRAR: 'Cancella',
				borrarSwal: 'Cancella commento',
				borrarSwal_TEXT: 'Sei sicuro di voler eliminare questo commento? Non sarà possibile recuperarne il contenuto.',
				borrarSwal_BTN1: 'Sì, elimina',
				borrarSwal_BTN2: 'Annulla',
			},
			TRAZABILIDAD: {
				TITULO: 'Tracciabilità del servizio',
				NO: 'Nessuna traccia disponibile',
				ACTUALIZAR: 'Aggiorna'
			},
			PSICOLOGO: {
				TITULO: 'Psicologo gratuito per il lutto',
				DETALLE: 'Mettiamo a tua disposizione il numero di telefono per il supporto psicologico gratuito.',
				CODIGO: 'Identificati come cliente di WFP con il seguente codice:',
				CAMPOS: 'Per offrirti un\'assistenza in linea con le tue esigenze, completa i seguenti campi.',
				PERSONA: 'Chi è il caro estinto?',
				PERSONA_SEL: 'Seleziona la persona',
				PADRES: 'Padre / Madre',
				HIJOS: 'Figlio/a',
				HERMANO: 'Fratello/a',
				OTRO: 'Altro',
				MOTIVO: 'Qual è stata la causa del decesso?',
				MOTIVO_SEL: 'Seleziona la causa',
				LOADING: 'Inviando le informazioni del servizio...',
				ENVIAR: 'Invia informazioni',
				VER_CODIGO: 'Vedi codice',
				MOTIVO1: 'Decesso di non nati o bambini',
				MOTIVO2: 'Decesso improvviso per incidente',
				MOTIVO3: 'Decesso per lunga malattia',
				MOTIVO4: 'Decesso multiplo (coniugi, due o più membri di una famiglia)',
				MOTIVO5: 'Decesso giudiziario (suicidio, morte violenta, altri)',
				MOTIVO6: 'Altre cause'
			},
			LEGAL: {
				TITULO: 'Assistenza legale',
				DETALLE: 'Un team di professionisti ti assisterà e consiglierà su qualsiasi procedura o tracciamento.'
			},
			JURIDICA: {
				TITULO: 'Assistenza giuridica',
				DETALLE: 'Ti aiutiamo a risolvere tutte le procedure amministrative necessarie come domiciliazioni, pensioni, cancellazioni di contratti ecc.'
			},
			DIGITAL: {
				TITULO: 'Gestione della vita digitale',
				DETALLE: 'Gestiamo e facciamo la transizione per disattivare tutti gli account, le sottoscrizioni, i social network e i profili digitali della persona scomparsa.'
			},
			FINANCIA: {
				TITULO: 'Servizio di finanziamento',
				DETALLE: 'Mettiamo a tua disposizione il servizio di finanziamento familiare. L\'economia non deve essere un problema per te in questi momenti.'
			},
			DOMESTIC: {
				TITULO: 'Servizi domestici',
				DETALLE: 'Ti forniamo assistenza urgente per la cura degli anziani, dei bambini o di altre necessità ora o in futuro.'
			},
		},
		WFP: {
			CEREMONIA: 'Cerimonia',
			MEMORIA: 'In memoria di',
			FALLECIDO: 'Deceduto il {{fecha}} a {{lugar}}, all\'età di {{edad}} anni.',
			FECHA_UBIC: 'Data, ora e luogo',
			COMPARTE_WEB: 'Condividi la cerimonia di',
			ENVIAR_FLORES: 'Invia fiori a',
			PROVEEDORES: 'Ti offriamo fornitori con un eccellente rapporto qualità-prezzo',
			COMPRAR: 'Compra',
			MENSAJE: 'Cerimonia in memoria di',
			SMS: 'Inserisci fino a 6 numeri di cellulare per condividere la pagina via SMS',
			SMS_NAME: 'Telefono',
			EMAIL: 'Inserisci fino a 6 indirizzi email per condividere la pagina via Email',
			EMAIL_NAME: 'Email',
			ENVIAR: 'Condividi pagina',
			VOLVER: 'Torna al mio profilo',
			NO_DISPONIBLE: 'La pagina non è disponibile in questo momento.',
			COMENTARIOS: 'Commenti di parenti e amici',
			COMENTARIOS_TITULO: 'Pubblica un nuovo commento',
			COMENTARIOS_NOMBRE: 'Nome',
			COMENTARIOS_INPUT: 'Commento',
			COMENTARIOS_BOTON: 'Invia commento',
			COMENTARIOS_ENVIADO: 'Commento inviato correttamente'
		},
		PREVIEW: {
			PREVISUALIZACION: 'Anteprima della pagina della cerimonia del defunto',
			VER: 'Vedi in versione',
			EDITAR: 'Modifica pagina',
			FINALIZAR: 'Concludi e condividi',
			COMPARTE: 'Condividi la pagina di',
			COMPARTE2: 'con i tuoi contatti',
			COMPARTIR: 'Condividi tramite',
			ACCEDE: 'Accedi alla pagina o copia il suo link',
			CONFIRMACION: 'Pagina salvata correttamente',
			NO_PUBLICADA: 'Pagina non pubblicata',
			PREVISUALIZAR: 'Anteprima pagina e pubblica',
		},
























		




		MENU: {
			SEGUIMIENTO: 'monitoraggio<br>delle mie offerte',
			SEGUIMIENTO2: 'monitoraggio delle mie offerte',
			EJECUCION: 'in<br>esecuzione',
			EJECUCION2: 'in esecuzione',
			TODAS: 'vedi tutte<br>le offerte',
			TODAS2: 'vedi tutte le offerte',
			CREAR: 'crea<br>gara d\'appalto',
			CREAR2: 'crea gara d\'appalto',
			EXPLORAR: 'esplora le mie<br>gare d\'appalto',
			EXPLORAR2: 'esplora le mie gare d\'appalto',
			PENDIENTES: 'in attesa<br>di assegnazione',
			PENDIENTES2: 'in attesa di assegnazione',
			TODAS_LIC: 'vedi tutte<br>le gare d\'appalto',
			TODAS_LIC2: 'vedi tutte le gare d\'appalto',
			CONTRATAR: 'ASSUMERE',
			PERFIL: 'Il Mio Profilo',
			LOGOUT: 'Logout'
		},
		FOOTER: {
			CONTACTA: 'Contattaci',
			SEDES: 'Sedi a: Madrid, Città del Messico, Lisbona e Colonia.',
			AVISO_LEGAL: 'Nota legale',
			POLITICA: 'Politica sulla privacy'
		},
		DEMO: {
			LOADING: 'Preparando la piattaforma, un momento per favore...',
			BIENVENIDA: 'Benvenuto in WFN',
			SECTOR: 'Seleziona il settore di appartenenza per accedere alla piattaforma dimostrativa.',
			FUNERARIA: 'SONO UN OPERATORE FUNEBRE',
			ASEGURADORA: 'SONO UNA COMPAGNIA DI ASSICURAZIONI, UN\'IMPRESA O UN COLLETTIVO',
			HOME: {
				STEP1: 'Benvenuto in WFN! Questa è la piattaforma dimostrativa. Scopriremo le funzionalità della piattaforma e poi potrai interagire con essa e fare tutti i test che ritieni necessari.',
				STEP2: 'Questo è il menu principale. Da qui potrai accedere alle diverse pagine disponibili.',
				STEP3: 'Qui potrai monitorare le gare d\'appalto in cui hai partecipato.',
				STEP4: 'Qui vengono mostrate le gare d\'appalto che ti sono state assegnate. In esse potrai consultare le informazioni del vincitore e compilare la tracciabilità.',
				STEP5: 'Infine, qui viene mostrato l\'elenco completo delle gare d\'appalto disponibili. Andremo in questa pagina per vederle più nel dettaglio.',
				STEP6: 'Potrai offrire le tue gare d\'appalto in questo menu. Inserirai i loro dettagli e saranno pubblicati nell\'elenco. Potrai provarlo al termine di questa dimostrazione.',
				STEP7: 'Una volta create, troverai in questa pagina tutte le tue gare d\'appalto.',
				STEP8: 'Quando una gara d\'appalto sarà terminata, potrai trovare tutte quelle in attesa di assegnazione a un\'agenzia funebre in questa schermata.',
				STEP9: 'Qui vengono mostrate le gare d\'appalto che hai già assegnato a un\'agenzia funebre e si trovano in esecuzione. In esse potrai consultare le informazioni dell\'agenzia funebre e vedere la sua tracciabilità.',
				STEP10: 'Infine, qui viene mostrato l\'elenco completo delle gare d\'appalto disponibili. Andremo in questa pagina per vederle più nel dettaglio.',
			},
			LICIT: {
				STEP1: 'Benvenuto in WFN! Questa è la piattaforma dimostrativa. Scopriremo le funzionalità della piattaforma e poi potrai interagire con essa e fare tutti i test che ritieni necessari.',
				STEP2: 'Ogni "carta" che vedi è una gara d\'appalto messa in atto da una compagnia di assicurazioni o un collettivo. In essa troverai tutte le informazioni e i dettagli dei servizi, come la localizzazione, il tempo rimanente, l\'importo attuale, lo stato del servizio, ecc.',
				STEP3: 'Nella zona superiore troverai il riferimento della gara d\'appalto, insieme alla data di creazione. Allo stesso modo, potrai eliminarle in qualsiasi momento (i partecipanti saranno avvisati) e modificarle quando non ci sono ancora state partecipazioni. Se la gara d\'appalto risulta deserta potrai modificarla anche una volta terminata.',
				STEP4: 'Nella zona superiore troverai il riferimento assegnato dal sistema alla gara d\'appalto, insieme alla data di creazione.',
				STEP5: "Questi sono i servizi che devono essere forniti in una gara d\'appalto. Cliccando su di essi potrai vedere il loro dettaglio generale. I servizi con l'icona <b style='color: red'>+</b> indicano che il banditore ha incluso un dettaglio specifico",
				STEP6: 'Di seguito si indica la localizzazione in cui si svolgerà il servizio o l\'origine e la destinazione nei trasferimenti',
				STEP7: 'Questo è il tempo rimanente per la conclusione della gara d\'appalto e quindi il tempo per fare un\'offerta.',
				STEP8: 'In questo spazio osserverai lo stato attuale della gara d\'appalto. Questa può essere aperta, in attesa di assegnazione, in attesa di esecuzione, completata, chiusa o deserta.',
				STEP9: 'Infine troverai l\'importo attuale della gara d\'appalto e potrai accedere al dettaglio delle sue offerte o iscritti.',
				STEP10: 'Una volta assegnata una gara d\'appalto potrai vedere il progresso della tracciabilità e i dettagli dell\'agenzia funebre assegnataria',
				STEP11: 'Inoltre, nel seguente menu potrai vedere il dettaglio della tracciabilità che verrà compilata dall\'agenzia funebre.',
				STEP12: 'In questo spazio osserverai lo stato attuale della gara d\'appalto. Questa può essere aperta (vincendo/perdendo/iscritto), in trattativa, in esecuzione, completata o deserta.',
				STEP13: 'Infine troverai l\'importo attuale della gara d\'appalto e potrai fare un\'offerta o iscriverti alla stessa.',
				STEP14: 'Una volta che ti sarà stata assegnata una gara d\'appalto potrai vedere i dettagli dell\'azienda aggiudicatrice.',
				STEP15: 'Se la gara d\'appalto è in esecuzione potrai compilare le informazioni di tracciabilità del servizio.',
				STEP16: 'Potrai personalizzare la visualizzazione delle carte in qualsiasi momento con i diversi filtri disponibili.',
				STEP17: 'Questo è tutto. Ricorda che ti trovi sulla piattaforma dimostrativa. Potrai interagire con le gare d\'appalto disponibili, crearne di nuove e fare tutti i test che ritieni necessari.',
				STEP18: 'Questo è tutto. Ricorda che ti trovi sulla piattaforma dimostrativa. Potrai interagire con le gare d\'appalto disponibili, fare offerte o iscriverti a esse e fare tutti i test che ritieni necessari.',
			}
		},
		CONFIRMACION: {
			LOADING: 'Elaborazione dell\'assunzione in corso...',
			SUCCESS: 'Assunzione completata',
			SUCCESS_SUB: 'Sarai reindirizzato alla piattaforma in pochi secondi...',
			PENDING: 'Conferma dell\'account in sospeso',
			PENDING_SUB: 'Prima di continuare, il tuo account deve essere confermato dall\'organizzazione. L\'organizzazione si riserva il diritto di ammissione, valuteremo il tuo ingresso entro 24-48 ore e potrai completare il processo di assunzione.',
			PAGO: 'Accedendo alla conferma di pagamento...'
		},
		PAGO: {
			LOADING: 'Accesso alla piattaforma di pagamento sicuro in corso...',
			RESULT_LOADING: 'Caricamento dei dati dell\'abbonamento...',
			METODO: 'Selezione del metodo di pagamento',
			METODO_SUB: 'Seleziona il tuo metodo di pagamento per completare l\'attivazione del tuo account.',
			METODO_TARJETA: 'PAGAMENTO CON CARTA DI CREDITO',
			METODO_SEPA: 'PAGAMENTO CON ADDEBITO DIRETTO BANCARIO (SEPA)',
			SEPA_TITLE: 'Pagamento con addebito diretto bancario - SEPA',
			SEPA_NOMBRE: 'Nome del Titolare',
			SEPA_IBAN: 'Numero di conto (IBAN)',
			SEPA_CODIGO: 'Codice promozionale',
			SEPA_ERRORCOD: 'Il codice inserito non è corretto',
			SEPA_BUTTON: 'Iscriviti a WFuneralNet',
			SEPA_LOADING: 'Elaborazione dei dati in corso...',
			SEPA_LEGAL: 'Fornendo i tuoi dati di pagamento e confermando questo pagamento, autorizzi WFuneralNet e Stripe, il nostro fornitore di servizi di pagamento, a inviare istruzioni alla tua banca per addebitare l\'importo sul tuo conto e alla tua banca ad addebitare l\'importo sul tuo conto secondo tali istruzioni. Come parte dei tuoi diritti, hai diritto a un rimborso dalla tua banca secondo i termini e le condizioni del tuo accordo con la tua banca. I tuoi diritti sono spiegati in una dichiarazione che puoi ottenere dalla tua banca. Accetti di ricevere notifiche di futuri addebiti fino a 2 giorni prima che si verifichino.',
			SUCCESS: 'Il tuo abbonamento è stato attivato correttamente.',
			SUCCESS_SUB: 'Puoi iniziare a utilizzare la piattaforma con tutte le sue funzionalità. Clicca sul seguente pulsante per accedere all\'inizio.',
			SUCCESS_BUTTON: 'INIZIA A UTILIZZARE WORLD FUNERAL NET',
			CANCEL: 'Hai annullato il processo di abbonamento.',
			CANCEL_SUB: 'Se si è verificato un errore o desideri riprovare, clicca sul seguente pulsante per accedere alla piattaforma di pagamento sicuro.',
			CANCEL_BUTTON: 'CONTINUA ALLA PIATTAFORMA DI PAGAMENTO SICURO'
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra a tua misura',
				INVITADO: 'I filtri sono disattivati per l\'account ospite.',
				CONTRATAR: 'Assumere',
				REFERENCIA: 'Riferimento',
				SERVICIO: 'Servizio',
				LOC_ORIGEN: 'Localizzazione origine',
				LOC_DESTINO: 'Localizzazione destinazione',
				TODOS: 'Tutti',
				PAIS: 'Paese',
				COMUNIDAD: 'Comunità autonoma',
				ESTADO: 'Stato',
				FECHA_DESDE: 'Data da',
				FECHA_HASTA: 'Data a',
				PRECIO_MIN: 'Prezzo min.',
				PRECIO_MAX: 'Prezzo max.',
				ESTADO_LIC: 'Stato gara d\'appalto',
				ESTADOS: {
					PENDIENTE_INI: 'In attesa di inizio',
					ABIERTA: 'Aperta',
					PENDIENTE_ADJ: 'In attesa di assegnazione',
					ADJ_TRAM: 'Assegnazione in trattativa',
					ADJ_PEN: 'Assegnata e in attesa',
					EJEC: 'In esecuzione',
					COMPLETADA: 'Completata',
					CERRADA: 'Chiusa',
					DESIERTA: 'Deserta'
				},
				REESTABLECER: 'Ripristina filtri'
			},
			ORDEN: {
				ESTADO: 'Ordina per stato della gara d\'appalto',
				ESTADO_ABIERTAS: 'Mostra prima le aperte',
				ESTADO_PENDIENTE: 'Mostra prima le in attesa',
				ESTADO_ADJUD: 'Mostra prima le assegnate',
				FECHA: 'Ordina per data di inizio',
				FECHA_ASC: 'Ascendente',
				FECHA_DESC: 'Discendente',
				TIEMPO: 'Ordina per tempo fino alla conclusione',
				TIEMPO_ASC: 'Ascendente',
				TIEMPO_DESC: 'Discendente'
			},
			LOADING: 'Caricamento delle gare d\'appalto...',
			NOHAY: 'Non ci sono gare d\'appalto',
			//NUEVO2 --------------
			CARD: {
				ORIGEN: 'Origine',
				DESTINO: 'Destinazione',
				TRASLADO: 'Trasferimento',
				LOCALIZ: 'Localizzazione',
				PUJA: 'offerta',
				OFERTA: 'offerta',
				PUJAR: 'fare un\'offerta',
				OFERTAR: 'fare un\'offerta',
				LA: 'La',
				TIME: 'Tempo rimanente per',
				COMIENZA: 'inizia in',
				VER_ASEG: 'vedi assicurazione',
				VER_FUN: 'vedi agenzia funebre',
				VER_DETALLES: 'vedi dettagli',
				ESTADOS: {
					PENDIENTE_INI: 'In attesa di inizio',
					ABIERTA: 'Aperta',
					INSCRITO: 'Iscritto',
					GANANDO: 'Vincendo',
					PERDIENDO: 'Perdendo',
					CONTU: 'con la tua',
					PUJA: 'offerta',
					OFERTA: 'offerta',
					DE: 'di',
					PENDIENTE_ADJ: 'In attesa',
					PENDIENTE_ADJ2: 'di assegnazione',
					CERRADA: 'Chiusa',
					ADJ_TRAM: 'Assegnazione in trattativa',
					ADJ_PEN: 'Assegnata e',
					ADJ_PEN2: 'in attesa di esecuzione',
					EJEC: 'In esecuzione',
					COMPLETADA: 'Completata',
					NO_ADJ: 'Non assegnata',
					DESIERTA: 'Deserta'
				},
				PUJA_SALIDA: 'Offerta di partenza',
				OFERTA_SALIDA: 'Offerta di partenza',
				PUJA_ACTUAL: 'Offerta attuale',
				OFERTA_ACTUAL: 'Offerta attuale',
				PUJA_GANADORA: 'Offerta vincente',
				OFERTA_GANADORA: 'Offerta vincente',
				PUJA_DESIERTA: 'Offerta deserta',
				OFERTA_DESIERTA: 'Offerta deserta',
				PUJA_ADJUDIC: 'Offerta assegnata',
				OFERTA_ADJUDIC: 'Offerta assegnata',
				PRECIO_FIJO: 'Prezzo fisso',
				VER_PUJAS: 'VEDI OFFERTE',
				VER_OFERTAS: 'VEDI OFFERTE',
				INSCRITOS: 'ISCRITTI',
				ADJUDICAR: 'ASSEGNARE',
				TRAZABILIDAD: 'TRACCIABILITÀ',
				INSCRÍBETE: 'ISCRIVITI',
				PUJAR2: 'FAI UN\'OFFERTA',
				OFERTAR2: 'FAI UN\'OFFERTA',
				VOLVER_PUJAR: 'FAI UN\'ALTRA OFFERTA',
				VOLVER_OFERTAR: 'FAI UN\'ALTRA OFFERTA',
				IVA_INC: 'IVA esclusa',
				HIST: {
					HISTORICO_PUJAS: 'Storico delle offerte',
					HISTORICO_OFERTAS: 'Storico delle offerte',
					HISTORICO_INSC: 'Storico degli iscritti',
					IMPORTE_SALIDA: 'Importo di partenza',
					ACTUALIZAR: 'aggiorna',
					TOTAL: 'Totale',
					FECHA: 'Data',
					USUARIO: 'Utente',
					IMPORTE: 'Importo',
					DESIERTA: 'Gara d\'appalto deserta',
					CREADOR: 'CREATORE',
				},
				CERRAR: 'chiudi',
				ASIG: {
					NOMBRE: 'Nome',
					LICENCIA: 'Licenza',
					DOMICILIO: 'Domicilio',
					TELEFONO: 'Telefono',
					EMAIL: 'Email',
					WEB: 'Sito Web',
					CERTIFICACIONES: 'Certificazioni',
					PUNTUACIONES: 'Punteggi',
					IMPORTE: 'Importo della gara d\'appalto',
					ADJUDICAR: 'ASSEGNARE',
					EXCLUIR: 'ESCLUDERE',
					EXCLUIR_TODAS: 'ESCLUDERE TUTTI',
					BLOQUEAR: 'BLOCCARE FORN.',
					VER_DETALLES: 'vedi dettagli',
					CERRAR_DETALLES: 'chiudi dettagli',
					DETALLE_ASIG1: 'L\'assicuratore si riserva il diritto di assegnazione valutando la qualità dei servizi offerti dall\'agenzia funebre.',
					DETALLE_ASIG2: 'È importante che completi il tuo profilo con punteggi e certificati di qualità.',
					DETALLE_ASIG_BTN: 'Completa profilo',
					REFERENCIA: 'Riferimento gara d\'appalto WFN',
					REFERENCIA2: 'Riferimento gara d\'appalto creatore',
					SIGUIENTES_PUJAS: 'Offerte successive',
					SIGUIENTES_OFERTAS: 'Offerte successive'
				},
				PUJAS: {
					PUJA_RAPIDA: 'Puja rápida',
					OFERTA_RAPIDA: 'Oferta rápida',
					PUJA_MANUAL: 'o introduce manualmente tu puja',
					OFERTA_MANUAL: 'o introduce manualmente tu oferta',
					PUJAR: 'PUJAR',
					OFERTAR: 'OFERTAR',
					AVISO: 'Aviso',
					PRORROGA3_PUJA: 'La licitación se extenderá en 3 minutos al pujar durante el minuto final',
					PRORROGA3_OFERTA: 'La licitación se extenderá en 3 minutos al ofertar durante el minuto final',
					PRORROGA1_PUJA: 'La licitación se extenderá en 1 minuto al pujar durante el minuto final',
					PRORROGA1_OFERTA: 'La licitación se extenderá en 1 minuto al ofertar durante el minuto final',
					PROCESO_PUJA: 'Su puja se está procesando. Un momento por favor...',
					PROCESO_OFERTA: 'Su oferta se está procesando. Un momento por favor...',
					HISTORICO_PUJAS: 'Ver histórico de pujas',
					HISTORICO_OFERTAS: 'Ver histórico de ofertas'
				},
				TRAZA: {
					INCIDENCIA: 'Incidencia en el servicio',
					VALIDAR: 'VALIDAR',
					CANCELAR: 'CANCELAR'
				},
				SERVICIO: {
					TIPO_TRASLADO: 'Tipo traslado',
					CARRETERA: 'Carretera punto a punto',
					AEROPUERTO: 'Aeropuerto',
					INCLUIDOS: 'Servicios Incluídos',
					TIPO_COMPLETO: 'Tipo Serv. Completo',
					CREMACION: 'Cremación + Urna',
					DESTFIN: 'Destino Final Temp.',
					DETALLE_ESP: 'Detalle específico del servicio',
					ESPEC_GEN: 'Especificaciones generales del servicio',
					TRASLADO1: 'El fallecimiento y el destino final (inhumación o incineración) se produce en localidades distintas.',
					TRASLADO2: 'Elementos y servicios habituales del traslado:',
					TRASLADO3: 'Arca de traslado : En aquellos casos en los que el Reglamento de Policía Sanitaria Mortuoria (RPSM) lo exija deberá estar provisto adicionalmente de un dispositivo de filtrado de aire u otro para equilibrar la presión interior y exterior.',
					TRASLADO4: 'Flete aéreo en su caso, Kms. realizados por carretera y gestiones y trámites específicos.'					
				}
			},
			SWAL: { 
				CANCELAR: 'Annulla',
				registerSwal: 'Assunzione Necessaria',
				registerSwal_TEXT: 'Questo contenuto non è disponibile con un account ospite. Assumi una tariffa per continuare.',
				registerSwal_BTN1: 'Assumi',
				suscriptionSwal: 'Gara d\'appalto non disponibile',
				suscriptionSwal_TEXT: 'Questo contenuto non è disponibile con la tua sottoscrizione. Controlla la tua tariffa per accedere.',
				suscriptionSwal_BTN1: 'Il Mio Profilo',
				confirmationSwal: 'Offerta Corretta',
				confirmationSwal_TEXT: 'La tua offerta è stata fatta correttamente',
				errorSwal: 'Importo Errato',
				errorSwal_TEXT: 'L\'importo deve essere inferiore all\'attuale importo della gara d\'appalto.',
				errorNegativeSwal_TEXT: 'L\'importo della gara d\'appalto non può essere negativo.',
				error2Swal_TEXT: 'L\'importo della gara d\'appalto è cambiato ed ora è inferiore alla tua proposta. Riprova con un altro importo.',
				confirmaSwal: 'Conferma',
				confirmaSwal_TEXT1: 'Confermi l\'offerta per {{importeLicitar}} {{divisa}}?',
				confirmaSwal_TEXT2: 'Confermi l\'iscrizione alla gara d\'appalto? Riceverai una notifica se sarai il prescelto al termine.',
				confirmaSwal_BTN1: 'Sì, confermo',
				confirmaElegidoSuccessSwal: 'Utente scelto correttamente.',
				confirmaElegidoSuccessSwal_TEXT: 'L\'aggiudicatario è stato assegnato correttamente. Qui di seguito puoi vedere i suoi dettagli.',
				confirmaElegidoSwal_TEXT1: 'Confermi {{empresa}} come aggiudicatario della gara d\'appalto?',
				confirmaSaltarSwal_TEXT1: 'Confermi di escludere {{empresa}} come aggiudicatario della gara d\'appalto e di rifiutarlo per le future gare d\'appalto? Non ci sono altre esclusioni, la gara d\'appalto sarà deserta. Questo partecipante non vedrà più le tue gare d\'appalto',
				confirmaSaltarSwal_TEXT2: 'Confermi di escludere {{empresa}} come aggiudicatario della gara d\'appalto e di rifiutarlo per le future gare d\'appalto? Questo partecipante non vedrà più le tue gare d\'appalto. Esclusione {{pasadas}}/2',
				confirmaSaltarSwal_TEXT3: 'Confermi di escludere {{empresa}} come aggiudicatario della gara d\'appalto? Non ci sono altre esclusioni, la gara d\'appalto sarà deserta.',
				confirmaSaltarSwal_TEXT4: 'Confermi di escludere {{empresa}} come aggiudicatario della gara d\'appalto? Non potrai tornare su questo utente. Esclusione {{pasadas}}/2',
				confirmaSaltarSwal_TEXT5: 'Confermi di escludere {{empresa}} come aggiudicatario della gara d\'appalto e di rifiutarlo per le future gare d\'appalto? Non ci sono altri partecipanti, la gara d\'appalto sarà deserta. Questo partecipante non vedrà più le tue gare d\'appalto',
				confirmaSaltarSwal_TEXT6: 'Confermi di escludere {{empresa}} come aggiudicatario della gara d\'appalto e di rifiutarlo per le future gare d\'appalto? Questo partecipante non vedrà più le tue gare d\'appalto.',
				confirmaSaltarSwal_TEXT7: 'Confermi di escludere tutti i partecipanti? La gara d\'appalto sarà deserta.',
				confirmaSaltarSuccessSwal1: 'Partecipante escluso correttamente.',
				confirmaSaltarSuccessSwal2: 'Partecipante bloccato correttamente.',
				confirmaSaltarSuccessSwal_TEXT1: 'Il partecipante è stato escluso. Ricorda che puoi escludere solo 2 volte per gara d\'appalto.',
				confirmaSaltarSuccessSwal_TEXT2: 'Il partecipante è stato escluso. La gara d\'appalto è ora deserta.',
				confirmaSaltarSuccessSwal_TEXT3: 'Il partecipante è stato escluso dalla gara d\'appalto.',
				confirmaSaltarSuccessSwal_TEXT4: 'Il partecipante è stato escluso e non vedrà più le tue gare d\'appalto. La gara d\'appalto è ora deserta.',
				confirmaSaltarSuccessSwal_TEXT5: 'Il partecipante è stato escluso e non vedrà più le tue gare d\'appalto.',
				confirmaTrazaSwal: 'Conferma della tracciabilità',
				confirmaTrazaSwal_TEXT: 'Vuoi confermare questa tracciabilità? Sarà notificato al banditore.',
				confirmaTrazaSwal_BTN1: 'Sì, confermo',
				errorTrazaSwal: 'Errore nella tracciabilità',
				errorTrazaSwal_TEXT: 'Devi inserire un commento sul servizio in caso di un\'incidenza.',
				confirmaTrazaSuccessSwal: 'Tracciabilità confermata con successo.',
				confirmaTrazaSuccessSwal_TEXT: 'La tracciabilità selezionata è stata confermata con successo.',
				cancelaTrazaSwal: 'Annullamento della tracciabilità',
				cancelaTrazaSwal_TEXT: 'Vuoi annullare questa tracciabilità? Tutte le informazioni verranno eliminate.',
				cancelaTrazaSwal_BTN1: 'Sì, confermo',
				cancelaTrazaSuccessSwal: 'Tracciabilità annullata con successo.',
				cancelaTrazaSuccessSwal_TEXT: 'La tracciabilità selezionata è stata annullata con successo.',
				borrarSwal: 'Elimina gara d\'appalto',
				borrarSwal_TEXT: 'Sei sicuro di voler eliminare questa gara d\'appalto? Non sarà possibile recuperarne il contenuto.',
				borrarSwal_BTN1: 'Sì, elimina',
				borrarConfirmSwal: 'Gara d\'appalto eliminata correttamente',
				borrarConfirmSwal_TEXT: 'La gara d\'appalto è stata eliminata correttamente.',
				conexionSwal: 'Limite di connessioni superato',
				conexionSwal_TEXT: 'Hai superato il numero di connessioni simultanee disponibili. Chiudi alcune delle sessioni attive per continuare.'
			},
			TUTORIAL: 'VEDI IL TUTORIAL DI NUOVO'
		},
		GLOBAL: {
			PAGO: 'Pagamento dell\'abbonamento pendente.',
			PAGO_SUB: 'Non ci hai ancora fornito i dati di pagamento per il tuo abbonamento. Clicca sul pulsante per accedere alla piattaforma di pagamento sicuro.',
			PAGO_BUTTON: 'PIATTAFORMA DI PAGAMENTO SICURO'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} non è valido',
				REQUIRED: '{{name}} è richiesto',
				MIN_LENGTH: 'La lunghezza minima di {{name}} è {{min}}',
				AGREEMENT_REQUIRED: 'È necessario accettare i termini e le condizioni',
				NOT_FOUND: '{{name}} richiesto non trovato',
				INVALID_LOGIN: 'I dati inseriti non sono corretti. Riprova.',
				REQUIRED_FIELD: 'Questo campo è obbligatorio',
				MIN_LENGTH_FIELD: 'La lunghezza minima di questo campo è di {{min}} caratteri',
				MAX_LENGTH_FIELD: 'La lunghezza massima di questo campo è di {{max}} caratteri',
				INVALID_FIELD: 'Questo campo non è valido',
				MATCH: 'La password e la sua conferma non corrispondono',
				INVALID_REGISTER: "L'email inserita è già registrata. Riprova o effettua l'accesso."
			}
		},
		CREAR: {
			TITULO: 'Crea gara d\'appalto',
			TITULO_EDIT: 'Modifica gara d\'appalto',
			REF: 'Riferimento cliente',
			REF2: '(non sarà mostrato pubblicamente)',
			REF_ERROR: 'Il riferimento è già registrato nel sistema.',
			TIPO: 'Tipo di gara d\'appalto',
			ESTANDAR: 'Gara d\'appalto standard.',
			ESTANDAR_DESC: 'Inserisci un importo di partenza. Le agenzie funebri faranno offerte al ribasso per aggiudicarsi il servizio o il trasferimento.',
			FIJO: 'Gara d\'appalto a prezzo fisso.',
			FIJO_DESC: 'Il servizio sarà assegnato al prezzo stabilito tra tutte le agenzie funebri che lo hanno richiesto.',
			FECHA_INI: 'Data/Ora di inizio',
			FECHA_FIN: 'Data/Ora di fine',
			FECHA_ERROR: 'La data di fine non può essere precedente alla data di inizio.',
			OPT_TRASLADO: 'Offri trasferimento',
			OPT_SERVICIO: 'Offri servizio',
			IMPORTE: 'Importo di partenza',
			IMPORTE_ERROR1: 'L\'importo del trasferimento non può essere negativo',
			IMPORTE_ERROR2: 'L\'importo del trasferimento deve essere inferiore a 100.000',
			DETALLE: 'Dettaglio specifico del servizio',
			ORIGEN: 'ORIGINE',
			PAIS: 'Paese',
			COMUNIDAD: 'Comunità autonoma',
			ESTADO: 'Stato',
			POBLACION: 'Popolazione',
			DESTINO: 'DESTINAZIONE',
			KMS: 'Chilometri alla destinazione',
			OPCIONAL: '(opzionale)',
			LOCALIZACION: 'LOCALIZZAZIONE',
			DESTINO_TRASLADO: '(Destinazione del trasferimento)',
			PENDIENTE_DESTINO: 'In attesa di selezionare la destinazione del trasferimento',
			SERVICIO_ERROR: 'È necessario selezionare almeno un servizio da offrire',
			IMPORTE_ERROR3: 'L\'importo del trasferimento non può essere negativo',
			IMPORTE_ERROR4: 'L\'importo del trasferimento deve essere inferiore a 100.000',
			INCLUIDO_EN: 'Incluso in',
			INCLUIDO_EN2: 'Servizio Completo',
			SELECT_OPCION: 'Seleziona un\'opzione',
			OPCION_ERROR: 'È necessario selezionare un\'opzione',
			OTROS_OPCION: 'Seleziona altri servizi da includere',
			CREACION: 'Formato di creazione',
			UNIDO: 'Stessa gara d\'appalto.',
			UNIDO_DESC: 'Il trasferimento e i servizi saranno offerti nella stessa gara d\'appalto. L\'agenzia funebre aggiudicataria sarà la stessa per entrambi.',
			SEPARADO: 'Gare d\'appalto separate.',
			SEPARADO_DESC: 'Il trasferimento e i servizi saranno offerti in gare d\'appalto separate. L\'agenzia funebre aggiudicataria può essere diversa per entrambi.',
			BOTON: 'AGGIUNGI GARA D\'APPALTO',
			BOTON_EDIT: 'MODIFICA GARA D\'APPALTO',
			SWAL: {
				confirmationAddSwal: 'Gara d\'Appalto Aggiunta',
				confirmationAddSwal_TEXT: 'I servizi selezionati sono stati aggiunti correttamente all\'elenco delle gare d\'appalto',
				confirmationEditSwal: 'Gara d\'Appalto Modificata',
				confirmationEditSwal_TEXT: 'La gara d\'appalto è stata modificata correttamente',
				errorLicitacionSwal: 'Gara d\'Appalto con Offerte',
				errorLicitacionSwal_TEXT: 'Questa gara d\'appalto ha già ricevuto offerte. Non è possibile apportare modifiche, puoi eliminarla e crearne una nuova.'
			}
		},
		START_old: {
			PASOS: {
				DATOS: 'Datos de contratante',
				VALIDACION: 'Validación de datos',
				ADICIONAL: 'Información adicional',
				PAGO: 'Datos de pago',
				CONFIRMACION: 'Confirmación',
				TARIFA: 'Selección de tarifa',
				TARIFA2: 'Información de tarifa'
			},
			TARIFA_LOC: 'Indique la localización de aplicación de tarifa',			
			CANALES: 'Quiero ser notificado a través de los siguientes canales: <i>(al menos uno)</i>',
			VOLVER: 'VOLVER',
			CONTINUAR: 'CONTINUAR',
			VALIDACION: 'Validación de datos',
			SMS: {
				TITULO: 'Validación de teléfono móvil',
				MENSAJE: 'Le hemos enviado un SMS al teléfono {{telefono}} con un código de confirmación. Introdúzcalo a continuación.',
				CODIGO: 'Código de confirmación',
				CODIGO_ERROR: 'El código introducido no es válido',
				REENVIAR: 'Reenviar código',
				REENVIADO: 'Mensaje reenviado'
			},
			CERTIFICADOS: 'Información adicional - Certificados y punt. calidad',
			PAGO: 'Introduce los datos bancarios',
			CONFIRMACION: 'Confirmación',
			PERSONAL: 'Información Personal',
			TARIFA: 'Tarifa',
			ALTA: 'Alta como',
			OFERTANTE: 'Ofertante',
			PROVEEDOR: 'Proveedor',
			SERVICIOS: 'de traslados y servicios funerarios',
			DATOS_PAGO: 'Datos de pago',
			CONFIRMAR: 'CONFIRMAR',
			CONFIRMAR_PAGAR: 'CONFIRMAR Y PAGAR',

			SWAL: {
				errorSMSSwal: 'Código SMS incorrecto',
				errorSMSSwal_TEXT: 'El código de validación de teléfono enviado por SMS es incorrecto. Revíselo e introdúzcalo de nuevo.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.'
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: 'Abbonamento fino a',
			GUARDAR: 'SALVA INFORMAZIONI',
			DATOS: {
				TITULO: 'Il Mio Profilo',
				TITULO2: 'Informazioni Utente',
				EMAIL: 'Email',
				EMPRESA: 'Azienda/Collettivo',
				EMPRESA2: 'Nome dell\'azienda o del collettivo',
				ACTIVIDAD: 'Attività',
				LICENCIA: 'N° Licenza',
				PAIS: 'Paese',
				COMUNIDAD: 'Comunità Autonoma/Stato',
				DIRECCION: 'Indirizzo',
				POBLACION: 'Popolazione',
				CP: 'CAP',
				MOVIL: 'Telefono Cellulare',
				NOTIFICACIONES: 'Notifiche',
				TELEFONO: 'Telefono',
				WEB: 'Sito Web',
			},
			SUSCRIPCION: {
				TITULO: 'Abbonamento',
				NO_HAY: 'Non c\'è nessun abbonamento attivo',
				CAMBIO: 'Elaborazione del cambio di metodo di pagamento in corso...',
				TARIFA: 'Tariffa',
				FECHA_INICIO: 'Data Inizio',
				FECHA_FIN: 'Data Fine',
				PROX_RENOV: 'Prossimo Rinnovo',
				FACTURACION: 'Fatturazione',
				PRECIO: 'Prezzo',
				LOCALIZACION: 'Localizzazione della tariffa',
				ESTADO: 'Stato della tariffa',
				RENOV_AUTO: 'Rinnovo automatico',
				RENOV_CANCEL: 'Rinnovo cancellato',
				PROX_CAMBIO: 'Prossimo cambio nella tariffa',
				FECHA_EFECT: 'Data Effettiva',
				LOCALIZACION2: 'Localizzazione',
				CANCELAR_CAMBIO: 'Annulla cambio',
				CAMBIO_PROCESO: 'Elaborazione dell\'annullamento in corso...',
				METODO_PAGO: 'Metodo di pagamento',
				EXPIRACION: 'Scadenza',
				PAGO_CAMBIO: 'Cambia metodo di pagamento',
				PAGO_LOADING: 'Caricamento della piattaforma di pagamento in corso...',
				BTN_LOCALIZACION: 'CAMBIA LOCALIZZAZIONE',
				BTN_TARIFA: 'CAMBIA TARIFFA',
				BTN_SUSC: 'CANCELLA ABBONAMENTO',
				BTN_CANCEL: 'ELABORAZIONE DELLA CANCELLAZIONE...'
			},
			METODO_PAGO: {
				TITULO: 'Metodo di pagamento',
				TITULAR: 'Titolare',
				FACTURACION: 'Fatturazione',
				MENSUAL: 'Mensile',
				LICITA: 'Per gara d\'appalto',
				CAMBIAR: 'Cambia dati di pagamento',
				NOMBRE: 'Nome Titolare',
				IBAN: 'Numero di conto (IBAN)'
			},
			PAGOS: {
				TITULO: 'Storico dei pagamenti',
				TITULO2: 'Storico dei pagamenti - fatture',
				IMPORTE: 'Importo',
				ESTADO: 'Stato',
				VER: 'Visualizza',
				DESCARGA: 'Scarica'
			},
			BLOQUEO: {
				TITULO: 'Lista di blocco',
				FECHA: 'Data del blocco'
			},
			CERTIFICACIONES: {
				TITULO: 'Le mie certificazioni e valutazioni',
				TITULO2: 'Certificazioni/Valutazioni di qualità dell\'azienda',
				TITULO3: 'Aggiungi una nuova certificazione',
				TITULO5: 'Aggiorna certificazione',
				TITULO4: 'Certificazioni',
				NPS: 'Inserisci i punteggi NPS',
				OTRO: 'Altro indice di misurazione della qualità (identificazione)',
				OTRO1: 'Inserisci il punteggio % di',
				OTRO2: 'Inserisci il punteggio % di un altro indice',
                NOMBRE: 'Nome',
                ESTADO: 'Stato',
                SUBIDA: 'Data di caricamento',
                VALIDEZ: 'Data di validità',
				BORRAR: 'Cancella',
                BOTON: 'Aggiungi certificazione',
				BOTON2: 'Aggiorna certificazione',
				CANCELAR: 'Annulla',
                GUARDAR: 'Salva certificazione',
                PROCESO: 'Elaborazione della certificazione in corso',
                ESTADO_CORRECTO: 'Corretto',
                ESTADO_REVISION: 'In revisione',
                ESTADO_ERROR: 'Non valido',
                ESTADO_CADUCADO: 'Scaduto',
				ACTUALIZAR: 'Aggiorna',
				SUBIR_ERR: 'Formato non corretto',
				SUBIR_ERR_TEXT: 'La certificazione deve essere caricata in formato PDF',
				SUBIR_ERR2: 'File non selezionato',
				SUBIR_ERR2_TEXT: 'Devi selezionare o trascinare un file nell\'area sopra',
				ARRASTRA: 'Trascina o clicca qui per caricare la tua certificazione (PDF)',
				SUBIR_OK: 'Certificazione salvata',
				SUBIR_OK_TEXT: 'La certificazione è stata salvata con successo',
				BORRAR_PREG: 'Cancella certificazione',
				BORRAR_PREG_TEXT: 'Sei sicuro di voler cancellare questa certificazione? Non sarà possibile recuperarla.',
				BORRAR_BOTON: 'Sì, cancella',
				BORRAR_BOTON2: 'Annulla',
				BORRAR_OK: 'Certificazione cancellata',
				BORRAR_OK_TEXT: 'La certificazione è stata cancellata con successo.'
			},
			ALERTAS: {
				TITULO: 'Il mio sistema di allerte',
				FRECUENCIA: 'Seleziona la frequenza di ricezione delle notifiche di nuove gare d\'appalto.',
				INMEDIATO: 'Immediato',
				MINUTOS: 'Ogni 15 minuti',
				NUNCA: 'Mai',
				MODO: 'Ajusta il modo in cui sarai notificato dei messaggi della piattaforma.',
				MODO_ERROR: 'Devi selezionare almeno un\'opzione',
				TELEGRAM_TITULO: 'Attivazione delle notifiche di Telegram',
				TELEGRAM1: 'Segui le istruzioni seguenti per attivare le notifiche sull\'applicazione Telegram:',
				TELEGRAM2: '1. Apri l\'applicazione <b>Telegram</b> sul tuo dispositivo mobile o installala dall\'app store (App Store - Apple o Google Play - Android)',
				TELEGRAM3: '2. Cerca l\'utente <b>WFuneralNetBot</b> nella barra di ricerca nella parte superiore. In alternativa: se hai Telegram installato sul tuo dispositivo attuale, puoi accedere a questo link <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" target="_blank">https://t.me/WFuneralNetBot</a> eaprire l\'applicazione.',
				TELEGRAM4: '3. Premi sul pulsante <b>Avvia</b> che apparirà dopo aver trovato l\'utente nell\'applicazione.',
				TELEGRAM5: '4. Inserisci di seguito il codice di verifica che apparirà nella conversazione.',
				COD_DISPOSITIVO: 'Codice dispositivo',
				COD_ERROR: 'Il codice inserito non è valido',
				NOTIFICACIONES: 'Personalizza le notifiche che riceverai in base all\'attività della piattaforma'
			},
			CAMBIO: {
				AVISO: 'ATTENZIONE: Il cambio di localizzazione avrà effetto nelle prossime 48 ore.',
				BTN_CANCELAR: 'ANNULLA CAMBIO',
				BTN_LOC: 'CAMBIA LOCALIZZAZIONE',
				BTN_TARIFA: 'CAMBIA TARIFFA',
				BTN_PROCESO: 'ELABORAZIONE DEL CAMBIO IN CORSO...',
				SELECCIONE_TARIFA: 'Seleziona la tariffa',
				PAGAR: 'Importo da pagare',
				AVISO2: 'ATTENZIONE: La nuova tariffa sarà applicata immediatamente.'
			},
			TODO_MUNDO: 'Tutto il mondo',
			DISPOSITIVOS: {
				TITULO: 'Dispositivi registrati',
				NOMBRE: 'Nome dispositivo',
				FECHA: 'Data di registrazione'
			},
			SWAL: {
				confirmationSwal: 'Dati Salvati',
				confirmationSwal_TEXT: 'I dati sono stati salvati correttamente',
				borrarSwal: 'Elimina Blocco',
				borrarSwal_TEXT: 'Sei sicuro di voler eliminare questo blocco?',
				borrarSwal_BTN1: 'Sì, elimina',
				borrarSwal_BTN2: 'Annulla',
				borrarConfirmSwal: 'Blocco eliminato con successo',
				borrarConfirmSwal_TEXT: 'Il blocco è stato eliminato con successo.',
				cancelarSuscripSwal: 'Annulla Abbonamento',
				cancelarSuscripSwal_TEXT: 'Sei sicuro di voler annullare il tuo abbonamento? Continuerai ad avere accesso ai servizi di WFN fino alla data di fine del tuo abbonamento attuale.',
				cancelarSuscripSwal_BTN1: 'Sì, annulla',
				cancelarSuscripSwal_BTN2: 'Annulla',
				cancelarSuscripOkSwal: 'Abbonamento annullato con successo',
				cancelarSuscripOkSwal_TEXT: 'L\'abbonamento è stato annullato con successo. Potrai riattivare la tua tariffa dal tuo profilo.',
				tarifaCambiadaSwal: 'Cambio di Tariffa Effettuato con Successo',
				tarifaCambiadaSwal_TEXT: 'La tariffa è stata cambiata con successo',
				tarifaCambiadaSwal_TEXT2: 'La tua nuova tariffa sarà applicata immediatamente.',
				errorSwal: 'Email Errata',
				errorSwal_TEXT: 'L\'email inserita è già registrata nel sistema.',
				errorTelegramSwal: 'Codice Telegram Errato',
				errorTelegramSwal_TEXT: 'Il codice di verifica di Telegram inserito è errato. Controllalo e inseriscilo nuovamente.',
				cambioLocSwal: 'Cambio di Localizzazione Effettuato con Successo',
				cambioLocSwal_TEXT: 'La tua nuova localizzazione tariffaria sarà effettiva in 48 ore.',
				borrarCambioSwal: 'Cambio Annullato con Successo',
				borrarCambioSwal_TEXT: 'Il cambio selezionato è stato annullato con successo.',
				borrarDispSwal: 'Elimina Dispositivo',
				borrarDispSwal_TEXT: 'Sei sicuro di voler eliminare questo dispositivo?',
				borrarDispSwal_BTN1: 'Sì, elimina',
				borrarDispSwal_BTN2: 'Annulla',
				borrarDispConfirmSwal: 'Dispositivo eliminato con successo',
				borrarDispConfirmSwal_TEXT: 'Il dispositivo è stato eliminato con successo.',
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Piani e Prezzi per',
			TITULO_FUN2: 'operatori funebri',
			SELECCIONA: 'Seleziona il piano che si adatta meglio alla tua attività di operatore funebre. Avrai sempre la flessibilità di aumentare o ridurre il tuo piano.',
			PAGO_ANUAL: 'PAGAMENTO ANNUALE',
			AHORRO: 'RISPARMIO DEL 10%',
			PAGO_MENSUAL: 'PAGAMENTO MENSILE',
			POPULAR: 'IL PIÙ POPOLARE',
			PUJA_EN: 'Offerta in',
			OFERTA_PARA: 'Offerta per',
			TARIFA1: 'tutto il mondo',
			TARIFA2: 'tutto il paese',
			TARIFA3: '5 comunità autonome',
			TARIFA4: 'una comunità autonoma',
			TARIFA1_MX: 'Messico',
			TARIFA2_MX: 'Messico, USA e Canada',
			TARIFA3_MX: 'tutto il mondo',
			MES: 'mese',
			AÑO: 'anno',
			IVA: 'IVA',
			TAX: 'Tasse',
			OFERTA: 'Offerta di Lancio',
			CONTRATAR: 'ASSUMI',
			TEXTO1: 'Accesso alle informazioni sulle gare d\'appalto che si svolgono in qualsiasi parte del mondo.',
			TEXTO2: 'Accesso Premium alla rivista funebre internazionale mensile Wfuneralnews.',
			TEXTO3: 'Supporto personalizzato da parte del nostro team.',
			TEXTO4: 'Sistema di allerta sul tuo cellulare o email per le gare d\'appalto, le offerte o altre informazioni rilevanti che ritieni importanti.',

			TITULO_ASEG1: 'Prezzi per',
			TITULO_ASEG2: 'assicurazioni, imprese e collettivi',
			TITULO_ASEG3: 'WFN Assicurazioni e collettivi',
			ASEG1: 'Lancia le tue gare d’appalto sulla nostra piattaforma per servizi o trasporti funebri.',
			ASEG2: 'Ottieni il miglior prezzo per il servizio.',
			ASEG3: 'Accesso al nostro database di 60.000 agenzie funebri in 194 paesi.',
			ASEG4: 'Segmentazione per criteri di qualità ed eccellenza (ISO, UNE, NPS).',
			ASEG5: 'Informazioni sulla tracciabilità del servizio contrattato.',
			ASEG6: 'Accesso alle informazioni sulle gare d\'appalto che si svolgono in qualsiasi parte del mondo.',
			ASEG7: 'Accesso Premium alla rivista funebre internazionale mensile Wfuneralnews.',
			ASEG8: 'Supporto personalizzato da parte del nostro team.',
			PAGANOS: 'Pagaci solo per quello che contratti.',
			PRECIO: 'del prezzo a cui è stata aggiudicata la gara d’appalto finale.',
			FUNERARIA: 'Paghi direttamente all’agenzia funebre il servizio contrattato.'
		}
	}
};
