import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de usuarios.
 */
@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `POST` para registrar un nuevo usuario
     * @param user Contenido del usuario
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    register(user: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/api/users/register`, user, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para comprobar si un email está utilizado
     * @param email Dirección de correo a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    check(email: any) {
        console.log("CHECK");
        return this.http.patch(`${environment.apiUrl}/api/users/check`, { email: email }, {observe: 'response'});
    }

    /**
     * Realiza una petición `GET` para obtener el usuario por su ID
     * @param id Identificador del usuario a obtener
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/user/${id}`);
    }

    /**
     * Realiza una petición `PATCH` para actualizar un usuario
     * @param user Contenido del usuario a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(user: any) {
        //console.log("UPDATE", `${environment.apiUrl}/api/users/${user.id}`, user);
        return this.http.patch(`${environment.apiUrl}/user/${user.id}`, user);
    }

    /**
     * Realiza una petición `GET` para obtener los bloqueos de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getBloqueosByUser(user: any) {
        return this.http.get<any>(`${environment.apiUrl}/user_bloqueo?user=${user}`);
    }

    /**
     * Realiza una petición `POST` para guardar un bloqueo
     * @param cert Contenido del bloqueo
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postBloqueo(bloqueo: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/user_bloqueo`, bloqueo, httpOptions);
    }

    /**
     * Realiza una petición `DELETE` para borrar un bloqueo
     * @param id Identificador del bloqueo a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteBloqueo(id: any) {
        return this.http.delete(`${environment.apiUrl}/user_bloqueo/${id}`);
    }

    /**
     * Realiza una petición `POST` para comprobar un código de telegram
     * @param info Información del código a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    checkTelegram(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/telegram/registro`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para confirmar la utilización de un código de Telegram
     * @param info Información del código a confirmar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    confirmacionTelegram(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/telegram/registro_confirmacion`, info, httpOptions);
    }

    /**
     * Realiza una petición `POST` para comprobar la utilización de un teléfono en Telegram
     * @param info Información del teléfono a comprobar
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    checkPhoneTelegram(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/telegram/checkPhone`, info, httpOptions);
    }

    confirmacionSMS(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/sms/confirmacionWFP`, info, httpOptions);
    }

    checkSMS(info: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/sms/check_code`, info, httpOptions);
    }

    recovery(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/recovery`, info, httpOptions);
    }

    recoveryCheck(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/recoveryCheck`, info, httpOptions);
    }

    validar(info: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/validar`, info, httpOptions);
    }

    checkMexico(cif: any) {
      return this.http.get<any>(`${environment.apiUrl}/user_registro?RFC=${cif}`);
    }

    getDispositivos(user: any) {
      return this.http.get<any>(`${environment.apiUrl}/dispositivo?user=${user}`);
    }

    deleteDispositivo(id: any) {
      return this.http.delete(`${environment.apiUrl}/dispositivo/${id}`);
    }

    registerWFP(user: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      return this.http.post(`${environment.apiUrl}/api/users/registerWFP`, user, httpOptions);
    }

    checkWFN(email: any) {
      return this.http.patch(`${environment.apiUrl}/api/users/checkWFN`, { email: email }, {observe: 'response'});
    }
}