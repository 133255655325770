export { AuthenticationService } from './authentication.service';
export { UserService } from './user.service';
export { CountryService } from './country.service';
export { StateService } from './state.service';
export { LicitaService } from './licita.service';
export { TrazabilidadService } from './trazabilidad.service';
export { NotificacionService } from './notificacion.service';
export { ServicioService } from './servicio.service';
export { CertificadoService } from './certificado.service';
export { WebService } from './web.service';
export { FunerariaService } from './funeraria.service';
export { Imagen_userService } from './imagen_user.service'; 
export { Wfp_servicioService } from './wfp_servicio.service';