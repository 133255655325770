import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class WebService {
    constructor(private http: HttpClient) { }
    
    /**
     * Realiza una petición `GET` para obtener una web desde su url
     * @param url URL de la página
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByUrl(url: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/web_fallecido?url=${url}`);
    }

    /**
     * Realiza una petición `GET` para obtener las webs de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByUsuario(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/web_fallecido?usuario=${user}`);
    }

    /**
     * Realiza una petición `GET` para obtener las imágenes de una web
     * @param web Identificador de la web
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getImagenes(web: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/web_fallecido_imagen?web=${web}`);
    }

    /**
     * Realiza una petición `GET` para obtener los comentarios de una web
     * @param web Identificador de la web
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getComentarios(web: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/web_fallecido_comentario?web=${web}`);
    }

    /**
     * Realiza una petición `POST` para publicar la web del fallecido
     * @param web Contenido de la web
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(web: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/web_fallecido`, web, httpOptions);
    }

    /**
     * Realiza una petición `POST` para guardar una imagen de una web
     * @param img Contenido de la imagen
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postImagen(img: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/web_fallecido_imagen`, img, httpOptions);
    }
    
    /**
     * Realiza una petición `POST` para guardar un comentario en la web
     * @param coment Contenido del comentario
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    postComentario(coment: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/web_fallecido_comentario`, coment, httpOptions);
    }

    /**
     * Realiza una petición `PATCH` para actualizar una web
     * @param suscrip Contenido de la web a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(web: any) {
        return this.http.patch(`${environment.apiUrl}/web_fallecido/${web.id}`, web);
    }

    /**
     * Realiza una petición `DELETE` para borrar una imagen
     * @param id Identificador de la imagen a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteImagen(id: number) {
        return this.http.delete(`${environment.apiUrl}/web_fallecido_imagen/${id}`);
    }

    /**
     * Realiza una petición `DELETE` para borrar un comentario
     * @param id Identificador del comentario a borrar
     * @returns An `Observable` of the response, with the response body of type `Object`
     */
    deleteComentario(id: number) {
        return this.http.delete(`${environment.apiUrl}/web_fallecido_comentario/${id}`);
    }
}