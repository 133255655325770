import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de trazas.
 */
@Injectable()
export class TrazabilidadService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el listado completo de trazas
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/trazabilidad`);
    }

    /**
     * Realiza una petición `GET` para obtener la traza por su ID
     * @param id Identificador de la traza a obtener
     * @returns An `Observable` of the response body as a JSON object.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/trazabilidad/${id}`);
    }

    /**
     * Realiza una petición `GET` para obtener las trazas de un grupo
     * @param grupo Identificador del grupo de trazas
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByGrupo(grupo: string) {
        return this.http.get<any[]>(`${environment.apiUrl}/trazabilidad?grupo=${grupo}`);
    }
}