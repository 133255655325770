import { Subscription, Subject } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import { locale as itLang } from './core/_config/i18n/it';

import { AuthenticationService } from './_services';

declare global {
    interface Window {
        io:any;
    }
}

/**
 * Componente principal de la aplicación.
 * Añade la autorización al socket.
 * @author Informática Integral Vasca
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'wfn';
	loader: boolean;	

	eventsSubject: Subject<void> = new Subject<void>();

	//socket: any;

	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
				         private router: Router,
				         private layoutConfigService: LayoutConfigService,
						 private splashScreenService: SplashScreenService,
						 private authenticationService: AuthenticationService) {

		// register translations
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang, itLang);
		//this.socket = self.io.sails.connect('http://localhost:1337');
		//console.log(this.socket);
		/**/

		
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// enable/disable loader
		this.loader = this.layoutConfigService.getConfig('loader.enabled');

		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				// hide splash screen
				this.splashScreenService.hide();

				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);

		

		let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
			
			self.io.sails.headers = {
				'Authorization': `Bearer ${currentUser.token}`
			};
			self.io.socket = self.io.sails.connect();

			const socket = self.io.socket;

			self.io.socket.on('connect', function onConnect(){
				setTimeout(() => { 
					if(!self.io.socket) {
						self.io.socket = socket;
					}
				}, 200);
			}.bind(this));
        }

		//var this1 = this;

		/*console.log(self);

		self.io.socket.get('/user', function (resData) {
			console.log(resData);
		});

		self.io.socket.on('user', function (msg) {
			console.log(msg);
		});*/
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
	}
}
