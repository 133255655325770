import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
  
/**
 * Contiene los métodos para la gestión de servicios.
 */
@Injectable()
export class ServicioService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener el listado completo de servicios
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
     getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/servicio`);
    }

    /**
     * Realiza una petición `GET` para obtener los servicios de un grupo
     * @param grupo Identificador del grupo
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAllGrupo(grupo: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/servicio?grupo=${grupo}`);
    }

    /**
     * Realiza una petición `GET` para obtener el servicio por su ID
     * @param id Identificador del servicio a obtener
     * @returns An `Observable` of the response body as a JSON object.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/servicio/${id}`);
    }
}