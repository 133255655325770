// Frances
export const locale = {
	lang: 'fr',
	data: {
		START: {
			TITULO: 'Trouvez les funérailles qui',
			TITULO2: 'Couvrez tous vos besoins',
			SUBTITULO: 'Nous vous montrerons le funéraire qui fournit le service dont vous avez besoin avec la plus haute qualité et au meilleur prix',
			PASO1: 'ÉTAPE 1',
			PASO2: 'ÉTAPE 2',
			PASO3: 'ÉTAPE 3',
			PASO1_NOMBRE: 'Emplacement et cérémonie',
			PASO2_NOMBRE: 'Service',
			PASO3_NOMBRE: 'Entrez vos données',
			SIGUIENTE: 'Suivant',
			FINALIZAR: 'Finition',
			VOLVER: 'Revenir',
			STEP1: {
				UBIC_FALLEC: 'Emplacement personne décédé',
				UBIC_TANAT: 'Emplacement du tanatorio',
				POBLACION: 'Ville',
				PROVINCIA: "Communauté / État autonome",
				PAIS: 'Pays',
				TIPOLOGIA: 'Typologie du service',
				TIPOLOGIA_SELECT: 'Sélectionnez le service',
				CREMACION: 'Incinération',
				INHUMACION: 'Inhumation',
				CEREMONIA: 'Cérémonie',
				CIVIL: 'Civil',
				RELIGIOSA: 'Religieux',
				MULTIPLE: 'Mort multiple',
				MULTIPLE_SELECT: 'Sélectionnez non. de personnes'
			},
			STEP2: {
				SUBTITULO: 'Choisissez l\'un de nos packs de services.',
				SERV1: 'TRANQUILLITÉ',
				SERV2: 'CALME',
				SERV3: 'PRIME',
				SERV1_ITEM1: 'Arche funéraire ronde',
				SERV1_ITEM2: 'Présentation et conditionnement',
				SERV1_ITEM3: 'Personnel funéraire en uniforme',
				SERV1_ITEM4: 'Certificat médical',
				SERV1_ITEM5: 'Voiture funéraire de luxe avec déplacement nécessaire inclus',
				SERV1_ITEM6: 'Couronne médiane',
				SERV1_ITEM7: 'Crémation et urne',
				SERV2_ITEM1: 'Arche funéraire ronde, semireda ou écologique égyptienne',
				SERV2_ITEM2: 'Présentation et conditionnement',
				SERV2_ITEM3: 'Personnel funéraire en uniforme',
				SERV2_ITEM4: 'Certificat médical',
				SERV2_ITEM5: 'Voiture funéraire de luxe avec déplacement nécessaire inclus',
				SERV2_ITEM6: 'Grande couronne',
				SERV2_ITEM7: 'Crémation et Urna Premium',
				SERV2_ITEM8: 'Transférer à tanatorio',
				SERV2_ITEM9: 'Salle tanatative',
				SERV3_ITEM1: 'Arche funéraire ronde, semireda ou écologique égyptienne',
				SERV3_ITEM2: 'Présentation et conditionnement',
				SERV3_ITEM3: 'Personnel funéraire en uniforme',
				SERV3_ITEM4: 'Certificat médical',
				SERV3_ITEM5: 'Voiture funéraire de luxe avec déplacement nécessaire inclus',
				SERV3_ITEM6: 'Grande couronne avec tête de lit',
				SERV3_ITEM7: 'Crémation et urne de luxe',
				SERV3_ITEM8: 'Transférer à tanatorio',
				SERV3_ITEM9: 'VIP Tank Room',
				SERV3_ITEM10: 'Catering de luxe',
				SERV3_ITEM11: 'Accompagnement musical ou similaire',
				SELECCIONAR: 'SÉLECTIONNER',
				SELECCIONADO: 'CHOISI',
				ADICIONALES: 'Si vous le souhaitez, vous pouvez ajouter des services supplémentaires au pack sélectionné',
				CATERING: 'Restauration',
				CATERING_SELECT: 'Sélectionnez non. de personnes pour la restauration',
				CATERING_NO: 'Sans restauration',
				CORONAS: 'Crowns',
				CORONAS_SELECT: 'Num. Des couronnes',
				CORONAS_NO: 'Sans couronnes',
				OTRAS: 'Autres spécifications ou informations pour le salon funéraire',
				VER_ADICIONALES: 'Afficher des services supplémentaires'
			},
			STEP3: {
				SUBTITULO: 'Entrez vos données afin que nous puissions vous montrer le funéraire que le service peut fournir.',
				NOMBRE: 'Nom',
				PREFIJO: 'Préfix du pays)',
				TELEFONO: 'Téléphone portable)',
				EMAIL: 'E-mail',
				POLITICA: 'J\'ai lu et accepte le',
				POLITICA2: 'Politique de confidentialité.',
				errorSwal: 'Mauvaise adresse mail',
				errorSwal_TEXT: 'L\'e-mail introduit est déjà enregistré dans le système.'
			},
			SWAL: {
				errorSMSSwal: 'Code SMS incorrect',
				errorSMSSwal_TEXT: 'Le code de validation téléphonique introduit est incorrect. Revisez-le et introduisez-le à nouveau.'
			}
		},
		VALIDAR: {
			TITULO: 'Application correctement envoyée',
			GRACIAS: 'Merci d\'avoir fait confiance au service des funérailles mondiales.',
			CODIGO: 'Pour continuer, entrez le code de vérification envoyé à votre téléphone',
			VALIDAR: 'Valider le code',
			RECIBIDO: 'Vous n\'avez pas reçu le code?',
			CODIGO_ERROR: 'Le code introduit n\'est pas valide',
			REENVIAR: 'Code transféré',
			REENVIADO: 'Message transféré'
		},
		PROCESO: {
			TITULO: 'En moins de 5 minutes, nous vous proposerons les funérailles qui répondent le mieux à vos besoins en qualité et en prix.',
			AVISO: 'Nous vous informerons par SMS et par e-mail lorsque toutes les informations seront disponibles.',
			DOCUMENTACION: 'Si vous le souhaitez, dans ces quelques minutes, vous pouvez lire et / ou préparer la documentation qui sera nécessaire pour le traitement du service funéraire.',
			DOC1: 'Photocopie Carte d\'identité de la personne décédée.',
			DOC2: 'Photocopie du déclarant (qui est en charge des funérailles).',
			DOC3: 'Certificat médical de décès (s\'il est déjà délivré).',
			DOC4: 'Ratio des effets personnels du défunt (bijoux, vêtements ou autres effets personnels qui veulent être enregistrés).',
			DOC5: 'Mode de paiement : numéro de carte ou de compte bancaire.',
			DOC6: 'Nous vous informez également que vous devrez signer l\'autorisation et le type de service à l\'arrivée du salon funéraire choisi.'
		},
		CONTRATAR: {
			TITULO: 'Ici, nous vous offrons le <b class = "g-font-weight-500"> <i> Options funéraires </i> </b> que nous considérons pour mieux répondre à vos besoins.',
			SUBTITULO: 'Cliquez sur louer maintenant et nous contacterons le salon funéraire afin de pouvoir fournir le service dès que possible',
			FUNERARIA: 'Données funéraires',
			CERTIFICADOS: 'Certificats de qualité et d\'excellence',
			CERT_NO: 'Il n\'y a pas de certificats',
			CONTRATAR: 'Embaucher maintenant',
			NOT_FOUND: 'Nous le pensons, pour le moment, nous n\'avons trouvé aucun service pour votre recherche.',
			SOLICITAR: 'Demander à nouveau',
			LOADING: 'Chargement des informations...',
			PROCESANDO: 'Traitement de l\'embauche...',
			SWAL: {
				contratarSwal: 'Embauche de service',
				contratarSwal_TEXT: 'Confirmez-vous l\'embauche par {{importe}} du service de {{funeraria}}?',
				contratarSwal_BTN: 'Embaucher',
				contratarSwal_CANCELAR: 'Annuler',
			},
			SUCCESS: {
				TITULO: 'Merci d\'avoir embauché le service',
				CONTACTO: 'Bientôt, le salon funéraire vous contactera pour poursuivre les procédures.',
				CUENTA: 'Benepy of World Funeral People et créer un compte.',
				SERVICIOS: 'Vous pouvez avoir:',
				SERV1: 'Attention psychologique au duel libre. Vous pouvez choisir entre différentes spécialités.',
				SERV2: 'Héritage numérique. Nous prenons soin de gérer les informations numériques de la personne décédée.',
				SERV3: 'Assistance juridique et gestion administrative. Nous savons que ces situations nécessitent beaucoup de bureaucratie, rien de simple parfois. Nous vous conseillons et vous aidons sur tout ce dont vous avez besoin.',
				SERV4: 'Création d\'un schéma numérique. Vous pouvez créer une page pour la personne décédée, incorporant des données de la cérémonie, merci, des photos et toutes les informations dont vous avez besoin.',
				SERV5: 'Service de financement. Nous mettons à votre disposition le service de financement familial.',
				SERV6: 'Services de soins. Si vous avez des mineurs ou des personnes âgées qui ont besoin d\'attention et de soins en ce moment, nous vous aidons à trouver la bonne personne.',
				BOTON: 'CRÉER UN COMPTE'
			}
		},
		AUTH: {
			LOGIN: {
				TITLE: 'Commencer la session',
				BUTTON: 'Commencer la session',
				BIENVENIDA1: 'Bienvenue dans le processus d\'embauche de votre taux WFN {{tarifa}} ',
				BIENVENIDA1_SUB: 'Connectez-vous pour associer votre compte à ce taux.',
				BIENVENIDA2: 'Bienvenue dans le processus d\'embauche WFN',
				BIENVENIDA2_SUB: 'Connectez-vous pour continuer.',
				FORGOT_BUTTON: 'Vous avez oublié votre mot de passe?',
				REGISTER: 'Vous n\'avez pas de compte?',
				REGISTER_BUTTON: 'S\'inscrire',
				VOLVER: 'Retour à la connexion'
			},
			INPUT: {
				EMAIL: 'E-mail',
				PASSWORD: 'Mot de passe',
				CONFIRM_PASSWORD: 'Confirmer mot de passe'
			},
			FORGOT: {
				TITLE: 'Récupérer le mot de passe',
				DESC: 'Ensuite, entrez le code de l\'e-mail reçu et le nouveau mot de passe',
				SUCCESS: 'Votre mot de passe a été correctement récupéré. Connectez-vous avec vos nouvelles informations d\'identification pour continuer.',
				INCORRECTO: 'Le code de récupération introduit n\'est pas correct.',
				INVALID: 'Ce lien de récupération n\'est pas valide ou a déjà été utilisé.',
				LOGIN: 'COMMENCER LA SESSION',
				CODIGO: 'Code de récupération (4 chiffres)',
				BUTTON: 'RÉCUPÉRER LE MOT DE PASSE',
				EMAIL: 'Un courrier de récupération a été envoyé à l\'e-mail introduit s\'il est enregistré dans le système. Suivez ses instructions pour continuer.'
			},
			REGISTER: {
				TITLE: 'S\'inscrire',
				BIENVENIDA1: 'Bienvenue dans le processus d\'embauche de votre taux WFN {{tarifa}}',
				BIENVENIDA1_SUB: 'Tout d\'abord, nous créerons un compte associé à votre taux.',
				BIENVENIDA2: 'Bienvenue dans le processus d\'embauche WFN',
				BIENVENIDA2_SUB: 'Tout d\'abord, nous créerons un compte.',
				EMAIL_CORP: 'Seulement les e-mails d\'entreprise (par exemple @votre-entreprise.com) sont acceptés',
				PAIS: 'Pays',
				PAIS_SELECT: 'Choisissez le pays...',
				PERTENEZCO: 'J\'appartiens à',
				PERTENEZCO_SELECT: 'Sélectionnez le secteur...',
				SECTOR1: 'Funérailles',
				SECTOR2: 'Les compagnies d\'assurance',
				SECTOR3: 'Les fonds de pension',
				SECTOR4: 'Banque sûre',
				SECTOR5: 'Sociétés de prévision et de pré-économie',
				SECTOR6: 'Ambassades',
				SECTOR7: 'Groupes avec couverture funéraire',
				SECTOR8: 'Autres',
				BUTTON: 'Registre',
				LOGIN: 'Avez-vous déjà un compte?',
				LOGIN_BUTTON: 'Se connecter',
				SUCCESS: 'Enregistrement correct',
				SUCCESS_SUB: 'Nous vous souhaitons la bienvenue au Net Funeral World!',
				SUCCESS_MAIL: 'Nous avons envoyé un e-mail de confirmation à l\'adresse d\'inscription. Veuillez saisir le code ou cliquez sur le lien inclus dans le courrier pour accéder à toutes les fonctionnalités de la plate-forme.',
				SUCCESS_MAIL2: 'Nous avons envoyé un e-mail de confirmation à l\'adresse d\'inscription. Veuillez saisir le code ou cliquez sur le lien inclus dans le courrier pour poursuivre votre tarif.',
				ERROR_CODE: 'Le code introduit n\'est pas correct.',
				VALIDAR_BUTTON: 'Valider le compte',
				GRACIAS: 'Merci beaucoup pour votre confiance.'
			},
			VALIDAR: { //NUEVO <--------------
				LOADING: 'Valider votre compte, un instant s\'il vous plaît...',
				SUCCESS: 'Compte validé',
				SUCCESS_DESC: 'Votre compte a été validé correctement. Nous continuerons le record en quelques secondes...',
				ERROR: 'Erreur de validation',
				ERROR_DESC: 'Votre lien de validation n\'est pas correct, vérifiez votre e-mail et utilisez le dernier lien généré.'
			},
			VALIDATION: {
				INVALID: '{{name}} n\'est pas valide',
				REQUIRED: '{{name}} doit être introduit',
				MIN_LENGTH: '{{name}} La longueur minimale est {{min}}',
				AGREEMENT_REQUIRED: 'Il faut accepter les termes et conditions',
				NOT_FOUND: 'Le {{name}} fissuré n\'est pas trouvé',
				INVALID_LOGIN: 'Les données entrées ne sont pas correctes. Essayer à nouveau.',
				INACTIVE: 'Votre compte est inactif. Vérifiez votre e-mail et cliquez sur le lien de validation.',
				REQUIRED_FIELD: 'Vous devez remplir ce champ',
				MIN_LENGTH_FIELD: 'La taille minimale de ce champ est:',
				MAX_LENGTH_FIELD: 'La taille maximale de ce champ est:',
				INVALID_FIELD: 'Ce champ n\'est pas valide',
				MATCH: 'Le mot de passe et la confirmation ne correspondent pas',
				INVALID_REGISTER: 'L\'e-mail introduit est déjà enregistré. Essayez à nouveau ou connectez-vous.'
			}
		},
		HOME: {
			BIENVENIDA: 'Salut',
			SALIR: 'Fermer la session',
			SERVICIOS: {
				TITULO: 'Services embauchés',
				DETALLES: 'Détails du service',
				CATERING: 'Restauration',
				CORONAS: 'Crowns',
				OTRAS: 'Autres spécifications',
				FUNERARIA: 'Mortuaire',
				WEB: 'Page Web'
			},
			PAGINA: {
				TITULO: 'Créer une page du défunt',
				TITULO_EDIT: 'Modifier la page du défunt',
				DETALLE: 'Ici, vous pouvez créer la page de la personne décédée pour mettre les informations que vous souhaitez que votre famille et vos amis voient, tels que les horaires ou les détails du service et de la cérémonie, des photos, des horaires, etc.',
				SIGUIENTE: 'Suivant',
				VOLVER: 'Revenir',
				PASO1: 'Données du défunt',
				PASO2: 'Données de cérémonie',
				PASO3: 'Souviens-toi de ton bien-aimé',
				PASO4: 'Partager',
				NOMBRE: 'Nom',
				APELLIDO1: 'Prénom',
				APELLIDO2: 'Deuxième nom de famille',
				FECHA_NAC: 'Date de naissance',
				FECHA_FAC: 'Date de décès',
				PASO2_DETALLE: 'Complétez les données de la cérémonie pour informer la famille et les amis',
				FECHA: 'Date',
				HORA: 'Heure',
				NOMBRE_FUN: 'Nom funéraire, tanatorio ou lieu de célébration',
				DIRECCION: 'Adresse, rue et numéro',
				CP: 'Cp',
				LOCALIDAD: 'Localité',
				PROVINCIA: 'Communauté / État autonome',
				PAIS: 'Pays',
				DESCRIPCION: 'Brève description de la cérémonie (masse, réponse, adieu ou autre)...',
				PASO3_DETALLE: 'Ajoutez tout ce que vous aimeriez vous souvenir de votre être cher, des détails, des anecdotes, des histoires, en plus des images et des vidéos.',
				RECUERDO: 'Écrivez ici sur votre bien-aimé',
				IMAGEN_TITULO: 'Téléchargez des images de la personne décédée',
				IMAGEN_PORTADA: 'Sélectionnez la photo de couverture de la page',
				IMAGEN_SLIDER: 'Sélectionnez d\'autres photos pour la page (6 images max.).',
				ARRASTRA: 'Faites glisser ou cliquez ici pour ajouter l\'image',
				IMAGEN_SUBIR: 'Augmenter',
				IMAGEN_BORRAR: 'Supprimer',
				SUBIR_ERR: 'Format incorrect',
				SUBIR_ERR_TEXT: 'Le fichier doit avoir le format d\'image',
				COMENTARIOS: 'Commentaires d\'amis et de famille.',
				COMENTARIOS_CHECK: 'Voulez-vous activer l\'option que vos amis et votre famille peuvent laisser des commentaires sur la page?',
				PASO4_DETALLE: 'Partagez la page avec votre famille et vos amis.',
				OPCION_TITULO: 'Voulez-vous activer l\'option que vos amis et votre famille peuvent également partager la page?',
				OPCION_CHECK: 'Activer l\'option de partager',
				OPCIONES: 'Sélectionnez les options que vous souhaitez partager la page',
				PREVISUALIZAR: 'Aperçu',
				LOADING: 'Préparer la planification de la page...',
				// - Pagina ya creada
				TITULO_CREADA: 'Page du défunt',
				BORRAR: 'Supprimer',
				borrarSwal: 'Supprimer le commentaire',
				borrarSwal_TEXT: 'Êtes-vous sûr d\'éliminer ce commentaire? Vous ne pourrez pas récupérer son contenu.',
				borrarSwal_BTN1: 'Oui, supprimez',
				borrarSwal_BTN2: 'Annuler'
			},
			TRAZABILIDAD: {
				TITULO: 'Service de service',
				NO: 'Il n\'y a pas de traces disponibles',
				ACTUALIZAR: 'Mise à jour'
			},
			PSICOLOGO: {
				TITULO: 'Psychologue gratuit en duel',
				DETALLE: 'Nous mettons à votre disposition le téléphone gratuit de soins psychologiques.',
				CODIGO: 'Identifier comme un client WFP avec le code suivant:',
				CAMPOS: 'Pour vous offrir une attention en fonction de vos besoins, remplissez les champs suivants.',
				PERSONA: 'Quel être cher est décédé?',
				PERSONA_SEL: 'Sélectionnez la personne',
				PADRES: 'Père Mère',
				HIJOS: 'Fils / a',
				HERMANO: 'Frère / a',
				OTRO: 'Autre',
				MOTIVO: 'Quelle est la raison de la mort?',
				MOTIVO_SEL: 'Sélectionnez la raison',
				LOADING: 'Envoi d\'informations sur le service...',
				ENVIAR: 'Envoyer une information',
				VER_CODIGO: 'Voir le code',
				MOTIVO1: 'Mort de non-natos ou d\'enfants',
				MOTIVO2: 'Mort inattendue par accident',
				MOTIVO3: 'Décès dû à une longue maladie',
				MOTIVO4: 'Mort multiple (mariage, deux membres ou plus d\'une famille)',
				MOTIVO5: 'Mort judiciaire (suicide, mort violente, autres)',
				MOTIVO6: 'Autres motifs'
			},
			LEGAL: {
				TITULO: 'Assistance légale',
				DETALLE: 'Une équipe de professionnels vous aidera et vous conseillera sur toute procédure ou procédure.'
			},
			JURIDICA: {
				TITULO: 'Assistance légale',
				DETALLE: 'Nous vous aidons à résoudre toutes les procédures administratives nécessaires telles que les domiciliations, les pensions, les contrats bas, etc.'
			},
			DIGITAL: {
				TITULO: 'Gestion de la vie numérique',
				DETALLE: 'Nous gérons et faisons la transition pour annuler tous les comptes, abonnements, réseaux sociaux et profils numériques de la personne qui est partie.'
			},
			FINANCIA: {
				TITULO: 'Service de financement',
				DETALLE: 'Nous mettons à votre disposition le service de financement familial. L\'économie ne devrait pas être un problème pour vous pour le moment.',
			},
			DOMESTIC: {
				TITULO: 'Services domestiques',
				DETALLE: 'Nous facilitons une aide urgente pour les soins aux adultes, aux enfants ou à d\'autres besoins maintenant ou à l\'avenir.'
			},
		},
		WFP: {
			CEREMONIA: 'Cérémonie',
			MEMORIA: 'En mémoire de',
			FALLECIDO: 'Mort le {{fecha}} dans {{lugar}}, à l\'âge de {{edad}}.',
			FECHA_UBIC: 'Date, heure et emplacement',
			COMPARTE_WEB: 'Partager la cérémonie de',
			ENVIAR_FLORES: 'Envoyer des fleurs à',
			PROVEEDORES: 'Nous vous offrons des fournisseurs d\'excellent rapport qualité / prix',
			COMPRAR: 'Acheter',
			MENSAJE: 'Cérémonie à la mémoire de',
			SMS: 'Entrez jusqu\'à 6 téléphones mobiles pour partager la page par SMS',
			SMS_NAME: 'Téléphone',
			EMAIL: 'Entrez jusqu\'à 6 adresses e-mail pour partager la page par e-mail',
			EMAIL_NAME: 'E-mail',
			ENVIAR: 'Partager Page',
			VOLVER: 'Retour à mon profil',
			NO_DISPONIBLE: 'La page n\'est pas disponible pour le moment.',
			COMENTARIOS: 'Commentaires de la famille et des amis',
			COMENTARIOS_TITULO: 'Publie un nouveau commentaire',
			COMENTARIOS_NOMBRE: 'Nom',
			COMENTARIOS_INPUT: 'Commentaire',
			COMENTARIOS_BOTON: 'Envoyer un commentaire',
			COMENTARIOS_ENVIADO: 'Commentaire correctement'
		},
		PREVIEW: {
			PREVISUALIZACION: 'Cérémonie de page de planification du défunt',
			VER: 'Voir dans la version',
			EDITAR: 'Modifier la page',
			FINALIZAR: 'Finir et partager',
			COMPARTE: 'Partagez la page de',
			COMPARTE2: 'Avec vos contacts',
			COMPARTIR: 'Partager à travers',
			ACCEDE: 'Accéder à la page ou copier votre lien',
			CONFIRMACION: 'Page enregistrée correctement',
			NO_PUBLICADA: 'Page non publiée',
			PREVISUALIZAR: 'Aperçu de la page et publication'
		},
























		




		MENU: {
			SEGUIMIENTO: 'seguimiento<br>de mis pujas',
			SEGUIMIENTO2: 'seguimiento de mis pujas',
			EJECUCION: 'en<br>ejecución',
			EJECUCION2: 'en ejecucion',
			TODAS: 'ver todas<br>las pujas',
			TODAS2: 'ver todas las pujas',
			CREAR: 'crear<br>licitación',
			CREAR2: 'crear licitación',
			EXPLORAR: 'explorar mis<br>licitaciones',
			EXPLORAR2: 'explorar mis licitaciones',
			PENDIENTES: 'pendientes<br>de adjudicar',
			PENDIENTES2: 'pendientes de adjudicar',
			TODAS_LIC: 'ver todas<br>las licitaciones',
			TODAS_LIC2: 'ver todas las licitaciones',
			CONTRATAR: 'CONTRATAR',
			PERFIL: 'Mi Perfil',
			LOGOUT: 'Cerrar Sesión'
		},
		FOOTER: {
			CONTACTA: 'Contacta con nosotros',
			SEDES: 'Sedes en: Madrid, Mexico, Lisboa y Colonia.',
			AVISO_LEGAL: 'Aviso legal',
			POLITICA: 'Política de privacidad'
		},
		DEMO: {
			LOADING: 'Preparando la plataforma, un momento por favor...',
			BIENVENIDA: 'Le damos la bienvenida a WFN',
			SECTOR: 'Seleccione el sector al que pertenece para acceder a la plataforma de demostración.',
			FUNERARIA: 'SOY OPERADOR FUNERARIO',
			ASEGURADORA: 'SOY ASEGURADORA, EMPRESA O COLECTIVO',
			HOME: { //NUEVO3 <--------------
				STEP1: '¡Bienvenid@ a WFN! Esta es la plataforma de demostración. Vamos a descubrir las funcionalidades de la plataforma y después podrás interactuar con ella y hacer las pruebas que necesites.',
				STEP2: 'Este es el menú principal. Desde aquí podrás acceder a las diferentes páginas disponibles.',
				STEP3: 'Aquí podrás hacer el seguimiento de las licitaciones en las que has participado.',
				STEP4: 'Aquí se muestran las licitaciones que te han sido asignadas. En ellas podrás consultar la información del adjudicatario y rellenar la trazabilidad.',
				STEP5: 'Finalmente, aquí se muestra el listado completo de las licitaciones disponibles. Vamos a ir a esta página para verlas más en detalle.',
				STEP6: 'Podrás ofertar tus licitaciones en este menu. Insertarás sus detalles y serán publicadas en el listado. Podrás probarlo al finalizar esta demostración.',
				STEP7: 'Una vez creadas, encontrarás en esta página todas tus licitaciones.',
				STEP8: 'Cuando una licitación haya finalizado, podrás encontrar todas las que estén pendientes de asignar a una funeraria en esta pantalla.',
				STEP9: 'Aquí se muestran las licitaciones que ya has asignado a una funeraria y se encuentran en ejecución. En ellas podrás consultar la información de la funeraria y ver su trazabilidad.',
				STEP10: 'Finalmente, aquí se muestra el listado completo de las licitaciones disponibles. Vamos a ir a esta página para verlas más en detalle.',
			},
			LICIT: {
				STEP1: '¡Bienvenid@ a WFN! Esta es la plataforma de demostración. Vamos a descubrir las funcionalidades de la plataforma y después podrás interactuar con ella y hacer las pruebas que necesites.',
				STEP2: 'Cada “card” que ves, es una licitación puesta por una aseguradora o colectivo. En ella encontrarás toda información y detalles de los servicios, tales como localización, el tiempo restante, el importe actual, estado del servicio etc.',
				STEP3: 'En la zona superior encontrarás la referencia de la licitación, junto a la fecha de creación. Así mismo, podrás eliminarlas en cualquier momento (se avisará a los participantes) y editarlas cuando aún no hayan participado en ella. Si la licitación se queda desierta podrás editarla también una vez finalizada.',
				STEP4: 'En la zona superior encontrarás la referencia que el sistema ha asignado a la licitación, junto a la fecha de creación.',
				STEP5: "Estos son los servicios que hay que prestar en una licitación. Al pulsar sobre ellos podrás ver su detalle general. Los servicios con el icono <b style='color: red'>+</b> indican que el licitador ha incluido un detalle específico",
				STEP6: 'A continuación se indica la localización en la que se realizará el servicio o el origen y destino en los traslados',
				STEP7: 'Este es el tiempo restante para que finalice la licitación y por tanto el tiempo para pujar.',
				STEP8: 'En este espacio observarás el estado actual de la licitación. Esta puede estar abierta, pendiente de adjudicación, pendiente de ejecución, completada, cerrada o desierta.',
				STEP9: 'Finalmente encontrarás el importe actual de la licitación y podrás acceder al detalle de sus pujas o inscritos.',
				STEP10: 'Una vez se haya asignado una licitación podrás ver el progreso de la trazabilidad y los detalles de la funeraria adjudicada.',
				STEP11: 'Además, en el siguiente menu podrás ver el detalle de la trazabilidad que rellenará la funeraria.',
				STEP12: 'En este espacio observarás el estado actual de la licitación. Esta puede estar abierta (ganando/perdiendo/inscrito), en trámite, en ejecución, completada o desierta.',
				STEP13: 'Finalmente encontrarás el importe actual de la licitación y podrás pujar o inscribirte en la misma.',
				STEP14: 'Una vez te hayan asignado una licitación podrás ver los detalles de la empresa adjudicadora.',
				STEP15: 'Si la licitación se encuentra en ejecución podrás rellenar la información de trazabilidad del servicio.',
				STEP16: 'Podrás personalizar la vista de cards en todo momento con los diferentes filtros disponibles.',
				STEP17: 'Esto es todo. Recuerda que estás en la plataforma de demostración. Podrás interactuar con las licitaciones disponibles, crear nuevas y hacer todas las pruebas que necesites.',
				STEP18: 'Esto es todo. Recuerda que estás en la plataforma de demostración. Podrás interactuar con las licitaciones disponibles, pujar o inscribirte en ellas y hacer todas las pruebas que necesites.',
			}
		},
		CONFIRMACION: {
			LOADING: 'Procesando la contratación...',
			SUCCESS: 'Contratación completada',
			SUCCESS_SUB: 'Serás redirigido a la plataforma en unos segundos...',
			PENDING: 'Confirmación de cuenta pendiente',
			PENDING_SUB: 'Antes de continuar, su cuenta debe ser confirmada por la organización. La organización se reserva el derecho de admisión, valoraremos su ingreso en 24 – 48 H y podrá finalizar el proceso de contratación.',
			PAGO: 'Accediendo a la confirmación de pago...'
		},
		PAGO: {
			LOADING: 'Accediendo a plataforma segura de pagos...',
			RESULT_LOADING: 'Cargando datos de suscripción...',
			METODO: 'Selección de método de pago',
			METODO_SUB: 'Seleccione su método de pago para finalizar la activación de su cuenta.',
			METODO_TARJETA: 'PAGO CON TARJETA DE CRÉDITO',
			METODO_SEPA: 'PAGO CON DOMICILIACIÓN BANCARIA (SEPA)',
			SEPA_TITLE: 'Pago con domiciliación bancaria - SEPA',
			SEPA_NOMBRE: 'Nombre Titular',
			SEPA_IBAN: 'Número de cuenta (IBAN)',
			SEPA_CODIGO: 'Código de promoción',
			SEPA_ERRORCOD: 'El código introducido no es correcto',
			SEPA_BUTTON: 'Suscribirme a WFuneralNet',
			SEPA_LOADING: 'Procesando datos...',
			SEPA_LEGAL: 'Al proporcionar su información de pago y confirmar este pago, usted autoriza a WFuneralNet y a Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a su banco para que cargue el importe en su cuenta y a su banco para que cargue el importe en su cuenta de acuerdo con dichas instrucciones. Como parte de sus derechos, tiene derecho a un reembolso por parte de su banco según los términos y condiciones de su acuerdo con su banco. Sus derechos se explican en una declaración que puede obtener de su banco. Usted acepta recibir notificaciones de futuros cargos hasta 2 días antes de que se produzcan.',
			SUCCESS: 'Su suscripción ha sido activada correctamente.',
			SUCCESS_SUB: 'Puedes comenzar a usar la plataforma con toda su funcionalidad. Pulsa en el siguiente botón para acceder al inicio.',
			SUCCESS_BUTTON: 'COMENZAR A UTILIZAR WORLD FUNERAL NET',
			CANCEL: 'Has cancelado el proceso de suscripción.',
			CANCEL_SUB: 'Si ha ocurrido un error o quieres volver a intentarlo, pulsa en el siguiente botón para acceder a la plataforma segura de pagos.',
			CANCEL_BUTTON: 'CONTINUAR A PLATAFORMA SEGURA DE PAGO'
		},
		LICIT: {
			FILTROS: {
				TITLE: 'Filtra a tu medida',
				INVITADO: 'Los filtros se encuentran desactivados en la cuenta de invitado.',
				CONTRATAR: 'Contratar',
				REFERENCIA: 'Referencia',
				SERVICIO: 'Servicio',
				LOC_ORIGEN: 'Localización origen',
				LOC_DESTINO: 'Localización destino',
				TODOS: 'Todos',
				PAIS: 'País',
				COMUNIDAD: 'Comunidad autónoma',
				ESTADO: 'Estado',
				FECHA_DESDE: 'Fecha desde',
				FECHA_HASTA: 'Fecha hasta',
				PRECIO_MIN: 'Precio min.',
				PRECIO_MAX: 'Precio max.',
				ESTADO_LIC: 'Estado licitación',
				ESTADOS: {
					PENDIENTE_INI: 'Pendiente Inicio',
					ABIERTA: 'Abierta',
					PENDIENTE_ADJ: 'Pendiente adjudicación',
					ADJ_TRAM: 'Adjud. en trámite',
					ADJ_PEN: 'Adjud. y pendiente',
					EJEC: 'En ejecución',
					COMPLETADA: 'Completada',
					CERRADA: 'Cerrada',
					DESIERTA: 'Desierta'
				},
				REESTABLECER: 'Reestablecer filtros'
			},
			ORDEN: {
				ESTADO: 'Ordenar por estado de licitación',
				ESTADO_ABIERTAS: 'Mostrar primero abiertas',
				ESTADO_PENDIENTE: 'Mostrar primero pendientes',
				ESTADO_ADJUD: 'Mostrar primero adjudicadas',
				FECHA: 'Ordenar por fecha de inicio',
				FECHA_ASC: 'Ascendente',
				FECHA_DESC: 'Descendente',
				TIEMPO: 'Ordenar por tiempo hasta finalización',
				TIEMPO_ASC: 'Ascendente',
				TIEMPO_DESC: 'Descendente'
			},
			LOADING: 'Cargando licitaciones...',
			NOHAY: 'No hay licitaciones',
			//NUEVO2 --------------
			CARD: {
				ORIGEN: 'Origen',
				DESTINO: 'Destino',
				TRASLADO: 'Traslado',
				LOCALIZ: 'Localización',
				PUJA: 'puja',
				OFERTA: 'oferta',
				PUJAR: 'pujar',
				OFERTAR: 'ofertar',
				LA: 'La',
				TIME: 'Tiempo restante para',
				COMIENZA: 'comienza en',
				VER_ASEG: 'ver aseguradora',
				VER_FUN: 'ver funeraria',
				VER_DETALLES: 'ver detalles',
				ESTADOS: {
					PENDIENTE_INI: 'Pendiente de inicio',
					ABIERTA: 'Abierta',
					INSCRITO: 'Inscrito',
					GANANDO: 'Ganando',
					PERDIENDO: 'Perdiendo',
					CONTU: 'con tu',
					PUJA: 'puja',
					OFERTA: 'oferta',
					DE: 'de',
					PENDIENTE_ADJ: 'Pendiente',
					PENDIENTE_ADJ2: 'adjudicación',
					CERRADA: 'Cerrada',
					ADJ_TRAM: 'Adjudicación en trámite',
					ADJ_PEN: 'Adjudicada y',
					ADJ_PEN2: 'pendiente de ejecución',
					EJEC: 'En ejecución',
					COMPLETADA: 'Completada',
					NO_ADJ: 'No adjudicado',
					DESIERTA: 'Desierta'
				},
				PUJA_SALIDA: 'Puja salida',
				OFERTA_SALIDA: 'Oferta salida',
				PUJA_ACTUAL: 'Puja actual',
				OFERTA_ACTUAL: 'Oferta actual',
				PUJA_GANADORA: 'Puja ganadora',
				OFERTA_GANADORA: 'Oferta ganadora',
				PUJA_DESIERTA: 'Puja desierta',
				OFERTA_DESIERTA: 'Oferta desierta',
				PUJA_ADJUDIC: 'Puja adjudic.',
				OFERTA_ADJUDIC: 'Oferta adjudic.',
				PRECIO_FIJO: 'Precio fijo',
				VER_PUJAS: 'VER PUJAS',
				VER_OFERTAS: 'VER OFERTAS',
				INSCRITOS: 'INSCRITOS',
				ADJUDICAR: 'ADJUDICAR',
				TRAZABILIDAD: 'TRAZABILIDAD',
				INSCRÍBETE: 'INSCRÍBETE',
				PUJAR2: 'PUJAR',
				OFERTAR2: 'OFERTAR',
				VOLVER_PUJAR: 'VOLVER A PUJAR',
				VOLVER_OFERTAR: 'VOLVER A OFERTAR',
				IVA_INC: 'IVA no incluido',
				HIST: {
					HISTORICO_PUJAS: 'Histórico de pujas',
					HISTORICO_OFERTAS: 'Histórico de ofertas',
					HISTORICO_INSC: 'Histórico de inscritos',
					IMPORTE_SALIDA: 'Importe salida',
					ACTUALIZAR: 'actualizar',
					TOTAL: 'Total',
					FECHA: 'Fecha',
					USUARIO: 'Usuario',
					IMPORTE: 'Importe',
					DESIERTA: 'Licitación desierta',
					CREADOR: 'CREADOR',
				},
				CERRAR: 'cerrar',
				ASIG: {
					NOMBRE: 'Nombre',
					LICENCIA: 'Licencia',
					DOMICILIO: 'Domicilio',
					TELEFONO: 'Teléfono',
					EMAIL: 'Email',
					WEB: 'Página Web',
					CERTIFICACIONES: 'Certificaciones',
					PUNTUACIONES: 'Puntuaciones',
					IMPORTE: 'Importe licitación',
					ADJUDICAR: 'ADJUDICAR',
					EXCLUIR: 'EXCLUIR',
					EXCLUIR_TODAS: 'EXCLUIR TODAS',
					BLOQUEAR: 'BLOQUEAR PROV.',
					VER_DETALLES: 'ver detalles',
					CERRAR_DETALLES: 'cerrar detalles',
					DETALLE_ASIG1: 'La Aseguradora se reserva el derecho de adjudicación valorando la calidad de los servicios ofrecidos por la Funeraria.',
					DETALLE_ASIG2: 'Es importante que completes tu perfil con puntuaciones y certificados de calidad.',
					DETALLE_ASIG_BTN: 'Completar perfil',
					REFERENCIA: 'Referencia licit. WFN',
					REFERENCIA2: 'Referencia licit. creador',
					SIGUIENTES_PUJAS: 'Siguientes pujas',
					SIGUIENTES_OFERTAS: 'Siguientes ofertas'
				},
				PUJAS: {
					PUJA_RAPIDA: 'Puja rápida',
					OFERTA_RAPIDA: 'Oferta rápida',
					PUJA_MANUAL: 'o introduce manualmente tu puja',
					OFERTA_MANUAL: 'o introduce manualmente tu oferta',
					PUJAR: 'PUJAR',
					OFERTAR: 'OFERTAR',
					AVISO: 'Aviso',
					PRORROGA3_PUJA: 'La licitación se extenderá en 3 minutos al pujar durante el minuto final',
					PRORROGA3_OFERTA: 'La licitación se extenderá en 3 minutos al ofertar durante el minuto final',
					PRORROGA1_PUJA: 'La licitación se extenderá en 1 minuto al pujar durante el minuto final',
					PRORROGA1_OFERTA: 'La licitación se extenderá en 1 minuto al ofertar durante el minuto final',
					PROCESO_PUJA: 'Su puja se está procesando. Un momento por favor...',
					PROCESO_OFERTA: 'Su oferta se está procesando. Un momento por favor...',
					HISTORICO_PUJAS: 'Ver histórico de pujas',
					HISTORICO_OFERTAS: 'Ver histórico de ofertas'
				},
				TRAZA: {
					INCIDENCIA: 'Incidencia en el servicio',
					VALIDAR: 'VALIDAR',
					CANCELAR: 'CANCELAR'
				},
				SERVICIO: {
					TIPO_TRASLADO: 'Tipo traslado',
					CARRETERA: 'Carretera punto a punto',
					AEROPUERTO: 'Aeropuerto',
					INCLUIDOS: 'Servicios Incluídos',
					TIPO_COMPLETO: 'Tipo Serv. Completo',
					CREMACION: 'Cremación + Urna',
					DESTFIN: 'Destino Final Temp.',
					DETALLE_ESP: 'Detalle específico del servicio',
					ESPEC_GEN: 'Especificaciones generales del servicio',
					TRASLADO1: 'El fallecimiento y el destino final (inhumación o incineración) se produce en localidades distintas.',
					TRASLADO2: 'Elementos y servicios habituales del traslado:',
					TRASLADO3: 'Arca de traslado : En aquellos casos en los que el Reglamento de Policía Sanitaria Mortuoria (RPSM) lo exija deberá estar provisto adicionalmente de un dispositivo de filtrado de aire u otro para equilibrar la presión interior y exterior.',
					TRASLADO4: 'Flete aéreo en su caso, Kms. realizados por carretera y gestiones y trámites específicos.'					
				}
			},
			SWAL: { 
				CANCELAR: 'Cancelar',
				registerSwal: 'Contratación Necesaria',
				registerSwal_TEXT: 'Este contenido no se encuentra disponible con una cuenta de invitado. Contrata una tarifa para continuar.',
				registerSwal_BTN1: 'Contratar',
				suscriptionSwal: 'Licitación no disponible',
				suscriptionSwal_TEXT: 'Este contenido no se encuentra disponible con tu suscripción. Revisa tu tarifa para acceder.',
				suscriptionSwal_BTN1: 'Mi Perfil',
				confirmationSwal: 'Licitación Correcta',
				confirmationSwal_TEXT: 'Tu importe se ha licitado correctamente',
				errorSwal: 'Importe Incorrecto',
				errorSwal_TEXT: 'El importe debe ser inferior al actual de la licitación.',
				errorNegativeSwal_TEXT: 'El importe de la licitación no puede ser negativo.',
				error2Swal_TEXT: 'El importe de la licitación ha cambiado y ahora es inferior a tu propuesta. Inténtelo de nuevo con otro importe.',
				confirmaSwal: 'Confirmación',
				confirmaSwal_TEXT1: '¿Confirmas la licitación por {{importeLicitar}} {{divisa}} ?',
				confirmaSwal_TEXT2: '¿Confirmas la inscripción a la licitación? Recibirás una notificación si eres el elegido al finalizar.',
				confirmaSwal_BTN1: 'Si, confirmo',
				confirmaElegidoSuccessSwal: 'Usuario elegido correctamente.',
				confirmaElegidoSuccessSwal_TEXT: 'El adjudicatario se ha asignado correctamente. A continuación puedes ver sus detalles.',
				confirmaElegidoSwal_TEXT1: '¿Confirmas a {{empresa}} como adjudicatario de la licitación?',
				confirmaSaltarSwal_TEXT1: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más exlusiones, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones',
				confirmaSaltarSwal_TEXT2: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones. Exclusión {{pasadas}}/2',
				confirmaSaltarSwal_TEXT3: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación? No hay más exlusiones, la licitación se quedará desierta.',
				confirmaSaltarSwal_TEXT4: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación? No podrás volver a este usuario. Exclusión {{pasadas}}/2',
				confirmaSaltarSwal_TEXT5: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? No hay más participantes, la licitación se quedará desierta. Este participante no volverá a ver tus licitaciones',
				confirmaSaltarSwal_TEXT6: '¿Confirmas excluir a {{empresa}} como adjudicatario de la licitación y rechazarlo en futuras licitaciones? Este participante no volverá a ver tus licitaciones.',
				confirmaSaltarSwal_TEXT7: '¿Confirmas excluir a todos los participantes? La licitación se quedará desierta.',
				confirmaSaltarSuccessSwal1: 'Participante excluido correctamente.',
				confirmaSaltarSuccessSwal2: 'Participante bloqueado correctamente.',
				confirmaSaltarSuccessSwal_TEXT1: 'Se ha excluido al participante. Recuerda que solo puedes excluir 2 veces por licitación.',
				confirmaSaltarSuccessSwal_TEXT2: 'Se ha excluido al participante. La licitación ha quedado desierta.',
				confirmaSaltarSuccessSwal_TEXT3: 'Se ha excluido al participante de la licitación.',
				confirmaSaltarSuccessSwal_TEXT4: 'Se ha excluído al participante y no volverá a ver tus licitaciones. La licitación ha quedado desierta.',
				confirmaSaltarSuccessSwal_TEXT5: 'Se ha excluído al participante y no volverá a ver tus licitaciones.',
				confirmaTrazaSwal: 'Confirmación de traza',
				confirmaTrazaSwal_TEXT: '¿Deseas confirmar esta traza? Se informará al licitador.',
				confirmaTrazaSwal_BTN1: 'Si, confirmo',
				errorTrazaSwal: 'Error en la traza',
				errorTrazaSwal_TEXT: 'Debes introducir un comentario del servicio al haber una incidencia en el mismo.',
				confirmaTrazaSuccessSwal: 'Traza confirmada correctamente.',
				confirmaTrazaSuccessSwal_TEXT: 'La traza seleccionada se ha confirmado correctamente.',
				cancelaTrazaSwal: 'Cancelación de traza',
				cancelaTrazaSwal_TEXT: '¿Deseas cancelar esta traza? Se borrará toda la información de la misma.',
				cancelaTrazaSwal_BTN1: 'Si, confirmo',
				cancelaTrazaSuccessSwal: 'Traza cancelada correctamente.',
				cancelaTrazaSuccessSwal_TEXT: 'La traza seleccionada se ha cancelado correctamente.',
				borrarSwal: 'Eliminar Licitación',
				borrarSwal_TEXT: '¿Estás seguro de eliminar esta licitación? No podrás recuperar su contenido.',
				borrarSwal_BTN1: 'Si, eliminar',
				borrarConfirmSwal: 'Licitación eliminada correctamente',
				borrarConfirmSwal_TEXT: 'Se ha eliminado correctamente la licitación.',
				conexionSwal: 'Límite de conexiones superado',
				conexionSwal_TEXT: 'Se ha superado el número de conexiones simultáneas disponible. Cierra alguna de las sesiones activas para continuar.'
			},
			TUTORIAL: 'VER TUTORIAL DE NUEVO'
		},
		GLOBAL: {
			PAGO: 'Pago de suscripción pendiente.',
			PAGO_SUB: 'Aún no nos has proporcionado los datos de pago de tu suscripción. Pulsa el botón para acceder a la plataforma segura de pagos.',
			PAGO_BUTTON: 'PLATAFORMA SEGURA DE PAGOS'
		},
		INPUT: {
			VALIDATION: {
				INVALID: '{{name}} no es válido',
				REQUIRED: '{{name}} debe ser introducido',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Es necesario aceptar los términos y condiciones',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'Los datos introducidos no son correctos. Inténtelo de nuevo.',
				REQUIRED_FIELD: 'Debes rellenar este campo',
				MIN_LENGTH_FIELD: 'El tamaño mínimo de este campo es:',
				MAX_LENGTH_FIELD: 'El tamaño máximo de este campo es:',
				INVALID_FIELD: 'Este campo no es válido',
				MATCH: 'La contraseña y su confirmación no coinciden',
				INVALID_REGISTER: 'El email introducido ya se encuentra registrado. Inténtalo de nuevo o inicia sesión.'
			}
		},
		CREAR: {
			TITULO: 'Crear licitación',
			TITULO_EDIT: 'Editar licitación',
			REF: 'Referencia del cliente',
			REF2: '(no será mostrado públicamente)',
			REF_ERROR: 'La referencia ya registrada en el sistema.',
			TIPO: 'Tipo de licitación',
			ESTANDAR: 'Licitación estándar.',
			ESTANDAR_DESC: 'Introduce una cifra de salida. Las funerarias pujarán a la baja para adjudicarse el servicio o traslado.',
			FIJO: 'Licitación a precio fijo.',
			FIJO_DESC: 'El servicio se adjudicará al precio establecido entre todas las funerarias que lo hayan solicitado.',
			FECHA_INI: 'Fecha de inicio/hora',
			FECHA_FIN: 'Fecha de finalización/hora',
			FECHA_ERROR: 'La fecha de finalización no puede ser anterior a la fecha inicio.',
			OPT_TRASLADO: 'Licitar traslado',
			OPT_SERVICIO: 'Licitar servicio',
			IMPORTE: 'Importe de salida',
			IMPORTE_ERROR1: 'El importe del traslado no puede ser negativo',
			IMPORTE_ERROR2: 'El importe del traslado debe ser inferior a 100.000',
			DETALLE: 'Detalle específico del servicio',
			ORIGEN: 'ORIGEN',
			PAIS: 'País',
			COMUNIDAD: 'Comunidad autónoma',
			ESTADO: 'Estado',
			POBLACION: 'Población',
			DESTINO: 'DESTINO',
			KMS: 'KMs a destino',
			OPCIONAL: '(opcional)',
			LOCALIZACION: 'LOCALIZACIÓN',
			DESTINO_TRASLADO: '(Destino del traslado)',
			PENDIENTE_DESTINO: 'Pendiente seleccionar destino del traslado',
			SERVICIO_ERROR: 'Debes seleccionar al menos un servicio a licitar',
			IMPORTE_ERROR3: 'El importe del traslado no puede ser negativo',
			IMPORTE_ERROR4: 'El importe del traslado debe ser inferior a 100.000',
			INCLUIDO_EN: 'Incluído en',
			INCLUIDO_EN2: 'Servicio Completo',
			SELECT_OPCION: 'Seleccione una opción',
			OPCION_ERROR: 'Debes seleccionar una opción',
			OTROS_OPCION: 'Seleccione otros servicios a incluir',
			CREACION: 'Formato de creación',
			UNIDO: 'Misma licitación.',
			UNIDO_DESC: 'El traslado y los servicios se ofertarán en una misma licitación. La funeraria adjudicada será la misma para ambos.',
			SEPARADO: 'Licitaciones separadas.',
			SEPARADO_DESC: 'El traslado y los servicios se ofertarán en licitaciones separadas. La funeraria adjudicada puede ser distinta en ambas.',
			BOTON: 'AÑADIR LICITACIÓN',
			BOTON_EDIT: 'EDITAR LICITACIÓN',
			SWAL: {
				confirmationAddSwal: 'Licitación Añadida',
				confirmationAddSwal_TEXT: 'Los servicios seleccionados se han añadido correctamente al listado de licitaciones',
				confirmationEditSwal: 'Licitación Editada',
				confirmationEditSwal_TEXT: 'La licitación se ha editado correctamente',
				errorLicitacionSwal: 'Licitación con pujas',
				errorLicitacionSwal_TEXT: 'Esta licitación ya ha recibido pujas. No es posible hacer modificaciones, puedes borrarla y crearla de nuevo.'
			}
		},
		START_old: {
			PASOS: {
				DATOS: 'Datos de contratante',
				VALIDACION: 'Validación de datos',
				ADICIONAL: 'Información adicional',
				PAGO: 'Datos de pago',
				CONFIRMACION: 'Confirmación',
				TARIFA: 'Selección de tarifa',
				TARIFA2: 'Información de tarifa'
			},
			TARIFA_LOC: 'Indique la localización de aplicación de tarifa',			
			CANALES: 'Quiero ser notificado a través de los siguientes canales: <i>(al menos uno)</i>',
			VOLVER: 'VOLVER',
			CONTINUAR: 'CONTINUAR',
			VALIDACION: 'Validación de datos',
			SMS: {
				TITULO: 'Validación de teléfono móvil',
				MENSAJE: 'Le hemos enviado un SMS al teléfono {{telefono}} con un código de confirmación. Introdúzcalo a continuación.',
				CODIGO: 'Código de confirmación',
				CODIGO_ERROR: 'El código introducido no es válido',
				REENVIAR: 'Reenviar código',
				REENVIADO: 'Mensaje reenviado'
			},
			CERTIFICADOS: 'Información adicional - Certificados y punt. calidad',
			PAGO: 'Introduce los datos bancarios',
			CONFIRMACION: 'Confirmación',
			PERSONAL: 'Información Personal',
			TARIFA: 'Tarifa',
			ALTA: 'Alta como',
			OFERTANTE: 'Ofertante',
			PROVEEDOR: 'Proveedor',
			SERVICIOS: 'de traslados y servicios funerarios',
			DATOS_PAGO: 'Datos de pago',
			CONFIRMAR: 'CONFIRMAR',
			CONFIRMAR_PAGAR: 'CONFIRMAR Y PAGAR',

			SWAL: {
				errorSMSSwal: 'Código SMS incorrecto',
				errorSMSSwal_TEXT: 'El código de validación de teléfono enviado por SMS es incorrecto. Revíselo e introdúzcalo de nuevo.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.'
			}
		},
		PERFIL: {
			SUSCRIPCION_HASTA: 'Suscripción hasta',
			GUARDAR: 'GUARDAR INFORMACIÓN',
			DATOS: {
				TITULO: 'Mi perfil',
				TITULO2: 'Información del usuario',
				EMAIL: 'Email',
				EMPRESA: 'Empresa/Colectivo',
				EMPRESA2: 'Nombre de la empresa o colectivo',
				ACTIVIDAD: 'Actividad',
				LICENCIA: 'Nº Licencia',
				PAIS: 'País',
				COMUNIDAD: 'Comunidad autónoma/Estado',
				DIRECCION: 'Dirección',
				POBLACION: 'Población',
				CP: 'CP',
				MOVIL: 'Teléfono Movil',
				NOTIFICACIONES: 'Notificaciones',
				TELEFONO: 'Teléfono',
				WEB: 'Página Web',
			},
			SUSCRIPCION: {
				TITULO: 'Suscripción',
				NO_HAY: 'No hay ninguna suscripción activa',
				CAMBIO: 'Procesando cambio de método de pago...',
				TARIFA: 'Tarifa',
				FECHA_INICIO: 'Fecha Inicio',
				FECHA_FIN: 'Fecha Fin',
				PROX_RENOV: 'Próx.Renovación',
				FACTURACION: 'Facturación',
				PRECIO: 'Precio',
				LOCALIZACION: 'Localización de la tarifa',
				ESTADO: 'Estado tarifa',
				RENOV_AUTO: 'Renovación automática',
				RENOV_CANCEL: 'Renovación cancelada',
				PROX_CAMBIO: 'Próximo cambio en la tarifa',
				FECHA_EFECT: 'Fecha Efectiva',
				LOCALIZACION2: 'Localización',
				CANCELAR_CAMBIO: 'Cancelar cambio',
				CAMBIO_PROCESO: 'Procesando cancelación...',
				METODO_PAGO: 'Método de pago',
				EXPIRACION: 'Expiración',
				PAGO_CAMBIO: 'Cambiar método de pago',
				PAGO_LOADING: 'Cargando plataforma de pago...',
				BTN_LOCALIZACION: 'CAMBIAR LOCALIZACIÓN',
				BTN_TARIFA: 'CAMBIAR TARIFA',
				BTN_SUSC: 'CANCELAR SUSCRIPCIÓN',
				BTN_CANCEL: 'PROCESANDO CANCELACIÓN...'
			},
			METODO_PAGO: {
				TITULO: 'Método de pago',
				TITULAR: 'Titular',
				FACTURACION: 'Facturación',
				MENSUAL: 'Mensual',
				LICITA: 'Por licitación',
				CAMBIAR: 'Cambiar datos de pago',
				NOMBRE: 'Nombre Titular',
				IBAN: 'Número de cuenta (IBAN)'
			},
			PAGOS: {
				TITULO: 'Historial de pagos',
				TITULO2: 'Historial de pagos - facturas',
				IMPORTE: 'Importe',
				ESTADO: 'Estado',
				VER: 'Ver',
				DESCARGA: 'Descarga'
			},
			BLOQUEO: {
				TITULO: 'Lista de bloqueo',
				FECHA: 'Fecha Bloqueo'
			},
			CERTIFICACIONES: {
				TITULO: 'Mis certificaciones y puntuaciones',
				TITULO2: 'Certificaciones/Puntuaciones de calidad de la empresa',
				TITULO3: 'Añade una nueva certificación',
				TITULO5: 'Actualizar certificación',
				TITULO4: 'Certificaciones',
				NPS: 'Introduce las puntuaciones NPS',
				OTRO: 'Otro índice de medición de calidad (identificación)',
				OTRO1: 'Introduce la puntuación % de',
				OTRO2: 'Introduce la puntuación % de otro índice',
                NOMBRE: 'Nombre',
                ESTADO: 'Estado',
                SUBIDA: 'Fecha Subida',
                VALIDEZ: 'Fecha Validez',
				BORRAR: 'Borrar',
                BOTON: 'Añadir certificación',
				BOTON2: 'Actualizar certificación',
				CANCELAR: 'Cancelar',
                GUARDAR: 'Guardar certificación',
                PROCESO: 'Procesando certificación',
                ESTADO_CORRECTO: 'Correcto',
                ESTADO_REVISION: 'En revisión',
                ESTADO_ERROR: 'No válido',
                ESTADO_CADUCADO: 'Caducado',
				ACTUALIZAR: 'Actualizar',
				SUBIR_ERR: 'Formato Incorrecto',
				SUBIR_ERR_TEXT: 'La certificación debe subirse en formato PDF',
				SUBIR_ERR2: 'Archivo no seleccionado',
				SUBIR_ERR2_TEXT: 'Debes seleccionar o arrastrar un archivo en el cuadro de la zona superior',
				ARRASTRA: 'Arrastra o pulsa aquí para subir tu certificación (PDF)',
				SUBIR_OK: 'Certificación guardada',
				SUBIR_OK_TEXT: 'La certificación se ha guardado con éxito',
				BORRAR_PREG: 'Borrar certificación',
				BORRAR_PREG_TEXT: '¿Estás seguro de borrar esta certificación? No se podrá recuperar.',
				BORRAR_BOTON: 'Si, borrar',
				BORRAR_BOTON2: 'Cancelar',
				BORRAR_OK: 'Certificación borrada',
				BORRAR_OK_TEXT: 'La certificación se ha borrado con éxito.'
			},
			ALERTAS: {
				TITULO: 'Mi sistema de alertas',
				FRECUENCIA: 'Selecciona la frecuencia de recepción de notificaciones de nuevas licitaciones.',
				INMEDIATO: 'Inmediato',
				MINUTOS: 'Cada 15 minutos',
				NUNCA: 'Nunca',
				MODO: 'Ajusta el modo en el que serás notificado de los mensajes de la plataforma.',
				MODO_ERROR: 'Debes seleccionar al menos una opción',
				TELEGRAM_TITULO: 'Activación de notificaciones de Telegram',
				TELEGRAM1: 'Siga las siguientes instrucciones para activar las notificaciones en la aplicación Telegram:',
				TELEGRAM2: '1. Abre la aplicación <b>Telegram</b> en tu dispositivo movil o instálala desde la tienda de aplicaciónes (App Store - Apple o Google Play - Android)',
				TELEGRAM3: '2. Busca el usuario <b>WFuneralNetBot</b> en la barra de búsqueda de la zona superior. Alternativa: Si tienes Telegram instalado en tu dispositivo actual puedes acceder a este enlace <a class="g-font-weight-500 g-color-gray-wfn--hover" href="https://t.me/WFuneralNetBot" target="_blank">https://t.me/WFuneralNetBot</a> y abrir la aplicación.',
				TELEGRAM4: '3. Pulse sobre el botón <b>Iniciar</b> que le aparecerá al encontrar el usuario en la aplicación.',
				TELEGRAM5: '4. Introduzca a continuación el código de validación que le aparecerá en la conversación.',
				COD_DISPOSITIVO: 'Código dispositivo',
				COD_ERROR: 'El código introducido no es válido',
				NOTIFICACIONES: 'Personaliza las notificaciones que recibirás de la actividad de la plataforma'
			},
			CAMBIO: {
				AVISO: 'AVISO: El cambio de localización será efectivo en las próximas 48 horas.',
				BTN_CANCELAR: 'CANCELAR CAMBIO',
				BTN_LOC: 'CAMBIAR LOCALIZACIÓN',
				BTN_TARIFA: 'CAMBIAR TARIFA',
				BTN_PROCESO: 'PROCESANDO CAMBIO...',
				SELECCIONE_TARIFA: 'Seleccione la tarifa',
				PAGAR: 'Cantidad a pagar',
				AVISO2: 'AVISO: La nueva tarifa se aplicará inmediatamente.'
			},
			TODO_MUNDO: 'Todo el mundo',
			DISPOSITIVOS: {
				TITULO: 'Dispositivos registrados',
				NOMBRE: 'Nombre dispositivo',
				FECHA: 'Fecha registro'
			},
			SWAL: {
				confirmationSwal: 'Datos Guardados',
				confirmationSwal_TEXT: 'Los datos se han guardado correctamente',
				borrarSwal: 'Borrar Bloqueo',
				borrarSwal_TEXT: '¿Deseas borrar este bloqueo?',
				borrarSwal_BTN1: 'Si, borrar',
				borrarSwal_BTN2: 'Cancelar',
				borrarConfirmSwal: 'Bloqueo borrado correctamente',
				borrarConfirmSwal_TEXT: 'Se ha borrado el bloqueo correctamente.',
				cancelarSuscripSwal: 'Cancelar Suscripción',
				cancelarSuscripSwal_TEXT: '¿Estás seguro de cancelar tu suscripción? Podrás seguir utilizando los servicios de WFN hasta la fecha de finalización actual de tu tarifa.',
				cancelarSuscripSwal_BTN1: 'Si, cancelar',
				cancelarSuscripSwal_BTN2: 'Cancelar',
				cancelarSuscripOkSwal: 'Suscripción cancelada correctamente',
				cancelarSuscripOkSwal_TEXT: 'Se ha cancelado correctamente la suscripción. Podrás volver a activar la tarifa desde tu perfil.',
				tarifaCambiadaSwal: 'Cambio correcto de tarifa',
				tarifaCambiadaSwal_TEXT: 'La tarifa se ha cambiado correctamente',
				tarifaCambiadaSwal_TEXT2: 'Su nueva tarifa se aplicará inmediatamente.',
				errorSwal: 'Email incorrecto',
				errorSwal_TEXT: 'El email introducido ya se encuentra registrado en el sistema.',
				errorTelegramSwal: 'Código Telegram incorrecto',
				errorTelegramSwal_TEXT: 'El código de validación de Telegram introducido es incorrecto. Revíselo e introdúzcalo de nuevo.',
				cambioLocSwal: 'Localización cambiada correctamente',
				cambioLocSwal_TEXT: 'Su nueva localización de tarifa será efectiva en 48 horas.',
				borrarCambioSwal: 'Cambio borrado correctamente',
				borrarCambioSwal_TEXT: 'Se ha cancelado el cambio seleccionado correctamente.',
				borrarDispSwal: 'Borrar Dispositivo',
				borrarDispSwal_TEXT: '¿Deseas borrar este dispositivo?',
				borrarDispSwal_BTN1: 'Si, borrar',
				borrarDispSwal_BTN2: 'Cancelar',
				borrarDispConfirmSwal: 'Dispositivo borrado correctamente',
				borrarDispConfirmSwal_TEXT: 'Se ha borrado el dispositivo correctamente.',
			}
		},
		TARIFAS: {
			TITULO_FUN1: 'Planes y precios para',
			TITULO_FUN2: 'operadores funerarios',
			SELECCIONA: 'Selecciona el plan que mejor se adapta a tu negocio de operador funerario. Siempre tendrás la flexibilidad para subir o bajar tu plan.',
			PAGO_ANUAL: 'PAGO ANUAL',
			AHORRO: 'AHORRO 10%',
			PAGO_MENSUAL: 'PAGO MENSUAL',
			POPULAR: 'MÁS POPULAR',
			PUJA_EN: 'Puja en',
			OFERTA_PARA: 'Oferta para',
			TARIFA1: 'todo el mundo',
			TARIFA2: 'todo el país',
			TARIFA3: '5 comunidades',
			TARIFA4: 'una comunidad',
			TARIFA1_MX: 'Mexico',
			TARIFA2_MX: 'Mexico, EEUU y Canadá',
			TARIFA3_MX: 'todo el mundo',
			MES: 'mes',
			AÑO: 'año',
			IVA: 'IVA',
			TAX: 'TAX',
			OFERTA: 'Oferta de lanzamiento',
			CONTRATAR: 'CONTRATAR',
			TEXTO1: 'Acceso a la información de las licitaciones que se producen en cualquier lugar del mundo.',
			TEXTO2: 'Acceso Premium a la revista funeraria mensual internacional Wfuneralnews.',
			TEXTO3: 'Soporte personalizado de nuestro equipo.',
			TEXTO4: 'Sistema de alertas en tu móvil o email, de las licitaciones, pujas u otra información relevante que consideres.',

			TITULO_ASEG1: 'Precios para',
			TITULO_ASEG2: 'aseguradoras, empresas y colectivos',
			TITULO_ASEG3: 'WFN Aseguradoras y colectivos',
			ASEG1: 'Licita en nuestra plataforma tus servicios o traslados funerarios.',
			ASEG2: 'Obtén el mejor precio para el servicio.',
			ASEG3: 'Acceso a nuestra base de datos de 60.000 funerarias en 194 países.',
			ASEG4: 'Segmentación por criterios de calidad y excelencia (ISO, UNE, NPS).',
			ASEG5: 'Ten información sobre la trazabilidad del servicio contratado.',
			ASEG6: 'Acceso a la información de las licitaciones que se producen en cualquier lugar del mundo.',
			ASEG7: 'Acceso Premium a la revista funeraria mensual internacional Wfuneralnews.',
			ASEG8: 'Soporte personalizado de nuestro equipo.',
			PAGANOS: 'Páganos a nosotros solo por lo contratado.',
			PRECIO: 'del precio al que se haya adjudicado la licitación final.',
			FUNERARIA: 'Pagas directamente a la funeraria el servicio contratado.'
		}
	}
};
