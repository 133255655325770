import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, UserService, NotificacionService, CertificadoService, LicitaService, TrazabilidadService, Imagen_userService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';

declare function initDropdown(): any;

/**
 * Componente que contiene el proceso de contratación de una opción funeraria (tras licitación).
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-contratar',
  templateUrl: './contratar.component.html',
  styleUrls: ['./contratar.component.scss']
})
export class ContratarComponent implements OnInit {

  /** Variables globales */
  @ViewChild('contratarSwal', {static: false}) private contratarSwal: SwalComponent

  licitCode = null;
  currentUser = null;
  licitacion = null;
  loadingInit = true;
  vista = "";
  estados: any;
  estadosHash = {};
  estadosAll: any;

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private licitaService: LicitaService,
    private stateService: StateService,
    private notificacionService: NotificacionService,
		private translate: TranslateService,
    private ngZone: NgZone,
    private countryService: CountryService,
    private trazabilidadService: TrazabilidadService,
    private certificadoService: CertificadoService,
    private imagen_userService: Imagen_userService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      dateTimeAdapter.setLocale('es-ES');
	}

  /**
   * Método que se lanza en el inicio del componente y carga las vistas y maestros necesarios.
   * Comprueba si hay login y si hay licitación abierta.
   */
	ngOnInit() {
    this.route.paramMap.subscribe(params => {
			this.licitCode = params.get("licit");

      if(this.licitCode == "not-found") {
        this.loadingInit = false;
        this.vista = "not-found";
        this.cdr.detectChanges();
        return;
      }
      console.warn(this.licitCode)

			if (this.licitCode) {
        this.loadProvinciasAll();

        this.authenticationService.loginLicita(this.licitCode, "WFP_USER")
        .pipe(first())
        .subscribe(
          data2 => {
            this.currentUser = data2;
            this.loadLicitacion();
          },
          error => {
            console.error(error);
            this.ngZone.run(() => this.router.navigate(['/start'])).then();
          });
			} else {
        this.ngZone.run(() => this.router.navigate(['/start'])).then();
      }
		})
  }

  /**
   * Método que se conecta al socket, carga la información de la licitación y la muestra en pantalla
   */
  loadLicitacion() {
    console.warn("LOAD LICITACION");
    var connectSails = function(){
      if(!self.io.socket) {
        return;
      }
      if(self.io.socket._isConnecting) {
        return;
      }
      var loadedLicitacion = function(resData){
        console.warn(resData);
  
        if(!resData[0]) return;
  
        this.licitacion = resData[0];
  
        if((this.licitacion.validada && !this.licitacion.usuarioElegido) || !this.licitacion.ultimaLicitacion) { // Si se ha quedado desierta le llevamos a la página de no hay resultados.
          console.warn("NOT FOUND!!!!");
          this.ngZone.run(() => this.router.navigate(['/contratar/not-found'])).then();
        } else if(this.licitacion.validada && this.licitacion.usuarioElegido) { // Si ya la ha asignado, le llevamos a la confirmacion de contratacion
          this.cargarContratado();
        } else {  //Mostramos los resultados
          this.loadLicita();
        }
  
        this.cdr.detectChanges();
      }
      self.io.socket.get('/licitacion', {referencia: this.licitCode}, loadedLicitacion.bind(this));
    }
    
    if(self.io.socket.isConnected()) {
      var fn = connectSails.bind(this);
      fn();
    } else {
      const socket = self.io.socket;
      var conectado = false;
      self.io.socket.on('connect', function onConnect(){
        if(!conectado) {
          conectado = true;
          setTimeout(() => { 
            if(self.io.socket) {
              var fn = connectSails.bind(this);
              fn();
            } else {
              self.io.socket = socket;
              var fn = connectSails.bind(this);
              fn();
            }
          }, 500);
        }
      }.bind(this));
      //self.io.socket.on('connect', connectSails.bind(this));
    }
  }

  /** Participaciones del usuario */
  rowsLicitCompleta = [];
  /** Participaciones del usuario filtradas para mostrado */
  rowsLicit = [];

  /**
   * Carga las participaciones en una licitación. Además muestra las imágenes del usuario (logo + presentación)
   */
  loadLicita() {
    this.rowsLicit = [];
    this.rowsLicitCompleta = [];
    this.licitaService.getAllByLicitacion(this.licitacion.id)
    .subscribe(licitas => {
      this.rowsLicitCompleta = licitas;
      var hashUsers = {}
      for(let licita of licitas) {
        if(!hashUsers[licita.user.id+""]) {
          licita.importe = licita.importe * 1.21;
          this.rowsLicit.push(licita);
          hashUsers[licita.user.id+""] = true;
        }
      }

      for(var i = 0; i < this.rowsLicit.length && i < 3; i++) {
        this.cargarCertParticipante(this.rowsLicit[i]);    
        this.cargarImgsParticipante(this.rowsLicit[i]);
      }
      this.vista = "resultado";
      this.loadingInit = false;

			this.cdr.detectChanges();
		});
  }

  /**
   * Carga los certificados del participante de la licitación
   * @param licit Identificador de la participación de la que cargar certificados.
   */
  cargarCertParticipante(licit: any) {
    this.certificadoService.getByUser(licit.user.id)
      .subscribe(data => {

        licit.user.certificados = [];

        for(let doc of data) {
          if(doc.estado == "CORRECTO") licit.user.certificados.push(doc);
        }
        
        this.cdr.detectChanges();
      });
  }

  /**
   * Carga las imágenes del participante de la licitación
   * @param licit Identificador de la participación de la que cargar imágenes.
   */
  cargarImgsParticipante(licit: any) {
    if(licit.user.imgLogo) {
      this.imagen_userService.getById(licit.user.imgLogo)
      .subscribe(data => {
        if(data) {
          licit.user.imgLogoCont = data.contenido;
          this.cdr.detectChanges();
        }
      });
    }
    if(licit.user.imgPresentacion) {
      this.imagen_userService.getById(licit.user.imgPresentacion)
      .subscribe(data => {
        if(data) {
          licit.user.imgPresentacionCont = data.contenido;
          this.cdr.detectChanges();
        }
      });
    }
    
  }

  /**
   * Devuelve el background de la página en base al estado de la página
   * @returns String css del fondo correspondiente.
   */
  getBackground() {
    if(this.vista == "resultado") return 'url("/assets/media/bg/contratar.png")'
    else if(this.vista == "not-found") return 'none';
  }

  /**
   * Marca las licitaciones como desiertas y vuelve a la pantalla de start.
   */
  solicitar() {
    var edit = {
      licitacionElegida: null,
      validada: true,
      usuarioElegido: null,
    }
    var completadoLicit = function(resLicit){
      this.ngZone.run(() => this.router.navigate(['/start'])).then();
    }
    self.io.socket.patch('/licitacion/' + this.licitacion.id, edit, completadoLicit.bind(this));
  }

  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num)
  {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(num_parts[1] && num_parts[1] > 0) return num_parts.join(",")
      else return num_parts[0];
    } else {
      return '';
    }
    
  }

  /**
   * Carga del listado completo de comunidades
   */
  loadProvinciasAll(){
    console.log("loadProvinciasAll()");
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }
      });
  }

  /** Información de la participación a contratar */
  contratarLicit = null;

  /**
   * Lanza la confirmación de la contratación
   * @param licit Objeto de la participación a contratar
   */
  contratar(licit: any) {
    this.contratarLicit = licit;

    var divisa = "€";
    if(this.licitacion.divisa != 'EUR') divisa = this.licitacion.divisa;
    this.contratarSwal.text = this.translate.instant('CONTRATAR.SWAL.contratarSwal_TEXT', {importe: licit.importe + " " + divisa, funeraria: licit.user.empresa});
    this.contratarSwal.fire();
  }

  /**
   * Proceso de contratación. Valida la licitación, prepara la traza y notifica la operación.
   */
  seleccionarLicitacion() {
    this.vista = "contratado";
    this.loadingInit = true;
    this.scrollUp();
    this.cdr.detectChanges();

    this.trazabilidadService.getByGrupo("B2C")
    .subscribe(trazasList => {
      var trazas = {};
      for(let traza of trazasList){
        if((traza.codigo.includes("inhum") && this.licitacion.servicios[0].tipo == "INHUM") || 
        (traza.codigo.includes("crema") && this.licitacion.servicios[0].tipo == "CREMA") || 
        (!traza.codigo.includes("inhum") && !traza.codigo.includes("crema"))) {
          trazas[traza.codigo] = {
            completado: false,
            fecha: null,
            texto: ""
          }
        }
      }

      var edit = {
        licitacionElegida: this.contratarLicit.id,
        validada: true,
        usuarioElegido: this.contratarLicit.user.id,
        trazas: JSON.stringify(trazas)
      }
      var completadoLicit = function(resLicit){
  
        //Envio mail/telegram confirmación
        this.notificacionService.licitacion_validada({licitacion: resLicit})
        .pipe(first())
        .subscribe(
          data => {
            //Preparamos la vista de confirmación
  
            this.cargarContratado();
  
            this.loadingInit = false;
            this.scrollUp();
            this.cdr.detectChanges();
          },
          error => {
            console.error(error);
        });

        this.notificacionService.no_adjudicados({licitacion: resLicit})
          .pipe(first())
          .subscribe(
            data => {
              
            },
            error => {
              console.error(error);
          });
      }
      self.io.socket.patch('/licitacion/' + this.licitacion.id, edit, completadoLicit.bind(this));

			this.cdr.detectChanges();
		});
  }

  /**
   * Hace scroll a la zona superior de la pantalla
   */
  scrollUp() {
    window.scroll({
      top: window.scrollY - 300,
      behavior: 'smooth'
    });
  }

  /** Información de la tarifa contratada */
  tarifa = null;

  /**
   * Carga la información de la tarifa contratada en pantalla
   */
  cargarContratado() {
    this.vista = "contratado";

    this.tarifa = this.licitacion.servicios[0].id;

    this.loadingInit = false;
    this.cdr.detectChanges();
  }

  /**
   * Lleva al usuario a la pantalla de registro para ver su información de contratación
   */
  registro() {
    this.ngZone.run(() => this.router.navigate(['/auth/register'])).then();
  }
}
