import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
/**
 * Contiene los métodos para la gestión de las licitaciones y sus participaciones
 */
@Injectable()
export class LicitaService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza una petición `GET` para obtener todas las participaciones en licitaciones
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/licita?populate=false&sort=name ASC`);
    }

    /**
     * Realiza una petición `GET` para obtener las participaciones no-nulas de una licitación ordenadas por fecha.
     * @param licitacion Identificador de la licitación
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAllByLicitacion(licitacion: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/licita?licitacion=${licitacion}&nula=0&sort=fecha DESC`);
    }

    /**
     * Realiza una petición `GET` para obtener las participaciones de un usuario.
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAllByUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/licita?user=${user}&select=licitacion,importe&populate=false`);
    }

    /**
     * Realiza una petición `PATCH` para actualizar una participación
     * @param licita Contenido de la participación a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(licita: any) {
        return this.http.patch(`${environment.apiUrl}/licita/${licita.id}`, licita);
    }

    /**
     * Realiza una petición `GET` para obtener las licitación de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getLicitacionesByUser(user: any) {
        return this.http.get<any[]>(`${environment.apiUrl}/licitacion?creador=${user}`);
    }
}