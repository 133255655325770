import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, UserService, NotificacionService, CertificadoService, WebService } from '../../../_services';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { environment } from '../../../../environments/environment';

import { interval, Subscription, Observable } from 'rxjs';
import * as moment from 'moment';
import { validateSpanishId } from 'spain-id'
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GlobalVariables } from '../../../_common/global-variables';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Componente que muestra la previsualización de la página del fallecido
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  /** Variables globales */
  currentUser = null;
  loading = false;
  webCode = null;
  web = null;
  preview = null;
  baseUrl = null;

  /** Variables para compartir */

  linkWhatsapp = null;
  textoSMS = null;
  compartirSMS = false;
  compartirEmail = false;
  inputShare = [null, null, null, null, null, null];

  vista = "preview";

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private webService: WebService,
    private notificacionService: NotificacionService,
    private ngZone: NgZone,
    protected _sanitizer: DomSanitizer,
    public translate: TranslateService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) {
  }

  /**
   * Método que se lanza en el inicio del componente y carga las vistas y maestros necesarios.
   * Comprueba si hay parámetro en la url, sino devuelve a la home.
   */
  ngOnInit() {
    this.baseUrl = environment.baseUrl;
    this.route.paramMap.subscribe(params => {
			this.webCode = params.get("code");

			if (this.webCode) { 
        this.currentUser = this.authenticationService.currentUserValue;
				this.loadWeb();
        this.preview = this._sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl +  "/wfp/" + this.webCode + "/preview");
        this.cdr.detectChanges();
			} else {
        //console.error(this.authenticationService.currentUserValue)
        this.ngZone.run(() => this.router.navigate(['/home'])).then();
			}
		})
  }

  /**
   * Carga y prepara la información de la web desde el código de la url.
   */
  loadWeb() {
    this.webService.getByUrl(this.webCode)
    .subscribe(web => {
      if(web[0]) {
        this.web = web[0];
        this.web.fechaStr = moment(this.web.fechaCeremonia).format("DD/MM/YYYY");
        this.web.horaStr = moment(this.web.fechaCeremonia).format("HH:mm");
        this.linkWhatsapp = "https://api.whatsapp.com/send?text=" + this.translate.instant('WFP.MENSAJE') + " " + this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2 + ". " + this.web.lugar + ". " + this.web.fechaStr + " " + this.web.horaStr + ". " + this.baseUrl + "/wfp/" + this.web.url;
        this.textoSMS = this.translate.instant('WFP.MENSAJE') + " " + this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2 + ". " + this.web.lugar + ". " + this.web.fechaStr + " " + this.web.horaStr + ". " + this.baseUrl + "/wfp/" + this.web.url;
        this.cdr.detectChanges();
      } else {
        this.ngZone.run(() => this.router.navigate(['/home'])).then();
      }
    });
  }
  
  /**
   * Finaliza la preview y marca la web como publicada. 
   * A continuación, muestra la vista de compartir.
   */
  finalizar() {
    if(this.loading) return;
    this.loading = true;
    this.cdr.detectChanges();
    var editWeb = {id: this.web.id, publicada: true};

    this.webService.update(editWeb)
      .pipe(first())
      .subscribe(
        data => {
          //Cambiamos la vista para mostrar las opciones de compartir.
          this.loading = false;
          this.vista = "compartir";
          this.cdr.detectChanges();

        },
        error => {
          console.error(error);
        });
  }

  /** Métodos para compartir */

  /**
   * Método para preparar el enlace de whatsapp
   * @param url Recibe la url a preparar
   * @returns Devuelve el elemento preparado para el enlace
   */
  sanitize(url:string){
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * Muestra el panel de compartir en SMS
   */
  shareSMS() {
    this.compartirSMS = true;
    this.compartirEmail = false;
    this.inputShare = [null, null, null, null, null, null];
    this.cdr.detectChanges();
  }

  /**
   * Muestra el panel de compartir en EMAIL
   */
  shareEmail() {
    this.compartirSMS = false;
    this.compartirEmail = true;
    this.inputShare = [null, null, null, null, null, null];
    this.cdr.detectChanges();
  }

  /**
   * Comparte la información de la ceremonia por SMS o EMAIL
   * Método que llama a la API y le manda la info necesaria
   */
  share() {
    //this.loadingCompartir = true;
    console.warn(this.inputShare);
    if(this.compartirSMS) {
      for(let telefono of this.inputShare) {
        if(telefono) {
          console.warn(telefono);
          this.notificacionService.enviarSMS({mensaje: this.textoSMS, telefono: this.currentUser.prefijo + telefono})
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        }
      }
    } else if(this.compartirEmail) {
      for(let email of this.inputShare) {
        if(email) {
          console.warn(email);
          //asunto, destinatario, linea1, linea2, linea3, linea4, linea5, url
          var mensaje = {
            asunto: this.translate.instant('WFP.MENSAJE') + " " + this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2,
            destinatario: email,
            linea1: this.translate.instant('WFP.MENSAJE'),
            linea2: this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2,
            linea3: this.web.lugar,
            linea4: this.web.fechaStr + " " + this.web.horaStr,
            linea5: this.translate.instant('PREVIEW.ACCEDE'),
            url: this.baseUrl + "/wfp/" + this.web.url
          }
          this.notificacionService.compartirPagina(mensaje)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        }
      }
    }
    //this.inputShare = [null, null, null, null, null, null];
    this.compartirSMS = false;
    this.compartirEmail = false;
    this.cdr.detectChanges();
  }

  /**
   * Método para volver a la página de perfil de usuario
   */
  volver() {
    this.ngZone.run(() => this.router.navigate(['/home/pagina'])).then();
  }






}
