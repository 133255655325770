/**
 * Variables globales utilizadas en el código de la aplicación
 */
export class GlobalVariables {
    /** Objeto que mantiene los filtros al cambiar entre páginas */
    public static filters: any = {};
    /** Objeto que mantiene el orden de licitaciones al cambiar entre páginas */
    public static orden: any = {};
    /** Objeto que guarda los datos de registro al pasar a validación */
    public static registro: any = {};
}