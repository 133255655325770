import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CountryService, AuthenticationService, StateService, UserService, NotificacionService, CertificadoService, WebService } from '../../../_services';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Componente que muestra la página del fallecido
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-wfp',
  templateUrl: './wfp.component.html',
  styleUrls: ['./wfp.component.scss']
})
export class WfpComponent implements OnInit {

  /** Variables globales */
  currentUser = null;
  baseUrl = null;
  loading = true;
  webCode = null;
  web = null;
  licita = null;
  preview = false;
  correcto = true;
  mapa = null;
  edad = null;

  imgPortada = null;
  imgsSlider = [];
  comentarios = []; 

  constructor(private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private notificacionService: NotificacionService,
    private userService: UserService,
    private stateService: StateService,
    private webService: WebService,
    private ngZone: NgZone,
		private translate: TranslateService,
    private countryService: CountryService,
    protected _sanitizer: DomSanitizer,
    private certificadoService: CertificadoService,
		private route: ActivatedRoute,
    private http: HttpClient,
    dateTimeAdapter: DateTimeAdapter<any>) {
  }

  /**
   * Método que se lanza en el inicio del componente.
   * Si hay código de página (url) llama al método de carga
   */
  ngOnInit() {
    this.baseUrl = environment.baseUrl;
    this.route.paramMap.subscribe(params => {
			this.webCode = params.get("code");

			if (this.webCode) { 
        if(this.router.url.includes('preview')) this.preview = true;
				this.loadWeb();
			} else {
        //console.error(this.authenticationService.currentUserValue)
        this.ngZone.run(() => this.router.navigate(['/start'])).then();
			}
		})
  }

  /** Variables para compartir la página */
  linkWhatsapp = null;
  textoSMS = null;
  compartirSMS = false;
  compartirEmail = false;
  inputShare = [null, null, null, null, null, null];

  /**
   * Método que carga toda la información de la página
   * Prepara el mapa a mostrar y los enlaces de compartir.
   */
  loadWeb() {
    this.webService.getByUrl(this.webCode)
    .subscribe(web => {
      if(web[0]) {
        this.web = web[0];
        if(!this.web.publicada && !this.preview) {
          this.correcto = false;
          this.cdr.detectChanges();
        }
        var mapa = this.web.lugar + " " + this.web.direccion + " " + this.web.localidad + " " + this.web.cp + " " + this.web.pais.name;
        this.mapa = this._sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=" + mapa + "&t=&z=15&ie=UTF8&iwloc=&output=embed");
        this.web.fechaStr = moment(this.web.fechaCeremonia).format("DD/MM/YYYY");
        this.web.horaStr = moment(this.web.fechaCeremonia).format("HH:mm");
        this.web.fechaFallecimientoStr = moment(this.web.fechaFallecimiento).format("DD/MM/YYYY");
        this.edad = moment(this.web.fechaFallecimiento).diff(this.web.fechaNacimiento, 'years');

        this.linkWhatsapp = "https://api.whatsapp.com/send?text=" + this.translate.instant('WFP.MENSAJE') + " " + this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2 + ". " + this.web.lugar + ". " + this.web.fechaStr + " " + this.web.horaStr + ". " + this.baseUrl + "/wfp/" + this.web.url;
        this.textoSMS = this.translate.instant('WFP.MENSAJE') + " " + this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2 + ". " + this.web.lugar + ". " + this.web.fechaStr + " " + this.web.horaStr + ". " + this.baseUrl + "/wfp/" + this.web.url;

        this.licita = this.web.licitacion;
        this.loadImages();
        this.loadComentarios();
        this.cdr.detectChanges();
      } else {
        this.ngZone.run(() => this.router.navigate(['/start'])).then();
      }
    });
  }

  /**
   * Carga las imágenes a mostrar en la página y las muestra en el slider
   */
  loadImages() {
    this.webService.getImagenes(this.web.id)
    .subscribe(images => {
      for(let img of images) {
        if(img.tipo == "PORTADA") {
          this.imgPortada = img;
        } else {
          this.imgsSlider.push(img);
        }
      }
      if(this.imgsSlider[0]) this.image1 = this.imgsSlider[0];
      if(this.imgsSlider[1]) this.image2 = this.imgsSlider[1];
      if(this.imgsSlider[2]) this.image3 = this.imgsSlider[2];

      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  /** Imágenes a mostrar en el slider */
  image1 = null; image2 = null; image3 = null;
  /** Índice actual de mostrado de imagen */
  index = 0;

  /**
   * Muestra la siguiente imagen en el slider
   */
  nextImage() {
    this.index++;
    if(this.imgsSlider[this.index]) this.image1 = this.imgsSlider[this.index];
    if(this.imgsSlider[this.index+1]) this.image2 = this.imgsSlider[this.index+1];
    if(this.imgsSlider[this.index+2]) this.image3 = this.imgsSlider[this.index+2];
    this.cdr.detectChanges();
  }

  /**
   * Muestra la anterior imagen en el slider
   */
  prevImage() {
    this.index--;
    if(this.imgsSlider[this.index]) this.image1 = this.imgsSlider[this.index];
    if(this.imgsSlider[this.index+1]) this.image2 = this.imgsSlider[this.index+1];
    if(this.imgsSlider[this.index+2]) this.image3 = this.imgsSlider[this.index+2];
    this.cdr.detectChanges();
  }

  /** Métodos para compartir */

  /**
   * Método para preparar el enlace de whatsapp
   * @param url Recibe la url a preparar
   * @returns Devuelve el elemento preparado para el enlace
   */
  sanitize(url:string){
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * Muestra el panel de compartir en SMS
   */
  shareSMS() {
    this.compartirSMS = true;
    this.compartirEmail = false;
    this.inputShare = [null, null, null, null, null, null];
    this.cdr.detectChanges();
  }

  /**
   * Muestra el panel de compartir en EMAIL
   */
  shareEmail() {
    this.compartirSMS = false;
    this.compartirEmail = true;
    this.inputShare = [null, null, null, null, null, null];
    this.cdr.detectChanges();
  }

  /**
   * Comparte la información de la ceremonia por SMS o EMAIL
   * Método que llama a la API y le manda la info necesaria
   */
  share() {
    if(this.compartirSMS) {
      for(let telefono of this.inputShare) {
        if(telefono) {
          this.notificacionService.enviarSMS({mensaje: this.textoSMS, telefono: this.web.usuario.prefijo + telefono})
          .pipe(first())
          .subscribe(
            data => {
              //console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        }
      }
    } else if(this.compartirEmail) {
      for(let email of this.inputShare) {
        if(email) {
          //asunto, destinatario, linea1, linea2, linea3, linea4, linea5, url
          var mensaje = {
            asunto: this.translate.instant('WFP.MENSAJE') + " " + this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2,
            destinatario: email,
            linea1: this.translate.instant('WFP.MENSAJE'),
            linea2: this.web.nombre + " " + this.web.apellido1 + " " + this.web.apellido2,
            linea3: this.web.lugar,
            linea4: this.web.fechaStr + " " + this.web.horaStr,
            linea5: this.translate.instant('PREVIEW.ACCEDE'),
            url: this.baseUrl + "/wfp/" + this.web.url
          }
          this.notificacionService.compartirPagina(mensaje)
          .pipe(first())
          .subscribe(
            data => {
              //console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        }
      }
    }
    //this.inputShare = [null, null, null, null, null, null];
    this.compartirSMS = false;
    this.compartirEmail = false;
    this.cdr.detectChanges();
  }

  /**
   * Carga los comentarios a mostrar en la página
   */
  loadComentarios() {
    this.webService.getComentarios(this.web.id)
    .subscribe(comentarios => {
      this.comentarios = comentarios;
      this.cdr.detectChanges();
    });
  }

  /** Input de nuevo comentario */
  comentario = {nombre: "", contenido: "", politica: false}
  mostrarNuevoComentario = false;
  comentarioEnviado = false;

  /**
   * Muestra el formulario para insertar un nuevo comentario
   */
  nuevoComentario() {
    this.mostrarNuevoComentario = true;
    this.cdr.detectChanges();
  } 

  /**
   * Publica un nuevo comentario.
   * Lo envía al servidor y lo muestra en la vista.
   */
  publicarComentario() {
    if(!this.comentario.politica) return;
    this.comentarios.push({nombre: this.comentario.nombre, contenido: this.comentario.contenido});
    this.comentarioEnviado = true;
    this.cdr.detectChanges();

    this.webService.postComentario({nombre: this.comentario.nombre, contenido: this.comentario.contenido, fecha: new Date(), web: this.web.id})
      .pipe(first())
      .subscribe(
        data => {
          //console.log("OK", data);
        },
        error => {
          console.error(error);
          this.loading = false;
        });
  }
}
