import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService, StateService, CountryService, WebService, FunerariaService, TrazabilidadService, Imagen_userService, NotificacionService, Wfp_servicioService } from '../../../_services';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import * as moment from 'moment';
declare var Stripe: any;

import { environment } from '../../../../environments/environment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Componente que muestra el área de cliente particular
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  /** Variables globales */
  @ViewChild('errorFileSwal', {static: false}) private errorFileSwal: SwalComponent;
  @ViewChild('borrarSwal', {static: false}) private borrarSwal: SwalComponent;

  currentUser: any;
  baseUrl = null;
  loading = true;
  loadingWeb = false;
  estadosHash = {};
  estadosAll = []
  provinciasSel = [];
  paises = [];
  paisesHash = {};
  paisesHashCode = {};
  trazabilidadHash = {};
  trazas = {};
  trazasHay = false;
  pagina = null;
  licitaciones = [];  
  funeraria = null;

  vista = "servicios";

  private unsubscribe: Subject<any>; 

  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
	  private modalService: NgbModal,
    private stateService: StateService,
    private trazabilidadService: TrazabilidadService,
    private webService: WebService,
    private funerariaService: FunerariaService,
    private imagen_userService: Imagen_userService,
    private notificacionService: NotificacionService,
    private wfp_servicioService: Wfp_servicioService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    private ngZone: NgZone,
    private http: HttpClient,
    private route: ActivatedRoute,
    private countryService: CountryService,
    dateTimeAdapter: DateTimeAdapter<any>) { 
      this.unsubscribe = new Subject();
      dateTimeAdapter.setLocale('es-ES');
    }

  /**
   * Método que se lanza en el inicio del componente y carga los datos del usuario, maestros y licitaciones.
   */
  ngOnInit() {
    this.baseUrl = environment.baseUrl;
    this.route.paramMap.subscribe(params => {
      if(params.get("tab")) {
        if(params.get("tab") == "pagina-editar") {
          this.modoEdicion = true;
        } else {
          this.vista = params.get("tab");
        }
      }
    });
    
    var token = this.authenticationService.currentUserValue.token;
    this.loadProvinciasAll();

    this.userService.getById(this.authenticationService.currentUserValue.id)
    .subscribe(user => {
      this.currentUser = user;
      this.currentUser.token = token;
      this.web.usuario = this.currentUser.id;
      this.cdr.detectChanges();
      this.authenticationService.changeUser(this.currentUser);

      var connectSails = function(){
        if(!self.io.socket) {
          return;
        }
        if(self.io.socket._isConnecting) {
          return;
        }
        this.loadLicitaciones();
        this.loadTrazabilidad();
        this.loadPagina();
        this.loadServicios();
      }
      
      if(self.io.socket.isConnected()) {
        var fn = connectSails.bind(this);
        fn();
      } else {
        const socket = self.io.socket;
        var conectado = false;
        self.io.socket.on('connect', function onConnect(){
          if(!conectado) {
            conectado = true;
            setTimeout(() => { 
              if(self.io.socket) {
                var fn = connectSails.bind(this);
                fn();
              } else {
                self.io.socket = socket;
                var fn = connectSails.bind(this);
                fn();
              }
            }, 500);
          }
        }.bind(this));
        //self.io.socket.on('connect', connectSails.bind(this));
      }


    });
  }

  /**
   * Carga y preparación de las licitaciones asociadas al usuario.
   */
  loadLicitaciones() {
    var loadedLicitaciones = function(resData){
      console.warn(resData);

      if(resData.length == 0) return;
      resData[0].detalle = true;

      for(let licit of resData) {
        if(licit.validada && licit.usuarioElegido) {
          licit.detalle = true;
          licit.fechaStr = moment(licit.startDate).format("DD/MM/YYYY HH:mm");
          this.trazas = JSON.parse(licit.trazas);
          
          for(let traza in this.trazas) {
            if(this.trazas[traza].completado) {
              this.trazasHay = true;
              if(traza == "wfp5" && !this.pagina) {
                var trazaObj = this.trazas[traza];
                this.web.fechaCeremonia = new Date(trazaObj.fechaInput);
                this.web.horaCeremonia = new Date(trazaObj.fechaInput);
                this.web.lugar = trazaObj.lugar;
                this.web.direccion = trazaObj.direccion;
                this.web.localidad = trazaObj.poblacion;
                this.web.cp = trazaObj.cp;
                this.web.pais = trazaObj.pais;
                this.web.provincia = trazaObj.provincia;

                this.onChangePais(null);
              }
            }
          }
          this.cargarLogoParticipante(licit);
          console.warn(this.trazas);
          this.licitaciones.push(licit);
          if(licit.licitacionElegida.funeraria) {
            // Cargamos la información de la funeraria - Si hay.
            this.funerariaService.getById(licit.licitacionElegida.funeraria)
            .subscribe(funeraria => {
              this.funeraria = funeraria;
              this.cdr.detectChanges();
            });
          }
        }
      }
      this.loading = false;
      this.cdr.detectChanges();
    }
    self.io.socket.get('/licitacion', {creador: this.currentUser.id, validada: true}, loadedLicitaciones.bind(this));
  }

  /**
   * Carga de las trazas disponibles en el maestro para su visualización
   */
  loadTrazabilidad(){
		this.trazabilidadService.getAll()
	    .subscribe(trazas => {
        for(var i = 0; i < trazas.length; i++){
          this.trazabilidadHash[trazas[i].codigo+""] = trazas[i];
        }
        console.warn(this.trazabilidadHash);
      });
  }

  /**
   * Carga el logo de la funeraria adjudicada en la licitación
   * @param licit Objeto de la licitación a cargar el logo
   */
  cargarLogoParticipante(licit: any) {
    if(licit.usuarioElegido.imgLogo) {
      this.imagen_userService.getById(licit.usuarioElegido.imgLogo)
      .subscribe(data => {
        if(data) {
          licit.usuarioElegido.imgLogoCont = data.contenido;
          this.cdr.detectChanges();
        }
      });
    } else if(licit.usuarioElegido.imgPresentacion) {
      this.imagen_userService.getById(licit.usuarioElegido.imgPresentacion)
      .subscribe(data => {
        if(data) {
          licit.usuarioElegido.imgLogoCont = data.contenido;
          this.cdr.detectChanges();
        }
      });
    }
    
  }

  /**
   * Muestra el detalle de la licitación
   * @param licit Objeto de la licitación a mostrar
   */
  detalleLicit(licit: any) {
    if(licit.detalle) licit.detalle = false
    else licit.detalle = true;
    this.cdr.detectChanges();
  }

  /**
   * Muestra la vista en la zona derecha de la pantalla
   * @param vista Identificador de la vista a mostrar
   */
  cambioVista(vista: any) {
    this.vista = vista;
    this.cdr.detectChanges();
  }

	/*
   * Evento de destrucción del componente. Finaliza las suscripciones activas.
   */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }

  /**
   * Hace scroll al elemento indicado
   * @param id Identificador del elemento al que hacer scroll.
   */
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
  }
  
  /**
   * Método que prepara un número para mostrarlo en el formato .00
   * @param num Número a formatear
   * @returns Número formateado de tipo string
   */
  thousands_separators(num) {
    if(num){ 
      var num_parts = num.toFixed(2).split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if(num_parts[1] && num_parts[1] > 0) return num_parts.join(",")
      else return num_parts[0];
    } else {
      return '';
    }
  }

  /**
   * Carga el listado completo de comunidades/estados
   */
  loadProvinciasAll(){
    console.log("loadProvinciasAll()");
		this.stateService.getAll()
	    .subscribe(estados => {
        this.estadosAll = estados;
        this.cdr.detectChanges();
        for(var i = 0; i < estados.length; i++){
          this.estadosHash[estados[i].id+""] = estados[i];
        }
        this.loadPaises();
      });
  }

  /**
   * Carga el listado completo de paises
   */
  loadPaises() {
		console.log("loadPaises()");
		this.countryService.getAll()
	  .subscribe(paises => {
			this.paises = paises;
      this.cdr.detectChanges();
      for(var i = 0; i < paises.length; i++){
        this.paisesHash[paises[i].id+""] = paises[i];
        this.paisesHashCode[paises[i].iso2+""] = paises[i];
      }

      /*this.http.get<any>(`https://freegeoip.app/json/`)
			.subscribe(data => {
        console.warn(data);
				if(this.paisesHashCode[data.country_code]) {
					this.web.pais = this.paisesHashCode[data.country_code].id;
          this.onChangePais(null);
				}
			});*/
		});
	}

  /**
   * Evento que carga las comunidades al seleccionar un pais.
   * @param event Información del evento generado
   */
  onChangePais(event){
    this.provinciasSel = [];
    if(!this.modoEdicion) this.web.provincia = '';
    for(let estado of this.estadosAll) {
      if(estado.countryId == this.web.pais) {
        this.provinciasSel.push(estado);
      }
    }
    this.cdr.detectChanges();
  }

	/**
	 * Cierra la sesión del usuario.
	 */
	logout() {
		this.authenticationService.logout();
	}

  /** ----- Métodos de creación y gestión de la página del fallecido ----- **/

  step = 1;
  modoEdicion = false;
  imagesEdit = [];
  comentarios = [];
  webObj = null;

  /** Datos a rellenar en el formulario de nueva web */
  web = {
    usuario: null,
    url: null,
    licitacion: null,
    //STEP 1
    nombre: null,
    apellido1: null,
    apellido2: null,
    fechaNacimiento: null,
    fechaFallecimiento: null,
    //STEP 2
    fechaCeremonia: null,
    horaCeremonia: null,
    lugar: null,
    direccion: null,
    cp: null,
    localidad: null,
    provincia: null,
    pais: null,
    descripcion: null,
    //STEP 3
    recuerdo: null,
    comentarios: false,
    //STEP 4
    compartir: false,
    compartir_whatsapp: true,
    compartir_sms: true,
    compartir_email: true,
    publicada: false
  }
  /** Gestión de errores de la web */
  web_error = {
    //STEP 1
    nombre: false,
    apellido1: false,
    fechaNacimiento: false,
    fechaFallecimiento: false,
    //STEP 2
    fechaCeremonia: false,
    horaCeremonia: null,
    lugar: false,
    direccion: false,
    cp: false,
    localidad: false,
    provincia: false,
    pais: false,
    descripcion: false,
    //STEP 3
    recuerdo: null,
    //STEP 4
    compartir: false
  }

  /**
   * Pasa a la página siguiente del formulario comprobando que se cumplen todos los requisitos.
   */
  siguiente() {
    if(this.loadingWeb) return;
    var error = false;
    if(this.step == 1) {
      if(!this.web.nombre) {
        this.web_error.nombre = true; error = true;
      } else this.web_error.nombre = false;
      if(!this.web.apellido1) {
        this.web_error.apellido1 = true; error = true;
      } else this.web_error.apellido1 = false;
      if(!this.web.fechaNacimiento) {
        this.web_error.fechaNacimiento = true; error = true;
      } else this.web_error.fechaNacimiento = false;
      if(!this.web.fechaFallecimiento) {
        this.web_error.fechaFallecimiento = true; error = true;
      } else this.web_error.fechaFallecimiento = false;
    } else if(this.step == 2) {
      if(!this.web.fechaCeremonia) {
        this.web_error.fechaCeremonia = true; error = true;
      } else this.web_error.fechaCeremonia = false;
      if(!this.web.horaCeremonia) {
        //this.web.horaCeremonia = new Date();
        //this.web_error.horaCeremonia = true; error = true;
      } else this.web_error.horaCeremonia = false;
      if(!this.web.lugar) {
        this.web_error.lugar = true; error = true;
      } else this.web_error.lugar = false;
      if(!this.web.direccion) {
        this.web_error.direccion = true; error = true;
      } else this.web_error.direccion = false;
      if(!this.web.cp) {
        this.web_error.cp = true; error = true;
      } else this.web_error.cp = false;
      if(!this.web.localidad) {
        this.web_error.localidad = true; error = true;
      } else this.web_error.localidad = false;
      if(!this.web.provincia) {
        this.web_error.provincia = true; error = true;
      } else this.web_error.provincia = false;
      if(!this.web.pais) {
        this.web_error.pais = true; error = true;
      } else this.web_error.pais = false;
      if(!this.web.descripcion) {
        this.web_error.descripcion = true; error = true;
      } else this.web_error.descripcion = false;
    } else if(this.step == 3) {
      if(!this.web.recuerdo) {
        this.web_error.recuerdo = true; error = true;
      } else this.web_error.recuerdo = false;
    } else if(this.step == 4) {
      
    }
    if(error) return;
    this.step++;
    this.cdr.detectChanges();
    setTimeout(() => {
      KTUtil.scrollTop();
      this.cdr.detectChanges();
    }, 100);
    
  }

  /**
   * Vuelve al paso anterior del formulario de creación de página
   * @returns 
   */
  volver() {
    if(this.step == 1) {
      this.modoEdicion = false;
      this.cdr.detectChanges();
      return;
    }
    this.step--;
    setTimeout(() => {
      KTUtil.scrollTop();
    }, 100);
    this.cdr.detectChanges();
  }

  /**
   * Guarda la información de la página.
   * Edita o crea una nueva si no existe.
   */
  guardar() {
    if(this.loadingWeb) return;
    this.loadingWeb = true;
    this.cdr.detectChanges();

    var fechaCeremonia = moment(this.web.fechaCeremonia).format("YYYY-MM-DD");
    fechaCeremonia += " " + moment(this.web.horaCeremonia || new Date()).format("HH:mm")

    this.web.fechaCeremonia = new Date(fechaCeremonia);
    this.web.fechaNacimiento = new Date(moment(this.web.fechaNacimiento).format("YYYY-MM-DD 15:00"));
    this.web.fechaFallecimiento = new Date(moment(this.web.fechaFallecimiento).format("YYYY-MM-DD 15:00"));
    this.web.url = this.web.nombre + "-" + this.web.apellido1 + "-" + this.web.apellido2 + "_" + moment(this.web.fechaFallecimiento).format("DDMMYY");

    this.web.licitacion = this.licitaciones[0].id;

    if(this.modoEdicion) {
      this.web["id"] = this.pagina.id;
      this.webService.update(this.web)
      .pipe(first())
      .subscribe(
        data => {
          this.webObj = data;

          for(let image of this.imagesEdit) {
            this.webService.deleteImagen(image.id).subscribe(data => {});
          }

          if(this.files[0]) {
            this.subirImagen(this.files[0], "PORTADA", function() {
              this.continuarSubida();
            }.bind(this))
          } else {
            this.continuarSubida();
          }
          
          console.log("OK", data);
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    } else {
      this.webService.post(this.web)
      .pipe(first())
      .subscribe(
        data => {
          this.webObj = data;

          if(this.files[0]) {
            this.subirImagen(this.files[0], "PORTADA", function() {
              this.continuarSubida();
            }.bind(this))
          } else {
            this.continuarSubida();
          }
          
          console.log("OK", data);
        },
        error => {
          console.error(error);
          this.loading = false;
        });
    }

    
  }

  /**
   * Continua el guardado de imágenes SLIDER y previsualiza la página.
   */
  continuarSubida(){
    var completados = 0;
    for(let file of this.files2) {
      this.subirImagen(file, "SLIDER", function() {
        completados++;
        if(this.files2.length == completados) this.previsualizar();
      }.bind(this))
    }
    if(this.files2.length == 0) this.previsualizar();
  }

  /**
   * Guarda una imagen en el servidor.
   * @param img Contenido de la imagen
   * @param tipo Tipo de la imagen (SLIDER/PORTADA)
   * @param cb Callback al finalizar el método
   */
  subirImagen(img, tipo, cb) {
    var doc = img;
    var myReader:FileReader = new FileReader();
    myReader.onloadend = function (e:any) {
      var newDoc = {contenido: myReader.result, nombre: doc.name, fecha: new Date(), web: this.webObj.id, tipo: tipo};

      this.webService.postImagen(newDoc)
        .pipe(first())
        .subscribe(
          data => {
            console.log("OK", data);
            
            cb();
          },
          error => {
            console.error(error);
            this.loading = false;
          });
    }.bind(this)
    myReader.readAsDataURL(doc);
  }

  /** Variables para la funcionalidad de compartir */
  linkWhatsapp = null;
  textoSMS = null;
  compartirSMS = false;
  compartirEmail = false;
  inputShare = [null, null, null, null, null, null];
  loadingCompartir = false;

  /**
   * Carga la información de la página del usuario (si existe)
   */
  loadPagina() {
    this.webService.getByUsuario(this.currentUser.id)
	    .subscribe(paginas => {
        if(paginas.length > 0) {
          this.pagina = paginas[0];
          console.warn(this.pagina);
          this.pagina.fechaStr = moment(this.pagina.fechaCeremonia).format("DD/MM/YYYY");
          this.pagina.horaStr = moment(this.pagina.fechaCeremonia).format("HH:mm");
          this.linkWhatsapp = "https://api.whatsapp.com/send?text=" + this.translate.instant('WFP.MENSAJE') + " " + this.pagina.nombre + " " + this.pagina.apellido1 + " " + this.pagina.apellido2 + ". " + this.pagina.lugar + ". " + this.pagina.fechaStr + " " + this.pagina.horaStr + ". " + this.baseUrl + "/wfp/" + this.pagina.url;
          this.textoSMS = this.translate.instant('WFP.MENSAJE') + " " + this.pagina.nombre + " " + this.pagina.apellido1 + " " + this.pagina.apellido2 + ". " + this.pagina.lugar + ". " + this.pagina.fechaStr + " " + this.pagina.horaStr + ". " + this.baseUrl + "/wfp/" + this.pagina.url;
          this.cdr.detectChanges();
          this.loadComentarios();
          if(this.modoEdicion) this.editarPagina();
        }
        
      });
  }

  /**
   * Prepara la url para mostrar en el iframe
   */
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * Muestra el formulario para compartir vía SMS
   */
  shareSMS() {
    this.compartirSMS = true;
    this.compartirEmail = false;
    this.inputShare = [null, null, null, null, null, null];
    this.cdr.detectChanges();
  }

  /**
   * Muestra el formulario para compartir vía EMAIL
   */
  shareEmail() {
    this.compartirSMS = false;
    this.compartirEmail = true;
    this.inputShare = [null, null, null, null, null, null];
    this.cdr.detectChanges();
  }

  /**
   * Comparte la página por el método seleccionado (SMS o EMAIL)
   */
  share() {
    //this.loadingCompartir = true;
    console.warn(this.inputShare);
    if(this.compartirSMS) {
      for(let telefono of this.inputShare) {
        if(telefono) {
          console.warn(telefono);
          this.notificacionService.enviarSMS({mensaje: this.textoSMS, telefono: this.currentUser.prefijo + telefono}, )
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        }
      }
    } else if(this.compartirEmail) {
      for(let email of this.inputShare) {
        if(email) {
          console.warn(email);
          //asunto, destinatario, linea1, linea2, linea3, linea4, linea5, url
          var mensaje = {
            asunto: this.translate.instant('WFP.MENSAJE') + " " + this.pagina.nombre + " " + this.pagina.apellido1 + " " + this.pagina.apellido2,
            destinatario: email,
            linea1: this.translate.instant('WFP.MENSAJE'),
            linea2: this.pagina.nombre + " " + this.pagina.apellido1 + " " + this.pagina.apellido2,
            linea3: this.pagina.lugar,
            linea4: this.pagina.fechaStr + " " + this.pagina.horaStr,
            linea5: this.translate.instant('PREVIEW.ACCEDE'),
            url: this.baseUrl + "/wfp/" + this.pagina.url
          }
          this.notificacionService.compartirPagina(mensaje)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        }
      }
    }
    //this.inputShare = [null, null, null, null, null, null];
    this.compartirSMS = false;
    this.compartirEmail = false;
    this.cdr.detectChanges();
  }

  /**
   * Activa el modo edición de la página cargando los campos y las imágenes
   */
  editarPagina() {
    this.web = {
      usuario: this.pagina.usuario.id,
      url: null,
      licitacion: this.pagina.licitacion.id,
      //STEP 1
      nombre: this.pagina.nombre,
      apellido1: this.pagina.apellido1,
      apellido2: this.pagina.apellido2,
      fechaNacimiento: new Date(this.pagina.fechaNacimiento),
      fechaFallecimiento: new Date(this.pagina.fechaFallecimiento),
      //STEP 2
      fechaCeremonia: new Date(this.pagina.fechaCeremonia),
      horaCeremonia: new Date(this.pagina.fechaCeremonia),
      lugar: this.pagina.lugar,
      direccion: this.pagina.direccion,
      cp: this.pagina.cp,
      localidad: this.pagina.localidad,
      provincia: this.pagina.provincia.id,
      pais: this.pagina.pais.id,
      descripcion: this.pagina.descripcion,
      //STEP 3
      recuerdo: this.pagina.recuerdo,
      comentarios: this.pagina.comentarios,
      //STEP 4
      compartir: this.pagina.compartir,
      compartir_whatsapp: this.pagina.compartir_whatsapp,
      compartir_sms: this.pagina.compartir_sms,
      compartir_email: this.pagina.compartir_email,
      publicada: false
    }

    this.vista = "pagina";
    this.modoEdicion = true;
    this.loadImages();
    this.onChangePais(null);
    this.cdr.detectChanges();
  }
  
  /**
   * Carga las imágenes de la página creada y las añade a su formulario
   */
  loadImages() {
    this.imagesEdit = [];
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    }
    this.webService.getImagenes(this.pagina.id)
    .subscribe(images => {
      this.imagesEdit = images;
      for(let img of images) {
        var image = dataURLtoFile(img.contenido,img.nombre);

        if(img.tipo == "PORTADA") {
          this.files.push(image);
        } else {
          this.files2.push(image);
        }
      }
      setTimeout(()=>{ 
        this.cdr.detectChanges()
      }, 1000);
    });
  }

  /**
   * Carga los comentarios a mostrar en la página
   */
   loadComentarios() {
    this.webService.getComentarios(this.pagina.id)
    .subscribe(comentarios => {
      this.comentarios = comentarios;
      this.cdr.detectChanges();
    });
  }

  /** Comentario a borrar */
  comentarioBorrar = null;

  /**
   * Lanza la confirmación de borrado de comentario
   * @param comentario Comentario a borrar
   */
  borrarComentario(comentario: any) {
    this.comentarioBorrar = comentario;
    this.borrarSwal.fire();
  }

  /**
   * Borra el comentario previamente seleccionado.
   */
  borrarComentario1() {
    this.comentarioBorrar.loadingBorrar = true;
    this.cdr.detectChanges();

    this.webService.deleteComentario(this.comentarioBorrar.id)
      .pipe(first())
      .subscribe(
        data => {
          console.log("OK", data);

          this.comentarios = [];
          this.loadComentarios();
          this.cdr.detectChanges();
        },
        error => {
          console.error(error);
          this.loading = false;
        });
  }

  /**
   * Ir a la página de previsualización
   */
  previsualizar(){
    console.warn("PREVISUALIZAR");
    //TODO: Falta previsualizar



    this.ngZone.run(() => this.router.navigate(['/preview/' + this.web.url])).then();
  }
  /**
   * Previsualizar una página ya creada para publicar
   */
  previsualizarCreada(){
    console.warn("PREVISUALIZAR");
    this.ngZone.run(() => this.router.navigate(['/preview/' + this.pagina.url])).then();
  }

  /** Subida de imágenes de la página **/

  files: File[] = [];

  /**
   * Nueva imagen añadida al formulario PORTADA
   * Comprueba que es una imagen o muestra un error.
   * @param event Evento con la información del fichero
   */
  onSelect(event) {
    console.log(event);
    if(event.addedFiles[0].type != "image/jpeg" && event.addedFiles[0].type != "image/jpg" && event.addedFiles[0].type != "image/gif" && event.addedFiles[0].type != "image/png"){
      this.errorFileSwal.fire();
      return;
    }
    this.files = [];
    this.files.push(...event.addedFiles);
    setTimeout(()=>{ 
      this.cdr.detectChanges()
    }, 150)

    console.log(event.addedFiles[0]);
  }

  /**
   * Imagen eliminada del formulario PORTADA
   * @param event Evento con la información del fichero
   */
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  files2: File[] = [];

  /**
   * Nueva imagen añadida al formulario SLIDER
   * Comprueba que es una imagen o muestra un error.
   * @param event Evento con la información del fichero
   */
  onSelect2(event) {
    if(this.files2.length == 6) return;
    console.log(event);
    if(event.addedFiles[0].type != "image/jpeg" && event.addedFiles[0].type != "image/jpg" && event.addedFiles[0].type != "image/gif" && event.addedFiles[0].type != "image/png"){
      this.errorFileSwal.fire();
      return;
    }
    //this.files2 = [];
    this.files2.push(...event.addedFiles);
    setTimeout(()=>{ 
      this.cdr.detectChanges()
    }, 150)

    console.log(event.addedFiles[0]);
  }

  /**
   * Imagen eliminada del formulario SLIDER
   * @param event Evento con la información del fichero
   */
  onRemove2(event) {
    console.log(event);
    this.files2.splice(this.files2.indexOf(event), 1);
  }

  /** ----- Métodos de gestión de la trazabilidad ----- */

  loadingTrazas = false;

  /**
   * Actualiza el listado de trazas
   */
  refreshTrazas() {
    this.loadingTrazas = true;
    this.cdr.detectChanges();
    self.io.socket.get('/licitacion/' + this.licitaciones[0].id, function (resData) {
      this.trazas = JSON.parse(resData.trazas);
      this.trazasHay = false;
          
      for(let traza in this.trazas) {
        if(this.trazas[traza].completado) {
          this.trazasHay = true;
          if(traza == "wfp5" && !this.pagina) {
            var trazaObj = this.trazas[traza];
            this.web.fechaCeremonia = new Date(trazaObj.fechaInput);
            this.web.horaCeremonia = new Date(trazaObj.fechaInput);
            this.web.lugar = trazaObj.lugar;
            this.web.direccion = trazaObj.direccion;
            this.web.localidad = trazaObj.poblacion;
            this.web.cp = trazaObj.cp;
            this.web.pais = trazaObj.pais;
            this.web.provincia = trazaObj.provincia;

            this.onChangePais(null);
          }
        }
      }
      this.cdr.detectChanges();
      setTimeout(()=>{ 
        this.loadingTrazas = false;
        this.cdr.detectChanges();
      }, 500)
    }.bind(this));
  }
  
  /** ----- Métodos de gestión del servicio de psicologo ----- */
  
  psicologo = null;
  psicologoInput = { persona: "", motivo: "" };
  psicologoInputError = { persona: false, motivo: false };
  loadingPsicologo = false;

  /**
   * Carga los servicios solicitados. De momento solo PSICOLOGO.
   */
  loadServicios() {
    this.wfp_servicioService.getByUser(this.currentUser.id)
    .subscribe(servicios => {
      for(let serv of servicios) {
        if(serv.tipo == "PSICOLOGO") {
          this.psicologo = serv;
        }
      }
      setTimeout(()=>{ 
        this.cdr.detectChanges();
      }, 1000);
    });
  }

  /**
   * Guarda la información del formulario de psicologo
   */
  guardarPsicologo() {
    if(this.loadingPsicologo) return;

    var error = false;
    if(!this.psicologoInput.persona) {
      this.psicologoInputError.persona = true; error = true;
    } else this.psicologoInputError.persona = false;
    if(!this.psicologoInput.motivo) {
      this.psicologoInputError.motivo = true; error = true;
    } else this.psicologoInputError.motivo = false;
    this.cdr.detectChanges();

    if(error) return;

    this.loadingPsicologo = true;
    this.cdr.detectChanges();

    var servicio_new = {
      usuario: this.currentUser.id,
      tipo: "PSICOLOGO",
      codigo: "WFP" + this.currentUser.id + "-" + this.licitaciones[0].id,
      info: this.psicologoInput,
      fecha: new Date(),
      enviado: false
    }

    this.wfp_servicioService.post(servicio_new)
      .pipe(first())
      .subscribe(
        data => {
          this.psicologo = data;
          this.loadingPsicologo = false;
          this.cdr.detectChanges();

        },
        error => {
          console.error(error);
          this.loadingPsicologo = false;
          this.cdr.detectChanges();
        });

  }

  /**
   * Muestra el código de psicologo en pantalla y
   * marca el servicio como enviado al responsable
   * TODO: Enviar info por email.
   */
  verCodigo() {
    var edit = {id: this.psicologo.id, enviado: true}
    this.psicologo.enviado = true;
    this.cdr.detectChanges();

    this.wfp_servicioService.update(edit)
      .pipe(first())
      .subscribe(
        data => {
          var info = {
            persona: null,
            motivo: null,
            codigo: this.psicologo.codigo
          }
          
          if(this.psicologo.info.persona == "PADRES") info.persona = this.translate.instant('HOME.PSICOLOGO.PADRES')
          else if(this.psicologo.info.persona == "HIJOS") info.persona = this.translate.instant('HOME.PSICOLOGO.HIJOS')
          else if(this.psicologo.info.persona == "HERMANOS") info.persona = this.translate.instant('HOME.PSICOLOGO.HERMANO')
          else if(this.psicologo.info.persona == "OTRO") info.persona = this.translate.instant('HOME.PSICOLOGO.OTRO');

          if(this.psicologo.info.motivo == "niños") info.motivo = this.translate.instant('HOME.PSICOLOGO.MOTIVO1')
          else if(this.psicologo.info.motivo == "accidente") info.motivo = this.translate.instant('HOME.PSICOLOGO.MOTIVO2')
          else if(this.psicologo.info.motivo == "enfermedad") info.motivo = this.translate.instant('HOME.PSICOLOGO.MOTIVO3')
          else if(this.psicologo.info.motivo == "multiple") info.motivo = this.translate.instant('HOME.PSICOLOGO.MOTIVO4')
          else if(this.psicologo.info.motivo == "judicial") info.motivo = this.translate.instant('HOME.PSICOLOGO.MOTIVO5')
          else if(this.psicologo.info.motivo == "otros") info.motivo = this.translate.instant('HOME.PSICOLOGO.MOTIVO6');

          this.notificacionService.enviarPsicologo(info)
          .pipe(first())
          .subscribe(
            data => {
              console.log("OK", data);
            },
            error => {
              console.error(error);
              this.loading = false;
            });
        },
        error => {
          console.error(error);
          this.loading = false;
        });
  }

}
