import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { UserService, AuthenticationService } from '../../../_services';
import { GlobalVariables } from '../../../_common/global-variables';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import moment from 'moment';
import { environment } from '../../../../environments/environment';

declare var Stripe: any;

/**
 * Componente que muestra el área de validación SMS
 * @author Informática Integral Vasca
 */
@Component({
  selector: 'kt-validar',
  templateUrl: './validar.component.html',
  styleUrls: ['./validar.component.scss']
})
export class ValidarComponent implements OnInit {

  /** Variables globales */
  @ViewChild('errorSMSSwal', {static: false}) private errorSMSSwal: SwalComponent

  loading = false;
  loadingRegistro = false;
  registro = null;

  /** Input del código SMS */
  codigoSMS = null;
  /** Errores en el código */
  error = {sms: false, smsCode: false};

  constructor(private route: ActivatedRoute,
    private userService: UserService,
		private translate: TranslateService,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    private router: Router,
    private cdr: ChangeDetectorRef) { 
    }


  /**
   * Método que se lanza en el inicio del componente.
   * Envía la petición de envío de sms al teléfono del usuario.
   */
  ngOnInit() {
    this.registro = GlobalVariables.registro;

    console.warn(this.registro);

    if(!this.registro.tarifa) {  //Si ha entrado directo a esta pagina le llevamos a la de registro.
      this.router.navigate(['/start']);
      return;
    }

    this.registro.last3 = this.registro.telefono.substr(this.registro.telefono.length - 3);
    this.cdr.detectChanges();
    
    this.userService.confirmacionSMS({email: this.registro.email, telefono: this.registro.prefijo + "" + this.registro.telefono, lang: this.translate.currentLang})
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
      });
  }

  /** Flag para un solo reenvío */
  mensajeReenviado = false;
  /**
   * Reenvía la petición de SMS al servidor.
   */
  reenviarSMS() {
    this.userService.confirmacionSMS({email: this.registro.email, telefono: this.registro.prefijo + "" + this.registro.telefono, reenvio: true, lang: this.translate.currentLang})
        .pipe(first())
        .subscribe(
          data => {
            this.mensajeReenviado = true;
            this.cdr.detectChanges();
          },
          error => {
            console.log(error);
        });
  }
  
  /**
   * Validación del código introducido por el usuario
   */
  validar() {
    if(!this.codigoSMS) {
      this.error.sms = true;
      this.cdr.detectChanges();
      return; 
    } else this.error.sms = false;
    this.loading = true;
    this.cdr.detectChanges();

    this.userService.checkSMS({email: this.registro.email, code: this.codigoSMS})
    .pipe(first())
    .subscribe(
      data => {
        console.log(data);
        this.registroUsuario();
      },
      error => {
        this.loading = false;
        this.error.smsCode = true;
        console.log(error);
        this.errorSMSSwal.fire();
        this.cdr.detectChanges();
    });
  }

  user = null;

  /**
   * Registra al usuario en la plataforma. Si ya existe inicia sesión en su cuenta.
   */
  registroUsuario() {
    var user = {
      email: this.registro.email,
      password: "WFP_USER",
      pais: this.registro.pais,
      provincia: this.registro.provincia,
      actividad: "WFP",
      empresa: this.registro.nombre,
      lang: this.translate.currentLang,
      status: "VALIDADO",
      prefijo: this.registro.prefijo,
      ofertante: true,
      proveedor: false,
      divisa: this.registro.divisa,
      telefono: this.registro.telefono,
      demo: false
    }

		this.userService.register(user)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);

          this.authenticationService.login(this.registro.email, "WFP_USER")
            .pipe(first())
            .subscribe(
              data2 => {

                console.log(data2);

                this.user = data2;
                this.crearLicit(function(code: any){
                  this.continuar(code);
                }.bind(this));
              },
              error => {
                console.error(error);
              });
        },
        error => {  // Intentamos hacer login por si ya existe...
          this.authenticationService.login(this.registro.email, "WFP_USER")
            .pipe(first())
            .subscribe(
              data2 => {

                console.log(data2);

                this.user = data2;
                this.crearLicit(function(code: any){
                  this.continuar(code);
                }.bind(this));
              },
              error => {  // Llegaríamos aquí si ya ha cambiado la contraseña. No debería ocurrir.
                console.error(error);
              });
        });
  }

  /**
   * Muestra el componente del proceso de licitación
   */
  continuar(code: any) {
    this.ngZone.run(() => this.router.navigate(['/proceso/' + code])).then();
  }

  /**
   * Crea la licitación en la plataforma con los datos del componente start.
   * @param cb Callback al finalizar la creación.
   */
  crearLicit(cb) {
    var servicios = [];
    if(this.registro.tarifa == "TRANQUILIDAD") {
      servicios.push({"id": 13, "abreviatura": "SV_TRANQUILIDAD", tipo: this.registro.tipo, cerem: this.registro.ceremonia, multiple: this.registro.multiple});
    } else if(this.registro.tarifa == "SOSIEGO") {
      servicios.push({"id": 14, "abreviatura": "SV_SOSIEGO", tipo: this.registro.tipo, cerem: this.registro.ceremonia, multiple: this.registro.multiple});
    } else if(this.registro.tarifa == "PREMIUM") {
      servicios.push({"id": 15, "abreviatura": "SV_PREMIUM", tipo: this.registro.tipo, cerem: this.registro.ceremonia, multiple: this.registro.multiple});
    }
    if(this.registro.coronas && this.registro.coronas != "NO") {
      servicios.push({"id": 16, "abreviatura": "CORONA", detalle: this.registro.coronas});
    }
    if(this.registro.catering && this.registro.catering != "NO") {
      servicios.push({"id": 17, "abreviatura": "CATERING", detalle: this.registro.catering});
    }
    if(this.registro.otras && this.registro.otras != "") {
      servicios.push({"id": 18, "abreviatura": "OTROS", detalle: this.registro.otras});
    }

    var unix = (moment().unix()+"").substring(2);
    var licit = {
      referencia: "WFP" + moment().format("YY") + this.user.id + unix,
      referenciaCliente: "WFP_" + this.user.id + unix,
      startDate: new Date(),
      endDate: moment().add(5, 'minutes').add(2, 'seconds').toDate(),
      abierta: true,
      pujas: true,
      importeSalida: null,
      poblacionOrigen: this.registro.poblacion,
      provinciaOrigen: this.registro.provincia,
      paisOrigen: this.registro.pais,
      comentarios: "",
      creador: this.user.id,
      poblacionDestino: this.registro.poblacion_tanat,
      provinciaDestino: this.registro.provincia_tanat,
      paisDestino: this.registro.pais_tanat,
      servicios: servicios,
      tipo: "WFP",
      divisa: this.registro.divisa,
      demo: false
    }
    var completado = function (resData){


      if(!environment.production) {
        var licit2 = {
          user: 12,
          importe: 1500,
          licitacion: resData.id,
        }
        var licit3 = {
          user: 5,
          importe: 1300,
          licitacion: resData.id,
        }
        var licit4 = {
          user: 1,
          importe: 1200,
          licitacion: resData.id,
        }
        var completado2 = function(resData2){
          var completado3 = function(resData3){
            var completado4 = function(resData4){
              cb(resData.referencia);
            }
            setTimeout(() => {
              self.io.socket.post('/postLicitacion', {cola: licit4}, completado4.bind(this));
            }, 1500);
          }
          setTimeout(() => {
            self.io.socket.post('/postLicitacion', {cola: licit3}, completado3.bind(this));
          }, 1500);
        }
        self.io.socket.post('/postLicitacion', {cola: licit2}, completado2.bind(this));



      } else {
        cb(resData.referencia);
      }
        




    }
    self.io.socket.post('/licitacion', licit, completado.bind(this));
  }
}
