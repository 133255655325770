import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import{ GlobalVariables } from '../_common/global-variables';

/** 
 * Contiene los métodos para la gestión de la autenticación
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private router: Router,
        private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        //this.logout();
    }

    /**
     * Devuelve el usuario logueado actual
     */
    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    /**
     * Cambia el contenido del objeto del usuario logueado
     * @param user Objeto del usuario a guardar
     */
    changeUser(user: any) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    /**
     * Inicia sesión en la plataforma y guarda los datos del usuario + JWT
     * @param email Dirección de correo del usuario
     * @param password Contraseña del usuario
     * @returns Devuelve el objeto con la información del usuario
     */
    login(email: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.patch<any>(`${environment.apiUrl}/api/users/login`, { email: email, password: password, web: 'wfp' }, httpOptions)
            .pipe(map(user => {
                if (user && user["token"]) {
                    localStorage.removeItem('filtros');
                    localStorage.removeItem('orden');
                    localStorage.removeItem('ordenMostrar');
                    GlobalVariables.filters = {};
                    GlobalVariables.orden = {};

                    localStorage.setItem('currentUser', JSON.stringify(user));
                    self.io.sails.headers = {
                        'Authorization': `Bearer ${user.token}`
                    };
                    self.io.socket = self.io.sails.connect();
                    this.currentUserSubject.next(user);
                    //this.router.navigate(['/home']);
                } else {
                    console.error("ERROR", user);
                }
 
                return user;
            }));
    }

    /**
     * Inicia sesión en la plataforma con un código de licitación.
     * @param code Referencia de la licitación
     * @param password Contraseña del usuario
     * @returns 
     */
    loginLicita(code: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
          console.error(code, password);
        return this.http.patch<any>(`${environment.apiUrl}/api/users/loginLicita`, { code: code, password: password }, httpOptions)
            .pipe(map(user => {
                if (user && user["token"]) {
                    localStorage.removeItem('filtros');
                    localStorage.removeItem('orden');
                    localStorage.removeItem('ordenMostrar');
                    GlobalVariables.filters = {};
                    GlobalVariables.orden = {};

                    localStorage.setItem('currentUser', JSON.stringify(user));
                    self.io.sails.headers = {
                        'Authorization': `Bearer ${user.token}`
                    };
                    self.io.socket = self.io.sails.connect();
                    this.currentUserSubject.next(user);
                    //this.router.navigate(['/home']);
                } else {
                    console.error("ERROR", user);
                }
 
                return user;
            }));
    }

    /** 
     * Cierra la sesión borrando los datos del usuario 
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
    }

    /**
     * Cierra la sesión del usuario y le lleva a contratar
     */
    contratar() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/register']);
    }
}