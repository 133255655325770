import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
 
@Injectable()
export class Wfp_servicioService {
    constructor(private http: HttpClient) { }
    
    /**
     * Realiza una petición `GET` para obtener todos los servicios wfp
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_servicio`);
    }

    /**
     * Realiza una petición `GET` para obtener el servicio wfp por ID
     * @param id Identificador del servicio WFP
     * @returns An `Observable` of the response body as a JSON object.
     */
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/wfp_servicio/${id}`);
    }

    /**
     * Realiza una petición `GET` para obtener los servicios wfp de un usuario
     * @param user Identificador del usuario
     * @returns An `Observable` of the `HTTPResponse`, with a response body in the requested type.
     */
    getByUser(user: string) {
        return this.http.get<any[]>(`${environment.apiUrl}/wfp_servicio?usuario=${user}`);
    }

    /**
     * Realiza una petición `POST` para guardar un servicio wfp
     * @param servicio Contenido del servicio wfp
     * @returns An `Observable` of the response, with the response body as a JSON object.
     */
    post(servicio: any) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post(`${environment.apiUrl}/wfp_servicio`, servicio, httpOptions);
    }
 
    /**
     * Realiza una petición `PATCH` para actualizar un servicio wfp
     * @param cert Contenido del servicio a actualizar
     * @returns An `Observable` of the response, with the response body as a JSON object
     */
    update(serv: any) {
        return this.http.patch(`${environment.apiUrl}/wfp_servicio/${serv.id}`, serv);
    }
}