// Angular
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
// RxJS
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { environment } from '../../../../../environments/environment';

import { UserService, AuthenticationService, CountryService } from '../../../../_services';

@Component({
  selector: 'kt-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	role = null;
	submitted = false;
	currentUser = null;

	paises = [];
	paisesHash = {};

	tarifa = null;
	tarifaStr = "";

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private userService: UserService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private countryService: CountryService
	) {
		this.unsubscribe = new Subject();

		this.route.paramMap.subscribe(params => {
			this.tarifa = params.get("tarifa");

			if (this.authenticationService.currentUserValue) { 
				this.currentUser = this.authenticationService.currentUserValue;
				if(this.currentUser.status == "ACTIVO") {
					this.router.navigate(['/home']);
				}
			} else {
				this.router.navigate(['/start']);
			}

			
		})
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			email: [this.currentUser.email, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		if(this.loading) return;
		
		this.submitted = true;
		
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		var form = {
			prev_email: this.currentUser.email,
			email: this.registerForm.value.email,
			password: this.registerForm.value.password
		}

		this.userService.registerWFP(form)
            .pipe(first())
            .subscribe(
				data => {
					console.log("OK", data);
					this.loading = false;
					this.cdr.detectChanges();
					this.currentUser.status = data["status"];
					this.currentUser.email = data["email"];
					this.authenticationService.changeUser(this.currentUser);
					this.router.navigate(['/home']);
				},
				error => {
					console.error(error);
					this.loading = false;
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_REGISTER'), 'danger');
					this.cdr.detectChanges();
				}
			);
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}

		if(!validationType) {
			const result = control.invalid && (control.dirty || control.touched);
			return result;
		} else {
			const result = control.hasError(validationType) && (control.dirty || control.touched);
			return result;
		}
	}

	/* VALIDAR CUENTA */

	email = null;
	hash = null;
	codigoValidacion = null;
	errorRequired = false;
	errorCodigo = false;

	validarCuenta() {
		var code = this.hash.substring(0,6);

		if(!this.codigoValidacion) {
			this.errorRequired = true;
			this.errorCodigo = false;
			return;
		}
		if(this.codigoValidacion != code) {
			this.errorRequired = false;
			this.errorCodigo = true;
			return;
		}

		this.router.navigateByUrl('/auth/validar?email=' + this.email + '&hash=' + this.hash);
		this.cdr.detectChanges();
	}

}
